import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { defineStore } from "pinia";

import { AppointmentsApi } from "@/api/appointment";
import { CheckoutApi } from "@/api/checkout";
import { EncountersAPI } from "@/api/encounters";
import { MedicationApi } from "@/api/medication";
import { PatientsApi } from "@/api/patients";
import { PharmacyApi } from "@/api/pharmacy";
import { PractitionersAPI } from "@/api/practitioners";
import { ServiceRequestApi } from "@/api/serviceRequest";
import { VisitNoteAPI } from "@/api/visitNote";
import { useEncountersStore } from "@/pinia-store/encounters";
import {
  initialEncounterNotes,
  initialMedicalInfo,
  initialPatientInfo,
  initialVisitDescription,
} from "@/pinia-store/interfaces/initialState";
import { VisitNoteState } from "@/pinia-store/interfaces/VisitNoteState";
import { ServiceRequestStatus } from "@/types/ServiceRequestStatus.enum";

/**
 * @description Refers to encounter, call between patient & practitioner,
 * ordering medication, medication statement, adding monitoring order, sign visit note
 * */
export const useVisitNoteStore = defineStore({
  id: "visitNote",
  state: (): VisitNoteState => ({
    serviceRequestObservations: [],
    bloodGlucoseFormOpened: false,
    encounter: {},
    oxygenSaturationFormOpened: false,
    bloodPressureFormOpened: false,
    temperatureFormOpened: false,
    heartRateFormOpened: false,
    respiratoryRateFormOpened: false,
    observationsSummary: {},
    patientInfo: cloneDeep(initialPatientInfo),
    practitionerInfo: {},
    medicalInfo: cloneDeep(initialMedicalInfo),
    medicationStatement: [],
    patientBodyHeights: [],
    patientBodyWeights: [],
    patientSmokingBehaviors: [],
    encounterNotes: cloneDeep(initialEncounterNotes),
    otherVisitNotes: [],
    pharmacy: {},
    previewSignNote: false,
    otherInfo: {
      patientEmail: "",
      appointmentId: "",
      encounterId: "",
      visitNoteId: "",
      patientPhoneNumber: "",
      status: "",
      practitionerId: undefined,
      practitionerEmail: "",
      practitionerPhoneNumber: "",
    },
    visitDescription: initialVisitDescription,
    checkout: undefined,
    patientId: "",
  }),
  actions: {
    setOtherVisitNotes(payload: []): void {
      this.otherVisitNotes = payload;
    },
    setAppointmentId(payload: string): void {
      this.otherInfo.appointmentId = payload;
    },
    setVisitDescription(payload: any) {
      this.visitDescription = payload;
    },
    setPatientEmail(payload: string): void {
      this.otherInfo.patientEmail = payload;
    },
    setPractitionerEmail(payload: string): void {
      this.otherInfo.practitionerEmail = payload;
    },
    setEncounterId(payload: string): void {
      this.otherInfo.encounterId = payload;
    },
    setEncounter(payload: any): void {
      this.encounter = payload;
    },
    clearEncounterNotes(): void {
      this.encounterNotes = { assessment: "", chiefComplaint: "", dx: "", followUp: "", plan: "" };
    },
    async getObservationsSummary(payload?: string) {
      this.observationsSummary = await PatientsApi.getObservationsSummary(payload || this.encounter?.patient?.id);
    },
    setDescribeProblem(payload: string) {
      this.describeProblem = payload;
    },

    async getPatientInfo(payload?: string) {
      this.patientInfo = await PatientsApi.patientGetProfile(payload || this?.patientId);
    },
    async getPractitionerInfo(payload?: string) {
      const patient = await PractitionersAPI.getPractitionerById(payload || this.encounter?.practitioner?.id);
      this.practitionerInfo = { ...patient };
    },
    async getServiceRequestObservations() {
      try {
        this.serviceRequestObservations = await ServiceRequestApi.getPatientObservationOrders({
          patientId: this.encounter?.patient?.id,
        });
      } catch (e) {
        console.info("Not any orders");
      }
    },
    async getVisitNote(encounterId?: string) {
      encounterId = encounterId ? encounterId : this.encounterId;
      if (encounterId) {
        try {
          this.encounterNotes = await EncountersAPI.getNotes(encounterId);
        } catch (e) {
          console.info("Error on getting encounter notes");
        }
      }
    },
    async getMedicationStatement() {
      this.medicationStatement = await MedicationApi.getForEncounter(this.encounter.id);
    },
    async getSelfReported() {
      this.medicationStatement = await MedicationApi.getPatientSelfReported(this.encounter?.patient?.id);
    },
    async getPharmacy() {
      let data = {};
      try {
        data = await PharmacyApi.getForEncounter(this.encounter?.id);
      } finally {
        this.pharmacy = data || {};
      }
    },
    async getPendingVisitNote(payload: {
      patientId: string;
      patient: { email: any; phoneNumber: any; firstName: any; lastName: any; gender: any; state: any };
      information: {
        medicalInformation: { medications: any[] };
        generalInformation: any;
        complaintDescription: any;
        encounterNotes: any;
        chiefComplaint: any;
      };
    }) {
      const { visitDescription } = this;
      const patient = await PatientsApi.patientGetProfile(payload.patientId);
      this.otherInfo.visitNoteId = patient;
      this.patientId = patient.id;
      this.otherInfo.patientEmail = payload.patient.email;
      this.otherInfo.patientPhoneNumber = payload.patient.phoneNumber;
      this.visitDescription = {
        ...visitDescription,
        ...payload.information.medicalInformation,
        ...payload.information.generalInformation,
        complaintDescription: payload.information.complaintDescription,
      };
      if (!payload.patient && !payload.information.medicalInformation && !payload.information.encounterNotes) {
        this.patientInfo = {
          firstName: patient.firstName,
          lastName: patient.lastName,
          gender: patient.gender,
          phoneNumber: patient.phoneNumber,
          state: patient.state,
        };
        this.encounterNotes = { ...initialEncounterNotes };
        this.medicalInfo = {
          psh: [],
          allergies: [],
          medications: [],
          pmh: [],
          document: [],
        };
      } else {
        this.patientInfo = {
          firstName: payload.patient.firstName,
          lastName: payload.patient.lastName,
          phoneNumber: payload.patient.phoneNumber,
          gender: payload.patient.gender,
          state: payload.patient.state,
        };
        this.encounterNotes = payload.information.encounterNotes
          ? payload.information.encounterNotes
          : { ...initialEncounterNotes, chiefComplaint: payload.information.chiefComplaint };
        this.medicalInfo = {
          ...payload.information.medicalInformation,
          medications: !payload.information.medicalInformation.medications
            ? []
            : payload.information.medicalInformation.medications.map((m: any) => ({
                ...m,
                startDate: new Date(m.startDate),
              })),
        };
      }
    },
    async signVisitNote() {
      const { visitNoteId } = this;
      await VisitNoteAPI.sign(visitNoteId);
    },
    async getOtherVisitNotes() {
      const { patientId, visitNoteId } = this;
      this.otherVisitNotes = await VisitNoteAPI.getPatientVisits(patientId, { excludeVisitNoteId: visitNoteId });
    },
    async getVisitSummary() {
      const { appointmentId, encounterId, patientId } = this;
      const encounterStore = useEncountersStore();
      const email = encounterStore.email;
      const practitioner = encounterStore.currentEncounter;
      const currentPractitioner = await PractitionersAPI.getPractitionerById(practitioner.id);
      // const observationSummary = await ObservationsApi.getSummary(practitioner.id);
      let summary;
      if (appointmentId) {
        [summary] = await AppointmentsApi.getSummary(appointmentId);
      } else if (encounterId) {
        [summary] = await EncountersAPI.getSummary(encounterId);
      }
      const { patientInformation, encounterNotes } = summary;
      this.otherInfo.practitionerEmail = currentPractitioner.email;
      this.otherInfo.practitionerId = currentPractitioner.id;
      this.patientId = patientId;
      this.patientInfo = patientInformation;
      this.otherInfo.patientEmail = email;
      this.encounterNotes = encounterNotes;
      if (appointmentId) {
        this.checkout = await CheckoutApi.getByAppointment(appointmentId);
      }
    },
    setEncounterNotesField(payload: { fieldName: string; value: any }) {
      this.encounterNotes[payload.fieldName] = payload.value;
    },
    autoSave() {
      debounce(() => {
        this.saveVisitNote();
      }, 2000);
    },
    savePatientInfo(data: any) {
      this.patientInfo = data;
    },
    async saveVisitNote() {
      const { encounterNotes, encounter } = this;
      await EncountersAPI.saveNotes(encounter.id, {
        followUp: encounterNotes.followUp,
        plan: encounterNotes.plan,
        assessment: encounterNotes.assessment,
        dx: encounterNotes.dx,
        chiefComplaint: encounterNotes.chiefComplaint,
      });
    },
    clearPatientInfoFields() {
      this.patientInfo = initialPatientInfo;
    },
    clearMedicalInfoFields() {
      this.medicalInfo = initialMedicalInfo;
    },
    clearEncounterNoteFields() {
      this.encounterNotes = initialEncounterNotes;
    },
  },
  getters: {
    serviceRequestObservationsHistory: (state) =>
      state.serviceRequestObservations.filter(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (i: { status: ServiceRequestStatus }) => i.status === ServiceRequestStatus.completed,
      ),
    serviceRequestObservationsActive: (state) =>
      state.serviceRequestObservations.filter((i) =>
        [ServiceRequestStatus.draft, ServiceRequestStatus.active].includes(i.status),
      ),
    serviceRequestObservationsActiveStatus: (state) =>
      state.serviceRequestObservations.filter((i) => i.status === ServiceRequestStatus.active),
    serviceRequestObservationsDraftStatus: (state) =>
      state.serviceRequestObservations.filter((i) => i.status === ServiceRequestStatus.draft),
    patientEmail: (state) => state.patientInfo.email,
    patientPhoneNumber: (state) => state.patientInfo.phoneNumber,
    patientHomePhoneNumber: (state) => state.patientInfo.homePhoneNumber,
    appointmentId: (state) => state.otherInfo.appointmentId,
    encounterId: (state) => state.encounter.id,
    visitNoteId: (state) => state.otherInfo.visitNoteId,
    status: (state) => state.otherInfo.status,
    practitionerPhoneNumber: (state) => state.otherInfo.practitionerPhoneNumber,
    practitionerEmail: (state) => state.otherInfo.practitionerEmail,
    practitionerId: (state) => state.otherInfo.practitionerId,
    disabledSigning: ({ patientInfo, encounterNotes }) => {
      const checksArray = [
        patientInfo.firstName,
        patientInfo.lastName,
        patientInfo.state,
        patientInfo.phoneNumber,
        encounterNotes.dx,
      ];
      return checksArray.some((el) => !el);
    },
  },
});
