<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    content-class="date-picker"
    min-width="290px"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :class="{ 'base-input': true, [className]: true }"
        :dense="dense"
        :error-messages="errors"
        :flat="!outlined"
        :height="height"
        :hide-details="hideDetails"
        :label="placeholder"
        :outlined="!!errors.length || outlined"
        :solo="!outlined"
        :value="formattedDate"
        light
        readonly
        @blur="onBlur"
        @focus="onFocus"
        v-on="on"
      >
        <template v-slot:append>
          <v-icon color="icon" size="15" v-on="on">$vuetify.icons.calendar</v-icon>
        </template>
      </v-text-field>
      <v-btn v-if="showNow" class="today-btn" color="primary" outlined @click="onNow">Today</v-btn>
      <v-progress-circular v-if="loading" class="loading-progress" color="primary" />
    </template>
    <v-date-picker
      ref="picker"
      :allowed-dates="allowedDates"
      :disabled="disabled"
      :max="maxDate"
      :min="minDate"
      :no-title="hideTitle"
      :value="picker"
      class="picker"
      color="primary"
      reactive
      @weekday-format="formatWeekDay"
      @click:date="updateDate"
    />
  </v-menu>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";

export default {
  name: "DatePicker",
  props: {
    loading: { default: false, type: Boolean },
    className: { default: "", type: String },
    showNow: { default: false, type: Boolean },
    dense: { default: false, type: Boolean },
    height: { default: 47, type: [Number, String] },
    placeholder: String,
    errors: { default: () => [], type: Array },
    value: [Date, String],
    maxDate: { default: "", type: String },
    isYearFirst: { default: true, type: Boolean },
    inputValue: { default: null, type: String },
    hideDetails: { default: false, type: Boolean },
    hideTitle: { default: false, type: Boolean },
    minDate: { default: undefined, type: String },
    disabled: { default: false, type: Boolean },
    customDates: { default: () => [], type: Array },
    labelActive: { default: false, type: Boolean },
  },
  watch: {
    menu(val) {
      this.isYearFirst && val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    value: {
      immediate: true,
      handler(val) {
        if (val && moment(val).isValid())
          this.picker = this.timeZone
            ? moment.tz(val, this.timeZone).format("YYYY-MM-DD")
            : moment(val).format("YYYY-MM-DD");
      },
    },
  },
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    menu: false,
    outlined: false,
  }),
  computed: {
    ...mapState["timeZone"],
    formattedDate() {
      const tzVal = this.timeZone
        ? moment.tz(this.value, this.timeZone).format(timeDisplayFormat)
        : moment(this.value).format(timeDisplayFormat);
      return this.inputValue ? this.inputValue : this.value ? (moment(this.value).isValid() ? tzVal : null) : null;
    },
  },
  methods: {
    onFocus() {
      if (!this.outlined) this.outlined = true;
      this.$emit("focus");
    },
    updateDate(date) {
      this.menu = false;
      const parsedDate = moment(date).toDate();
      this.$emit("setDate", parsedDate);
      this.$emit("input", parsedDate);
      this.picker = date;
    },
    onNow() {
      this.menu = false;
      const parsedDate = this.$moment.tz(this.timeZone).toDate();
      this.$emit("setDate", parsedDate);
      this.$emit("input", parsedDate);
      this.picker = parsedDate;
    },
    allowedDates(value) {
      return this.customDates.length ? this.customDates.includes(value) : true;
    },
    formatWeekDay(val) {
      return val;
    },
    onBlur() {
      if (!this.labelActive) this.outlined = false;
      this.$emit("blur");
    },
  },
  created() {
    if (this.labelActive) this.outlined = true;
  },
};
</script>

<style lang="scss">
.v-date-picker-title {
  display: flex;
  justify-content: center;
}

.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  text-align: center !important;
}

.v-picker {
  .v-date-picker-table {
    tbody {
      tr {
        td {
          button {
            &.v-btn {
              font-size: 12.5px;

              &:hover {
              }

              color: #33343e;
              border-radius: 4px;
            }

            &.v-btn:not(.v-date-picker-table__current) {
              border-bottom: 2px solid transparent;
            }

            &.v-btn--active {
              &:before {
                color: white;
              }

              border-bottom: 2px solid $primary;
              color: $primary;
              border-radius: 0;
              background-color: white !important;
            }
          }
        }
      }
    }
  }
}

.date-picker {
  @media (max-width: 480px) {
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.today-btn {
  margin-left: 16px;
  width: 80px;
  height: 46px !important;

  .v-btn__content {
    text-transform: none;
  }
}
</style>
