<template>
  <div id="layout-wrap">
    <v-navigation-drawer
      v-model="showDrawer"
      :color="userIsDependent ? '#2FCF6F' : 'primary'"
      :mini-variant="mini"
      :mini-variant-width="70"
      :permanent="!$vuetify.breakpoint.smAndDown"
      :temporary="$vuetify.breakpoint.smAndDown"
      :width="$vuetify.breakpoint.smAndDown ? 241 : 269"
      dark
      fixed
    >
      <div class="layout-logo d-flex justify-space-between align-center" @click="$router.push(`/${role}`)">
        <img v-if="!mini" :src="logoExpandedUrl" alt="logo" />
        <img v-else :src="logoReducedUrl" alt="logo" />
        <div v-if="$vuetify.breakpoint.smAndDown" class="navigation mr-2">
          <v-btn class="menu" color="white" icon @click="setDrawer(!drawer)">
            <v-icon>{{ drawer ? "mdi-close" : "mdi-menu" }}</v-icon>
          </v-btn>
        </div>
      </div>
      <v-divider />
      <v-list-item v-if="uid" class="justify-start layout-nav-profile relative" link @click="redirectToSettings">
        <v-skeleton-loader v-if="isLoading || loading" type="list-item-avatar" width="100%"></v-skeleton-loader>
        <template v-else>
          <v-list-item-avatar class="avatar-container" @click="onAvatarClick">
            <v-img v-if="photoURL" :src="photoURL" />
            <img v-else alt="no-avatar" src="../../assets/no-avatar.svg" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="layout-nav-list-name">{{ displayName }}</v-list-item-title>
            <v-list-item-subtitle class="layout-nav-list-rolename"> {{ roleName }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-menu
            v-if="userIsPatient && proxyAccounts.length > 1"
            :close-on-click="true"
            :close-on-content-click="true"
            :value="proxyMenu"
            bottom
            content-class="proxy-menu-root"
            offset-y
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :style="userIsDependent ? { 'border-color': '#2FCF6F' } : {}"
                class="proxy-trigger-btn"
                v-bind="attrs"
                @click="openProxyMenu"
                v-on="on"
                >mdi-chevron-down
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="user in proxyAccounts"
                :key="user.id"
                :class="{ active: user.id === uid }"
                @click="onSignWithProxy(user)"
              >
                <v-list-item-avatar class="avatar-proxy-container">
                  <v-img v-if="user.photoURL" :src="user.photoURL" />
                  <img v-else alt="no-avatar" src="../../assets/no-avatar.svg" />
                </v-list-item-avatar>
                <v-list-item-content class="proxy-container-content">
                  <v-list-item-title class="proxy-title">{{ user.displayName }}</v-list-item-title>
                  <v-list-item-subtitle class="proxy-subtitle">
                    {{ user.isDependent ? user.birthDate : "You" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="notificationCountOfUser(user.id) && user.id !== uid">
                  <v-chip class="proxy-count" color="primary" x-small>{{ notificationCountOfUser(user.id) }}</v-chip>
                </v-list-item-action>
                <v-icon v-if="user.id === uid" class="ml-2" color="#2196F3" x-small>mdi-check</v-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn icon>
          <img alt="tune.svg" src="../../assets/sidebar/tune.svg" />
        </v-btn>
      </v-list-item>
      <v-divider v-if="uid" :class="{ 'layout-nav-divider-inset': mini }" class="layout-nav-divider" />
      <NavList
        :navTabs="navTabs"
        :orders="orders.length"
        :plannedEncountersCount="plannedEncountersCount"
        :unreadMessagesCount="unreadMessagesCount"
        @click="action"
      />
      <v-subheader v-if="!mini" class="layout-nav-subheader">{{ $t("general.sidebar.quickActions") }}</v-subheader>
      <div v-else>
        <v-divider v-if="uid" class="layout-nav-divider-quick-actions" />
      </div>
      <NavList :navTabs="quickActions" :orders="orders.length" @click="action" />
      <template v-slot:append>
        <v-list-item :ripple="false" class="justify-start layout-nav-item-close" link @click="toggleSidebar">
          <v-list-item-icon v-if="!$vuetify.breakpoint.smAndDown" :class="{ 'collapsed-icon': mini }" class="ml-7px">
            <img alt="collapse.svg" src="../../assets/sidebar/collapse.svg" />
          </v-list-item-icon>
          <v-list-item-content v-if="!$vuetify.breakpoint.smAndDown">
            <v-list-item-title class="layout-nav-item-title">
              {{ $t("general.sidebar.collapseSidebar") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="uid" />
        <v-list-item v-if="uid" class="justify-start" link @click="toggleDialog">
          <v-list-item-icon class="ml-7px">
            <img alt="logout.svg" src="../../assets/sidebar/logout.svg" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="layout-nav-item-title">
              {{ $t("general.sidebar.logout") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <div
      :class="{ 'layout-slot-wrapper--reduced': !mini, 'layout-slot-wrapper--expanded': mini, [layoutClass]: true }"
      class="layout-slot-wrapper"
    >
      <Header />
      <slot />
      <Footer v-if="showFooter" />
      <BottomNav v-if="showBottomNav" />
    </div>
    <InvitePatientDialog v-model="inviteDialog" @toggleDialog="toggleInviteDialog" />
    <MediaDialog v-model="mediaDialog" @toggleDialog="toggleMediaDialog" />
    <GenerateCodeDialog v-if="generateCodeDialog" @toggleDialog="toggleGenerateCode" />
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('general.dialog.yes')"
      :dialog="dialog"
      :text="$t('general.sidebar.logoutConfirm')"
      :title="$t('general.sidebar.logout')"
      @confirm="logOutMethod"
      @toggleDialog="toggleDialog"
    />
    <DosespotUIDialog v-model="dosespotDialog" @toggleDialog="toggleDoseSpotUI" />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { AuthApi } from "@/api/auth";
import chat from "@/assets/sounds/chat.mp3";
import DosespotUIDialog from "@/components/DosespotUIDialog/Index";
import GenerateCodeDialog from "@/components/GenerateCodeDialog/index";
import InvitePatientDialog from "@/components/InvitePatientDialog/index";
import BottomNav from "@/components/Layout/BottomNav.vue";
import MediaDialog from "@/components/media/MediaDialog";
import TwilioContext from "@/context/twilioContext/TwilioContext";
import { WS_MEETING_ROOMS_UPDATE } from "@/eventBuses/socketEvents";
import { useAppStore } from "@/pinia-store/app";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useCareCoordinationStore } from "@/pinia-store/careCoordination";
import { useChatStore } from "@/pinia-store/chat";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { useObservationsStore } from "@/pinia-store/observations";
import { usePatientStore } from "@/pinia-store/patient";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useVoipStore, voipDialogType } from "@/pinia-store/voip";
import { RolesEnum } from "@/types/Roles.enum";
import drawer from "@/views/Patient/Monitoring/heartRate/Drawer.vue";

import Confirm from "../shared/Confirm.vue";
import Footer from "./Footer";
import Header from "./Header";
import NavList from "./NavList";

export default {
  name: "Layout",
  props: ["navTabs", "quickActions"],
  components: {
    BottomNav,
    DosespotUIDialog,
    MediaDialog,
    Header,
    InvitePatientDialog,
    GenerateCodeDialog,
    NavList,
    Confirm,
    Footer,
  },
  inject: ["injectable"],
  updated() {
    if (this.allowingMode) {
      this.allowingMode = false;
      return;
    }
    this.adjustCollapsing();
  },
  mounted() {
    this.adjustCollapsing();
    this.sockets.subscribe(WS_MEETING_ROOMS_UPDATE, (data) => {
      this.onUpdateMeetingRoom(data);
    });
    this.sockets.subscribe(WS_MEETING_ROOMS_UPDATE, (data) => {
      this.onUpdateMeetingRoom(data);
    });
    this.injectable.twilioContext.unconsumedMessagesDeltaSum.subscribe((val) => (this.unreadMessagesCount = val));
  },
  data: () => ({
    isMaintenance: true,
    audioPlayer: new Audio(chat),
    loading: false,
    dialog: false,
    proxyMenu: false,
    inviteDialog: false,
    dosespotDialog: false,
    mediaDialog: false,
    generateCodeDialog: false,
    unreadMessagesCount: null,
    internalMini: false,
    forbidCollapsing: false,
    allowingMode: false,
    previousCount: 0,
    twilioContext: TwilioContext | null,
  }),
  computed: {
    drawer() {
      return drawer;
    },
    ...mapState(useAppStore, ["drawer", "layoutClass", "sidebarStatus", "logoExpandedUrl", "logoReducedUrl"]),
    ...mapState(useAuthStore, [
      "uid",
      "userIsPatient",
      "userIsDependent",
      "isPractitionerFromApp",
      "isLoggedIn",
      "photoURL",
      "role",
      "timeZone",
    ]),
    ...mapState(usePatientStore, ["proxyAccounts"]),
    ...mapState(useNotificationsStore, ["monitoringCount", "notificationCountOfUser"]),
    ...mapState(useMonitoringStore, ["orders"]),
    ...mapState(useEncountersStore, ["plannedEncounters"]),
    ...mapState(useAppointmentsStore, [
      "practitionerTodayAppointmentsCount",
      "pendingAppointments",
      "patientNavbarAppointmentsCount",
    ]),
    ...mapState(useProfileSettingsStore, [
      "practitionerSettings",
      "patientSettings",
      "ownerSettings",
      "registrarSettings",
      "adminSettings",
    ]),
    ...mapState(useCareCoordinationStore, { isOpenedCareCoordination: "isOpened" }),
    showFooter() {
      return !(this.isPractitionerFromApp && this.isLoggedIn);
    },
    showBottomNav() {
      return this.isPractitionerFromApp && this.isLoggedIn;
    },
    plannedEncountersCount() {
      if (this.role === RolesEnum.Practitioner) return this.practitionerTodayAppointmentsCount;
      return this.patientNavbarAppointmentsCount;
    },
    mini() {
      // mobile version do not have mini version
      if (this.$vuetify.breakpoint.smAndDown) {
        return false;
      }
      return this.internalMini;
    },

    showDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        return this.setDrawer(value);
      },
    },
    sidebarStatus: {
      get() {
        return this.sidebarStatus;
      },
      set(value) {
        return this.setSidebarStatus(value);
      },
    },

    isLoading() {
      return !this.uid;
    },

    setting() {
      let roleSetting = "";
      if (this.role === RolesEnum.Practitioner) {
        roleSetting = "practitionerSettings";
      } else if (this.role === RolesEnum.Patient) {
        roleSetting = "patientSettings";
      } else if (this.role === RolesEnum.Owner) {
        roleSetting = "ownerSettings";
      } else if (this.role === RolesEnum.Registrar) {
        roleSetting = "registrarSettings";
      } else if (this.role === RolesEnum.Admin) {
        roleSetting = "adminSettings";
      }
      return this[roleSetting] || {};
    },

    displayName() {
      if (!this.setting?.firstName && !this.setting?.lastName) return "";
      return `${this.setting?.firstName || ""} ${this.setting?.lastName || ""}`;
    },
    isAnonymous() {
      return !this.setting?.firstName && !this.setting?.lastName;
    },
    roleName() {
      return this.userIsDependent
        ? "Dependent"
        : this.setting.clinicalRole || this.role.charAt(0).toUpperCase() + this.role.slice(1);
    },
  },
  methods: {
    ...mapActions(useAppStore, ["setDrawer", "setSidebarStatus"]),
    ...mapActions(useAuthStore, ["signInWithCustomToken", "logOut", "setCustomToken"]),
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    ...mapActions(useMeetingRoomStore, ["updateMeetingRoom", "setOpenedListDialog"]),
    ...mapActions(useObservationsStore, ["toggleFormKindSelector"]),
    ...mapActions(useVoipStore, ["setDialog"]),
    ...mapActions(useChatStore, ["toggleFloatingChatVisible"]),
    ...mapActions(useCareCoordinationStore, { setIsOpenCareCoordination: "setIsOpened" }),
    onAvatarClick(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.userIsPatient && this.proxyAccounts.length > 1) {
        this.proxyMenu = !this.proxyMenu;
      }
    },
    voipCall() {
      this.setDialog(voipDialogType.dialog);
    },
    floatingChat() {
      this.toggleFloatingChatVisible();
    },
    async onSignWithProxy(item) {
      this.loading = true;

      try {
        const data = await AuthApi.proxyToPatient(item.proxyId ? item.proxyId : item.id);
        this.setCustomToken(data.token);
        await this.signInWithCustomToken();
        // for twilio messages
        // await this.$router.push({ name: routesEnum.patientUpcomingVisits });
        window.location.href = "/patient/visits/upcoming";
      } catch (e) {
        console.error(e);
      }
    },
    onUpdateMeetingRoom(meetingRoom) {
      if (this.role === RolesEnum.Practitioner && meetingRoom.sentBy !== this.uid) return;
      this.updateMeetingRoom(meetingRoom);
    },
    openProxyMenu(e) {
      e.stopPropagation();
      this.proxyMenu = true;
    },
    openMeetingRooms() {
      this.setOpenedListDialog(true);
    },
    action(eventName) {
      if (this[eventName]) {
        this[eventName]();
      }
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    togglePatientAddMonitoringData() {
      this.toggleFormKindSelector();
    },
    toggleCareCoordination() {
      this.setIsOpenCareCoordination(!this.isOpenedCareCoordination);
    },
    async toggleChat() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showDrawer = false;
        await this.injectable.twilioContext.setCurrentChannel(null);
      }
    },
    toggleMediaDialog() {
      this.mediaDialog = !this.mediaDialog;
    },
    toggleInviteDialog() {
      this.inviteDialog = !this.inviteDialog;
    },
    toggleDoseSpotUI() {
      this.dosespotDialog = !this.dosespotDialog;
    },
    toggleGenerateCode() {
      this.generateCodeDialog = !this.generateCodeDialog;
    },
    toggleSidebar() {
      this.allowingMode = true;
      this.internalMini = !this.internalMini;
      this.sidebarStatus = this.internalMini ? "collapsed" : "expanded";
      localStorage.setItem("sidebarStatus", this.internalMini ? "collapsed" : "expanded");
    },
    adjustCollapsing() {
      if (this.$router.history.current.meta.collapseSidebar) {
        this.internalMini = true;
        this.forbidCollapsing = true;
      } else {
        this.internalMini = localStorage.getItem("sidebarStatus") === "collapsed";
        this.forbidCollapsing = false;
      }
    },
    async redirectToSettings() {
      if (this.isAnonymous) return;
      await this.$router.push(`/${this.role}/settings`);
    },
    async logOutMethod() {
      this.$socket.emit("disconnected", this.uid);
      await this.logOut();
      await this.$router.push({ path: `/auth/signin` });
    },
  },
};
</script>

<style lang="scss">
.proxy-trigger-btn {
  position: absolute !important;
  left: 45px;
  bottom: 12px;
  color: black !important;
  background: white !important;
  border-radius: 50%;
  font-size: 12px !important;
  width: 16px !important;
  height: 16px !important;
  border: 3px solid var(--primary);
}

.proxy-menu-root {
  left: 11px;
  min-width: 200px !important;

  > .v-list {
    overflow-x: auto;
    max-height: calc(100vh - 50px);
  }

  .v-list-item {
    .proxy-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #33343e;
      cursor: pointer;
    }

    .proxy-count {
      background: #2fcf6f !important;
      color: white !important;
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
    }

    .proxy-subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #91909c;
    }

    &.active {
      background: rgba(33, 150, 243, 0.08);

      .proxy-title {
        color: var(--primary);
      }
    }
  }
}

#layout-wrap {
  height: 100%;

  > aside {
    z-index: 7;
  }
}

.layout-nav-profile {
  height: 72px;

  .avatar-container {
    position: relative;
  }
}

.v-navigation-drawer {
  z-index: 3;
}

.layout-nav-divider-quick-actions {
  margin: 23px 21px 24px 21px;
}

.layout-nav-divider-inset {
  margin: 0 21px 0 21px;
}

.layout-nav-divider {
  margin-bottom: 18px;
}

.layout-nav-subheader {
  width: 300px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.layout-nav-list-name {
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}

.layout-nav-list-rolename {
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.collapsed-icon {
  transform: rotate(180deg);
}

.layout-logo {
  height: 72px;
  padding-left: 20px;
  cursor: pointer;

  img {
    max-height: 36px;
  }
}

.layout-nav-item-title {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #ffffff;
}

.layout-nav-item-close {
  opacity: 0.56;
}

.avatar-proxy-container {
  width: 26px !important;
  min-width: 26px !important;
  height: 26px !important;
}

.proxy-container-content {
  padding: 4px 0 !important;
}

.layout-slot-wrapper {
  display: flex;
  flex-direction: column;
  transition: margin 200ms;
  min-height: calc(100%);

  &.gray {
    background: #fafafd;
  }

  &--reduced {
    margin-left: 269px;
    @media (min-width: 960px) {
      max-width: calc(100% - 270px);
    }
  }

  &--expanded {
    margin-left: 70px;
    @media (min-width: 959px) {
      max-width: calc(100% - 70px);
    }
  }

  @media (max-width: 959px) {
    margin-left: 0 !important;
  }
}
</style>
