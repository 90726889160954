<template>
  <div class="media-uploader">
    <VueFileAgent
      ref="vueFileAgent"
      :accept="acceptFiles"
      :class="`custom-file-agent ${lite && 'lite'}`"
      :deletable="true"
      :errorText="{
        type: 'Invalid file type. Only images or zip Allowed',
        size: 'Files should not exceed 10MB in size',
      }"
      :maxFiles="14"
      :maxSize="'10MB'"
      :meta="true"
      :multiple="true"
      :theme="'list'"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      @select="filesSelected($event)"
    >
      <template v-slot:file-preview="slotProps">
        <div :key="slotProps.index" class="grid-box-item file-row"></div>
      </template>
      <template v-slot:file-preview-new>
        <IconicButton
          v-if="lite"
          key="new"
          :loading="loading"
          :text="title"
          class="pointer"
          left-icon="mdi-attachment"
          size="small"
        />
        <div v-else key="new" class="media-uploader__drop-text">
          Drag&drop or
          <a class="" href="#">Browse file</a>
        </div>
      </template>
    </VueFileAgent>
    <v-progress-linear v-if="loading && !lite" color="primary" indeterminate />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { MediaApi } from "@/api/media";
import IconicButton from "@/components/uikit/IconicButton.vue";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "MediaUploader",
  components: { IconicButton },
  props: {
    component: {
      type: String,
    },
    componentId: {
      type: String,
    },
    disabledAutoFetch: {
      type: Boolean,
      default: false,
    },
    lite: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Browse file",
    },
  },
  data() {
    return {
      loading: false,
      fileRecordsForUpload: [],
      fileRecordsNewlySelected: [],
    };
  },
  computed: {
    ...mapState(useMediaStore, ["uid", "acceptFiles"]),
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchFiles", "emptyFiles"]),
    async filesSelected(fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      await this.uploadFiles();
    },
    async uploadFiles() {
      this.loading = true;
      await Promise.all(
        this.fileRecordsForUpload.map(async (file, index) => {
          const formData = new FormData();
          formData.append("patientId", this.uid);

          if (this.component && this.component.length && this.componentId) {
            formData.append("component", this.component);
            formData.append("componentId", this.componentId);
          }
          formData.append("file", file.file);
          try {
            const fileUploaded = await MediaApi.create(formData);
            this.$emit("onUploaded", fileUploaded);
          } catch (e) {
            console.error("Error uploading file", e);
          }
        }),
      );
      this.fileRecordsForUpload = [];
      this.fileRecordsNewlySelected = [];
      this.loading = false;
      if (!this.disabledAutoFetch) await this.fetchFiles();
    },
    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
      }
    },
    fileDeleted: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>

<style lang="scss">
.media-uploader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .file-input {
    cursor: pointer;
  }

  .custom-file-agent {
    &.lite {
      .file-input-wrapper {
        border: none !important;
      }

      .lite-btn {
        cursor: pointer;
      }
    }
  }

  .file-input {
    opacity: 0;
  }

  .file-preview-wrapper {
    display: none !important;
  }

  .custom-file-agent .vue-file-agent.file-input-wrapper {
    border: 1.5px dashed #2196f3;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__drop-text {
    background: #f4fafe;
    padding: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: rgba(33, 150, 243, 0.6);
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }
}
</style>
