import { DateTime } from "luxon";
import moment from "moment-timezone";

import { calendarTimeFormat } from "@/components/Clinicians/constants";
import { ISlot, SlotRepeatTypeEnum } from "@/pinia-store/schedule";

const formatTime = (value: number) => (value < 10 ? `0${value}` : value);

export const generateTimeslots = (start: string, end: string, interval: number) => {
  const [startHour, startMinute] = start.split(":");
  const [endHour, endMinute] = end.split(":");

  const startDate = DateTime.fromObject({ hour: +startHour, minute: +startMinute });
  const endDate = DateTime.fromObject({ hour: +endHour, minute: +endMinute });
  let updatedDate = startDate;
  const slots = [];
  while (!updatedDate.equals(endDate)) {
    const startStr = `${formatTime(updatedDate.hour)}:${formatTime(updatedDate.minute)}`;
    updatedDate = updatedDate.plus({ minutes: interval });
    slots.push(`${startStr} - ${formatTime(updatedDate.hour)}:${formatTime(updatedDate.minute)}`);
  }

  return slots;
};

export const slotDuration = 15;

export const takeEventsFromSlots = (slot: ISlot, scheduleStartDate: string, scheduleEndDate: string) => {
  // todo: change from schedule
  // const scheduleStartDate = moment().format();
  // const scheduleEndDate = moment().add(7, "days").format();
  const events = [];

  // const start = moment(slot.start).format("HH:mm");
  const end = moment(slot.end).format("HH:mm");
  // const [startHour, startMinute] = start.split(":");
  const [endHour, endMinute] = end.split(":");
  if (!slot.repeat)
    return [
      {
        ...slot,
        slot: slot,
        start: moment(slot.start).format(calendarTimeFormat),
        end: moment(slot.end).format(calendarTimeFormat),
      },
    ];
  const loopCurrentDate = moment(slot.start);
  switch (slot.repeatType) {
    case SlotRepeatTypeEnum.daily: {
      while (moment(loopCurrentDate).isBefore(moment(scheduleEndDate))) {
        events.push({
          ...slot,
          slot: slot,
          start: moment(loopCurrentDate).toDate(),
          end: moment(loopCurrentDate)
            .set({
              hour: parseInt(endHour),
              minute: parseInt(endMinute),
            })
            // .add(slotDuration, "minutes")
            .toDate(),
          // end: moment(loopCurrentDate).add(slotDuration, "minutes").toDate(),
        });
        loopCurrentDate.add(slot.repeatInterval, "days");
      }
      break;
    }
    case SlotRepeatTypeEnum.weekly: {
      while (loopCurrentDate.isBefore(moment(scheduleEndDate))) {
        if (slot.days && slot.days.length) {
          const newDate = loopCurrentDate.clone();
          for (const key in slot.days) {
            const day = slot.days[key];
            events.push({
              ...slot,
              slot: slot,
              start: newDate.day(day).toDate(),
              end: newDate
                .set({
                  hour: parseInt(endHour),
                  minute: parseInt(endMinute),
                })
                .toDate(),
              // end: moment(loopCurrentDate).add(slotDuration, "minutes").toDate(),
            });
          }
        } else {
          events.push({
            ...slot,
            slot: slot,
            start: moment(loopCurrentDate).toDate(),
            end: moment(loopCurrentDate)
              .set({
                hour: parseInt(endHour),
                minute: parseInt(endMinute),
              })
              .toDate(),
            // end: moment(loopCurrentDate).add(slotDuration, "minutes").toDate(),
          });
        }
        loopCurrentDate.add(slot.repeatInterval, "weeks");
      }
      break;
    }
    case SlotRepeatTypeEnum.monthly: {
      while (loopCurrentDate.isBefore(moment(scheduleEndDate))) {
        if (slot.days && slot.days.length) {
          events.push({
            ...slot,
            slot: slot,
            start: moment(loopCurrentDate).toDate(),
            end: moment(loopCurrentDate)
              .set({
                hour: parseInt(endHour),
                minute: parseInt(endMinute),
              })
              .toDate(),
            // end: moment(loopCurrentDate).add(slotDuration, "minutes").toDate(),
          });
          loopCurrentDate.add(slot.repeatInterval, "months");
        }
      }
      break;
    }
    case SlotRepeatTypeEnum["bi-weekly"]: {
      while (loopCurrentDate.isBefore(moment(scheduleEndDate))) {
        if (slot.days && slot.days.length) {
          const newDate = loopCurrentDate.clone();
          for (const key in slot.days) {
            const day = slot.days[key];
            events.push({
              ...slot,
              slot: slot,
              start: newDate.day(day).toDate(),
              end: newDate
                .set({
                  hour: parseInt(endHour),
                  minute: parseInt(endMinute),
                })
                .toDate(),
            });
          }
        } else {
          events.push({
            ...slot,
            slot: slot,
            start: moment(loopCurrentDate).toDate(),
            end: moment(loopCurrentDate)
              .set({
                hour: parseInt(endHour),
                minute: parseInt(endMinute),
              })
              .toDate(),
            // end: moment(loopCurrentDate).add(slotDuration, "minutes").toDate(),
          });
        }
        loopCurrentDate.add(slot.repeatInterval, "weeks");
      }
      break;
    }
  }
  return events;
};
