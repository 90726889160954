<template>
  <footer class="footer">
    <v-row class="justify-space-between">
      <div>
        <span>{{ $t("general.contact.rightsReserved", copyrightParams) }}</span>
        <span class="left-margin mr-2">v1.0.0-{{ build }}</span>
        <a :href="telContactPhoneNumber" class="footer__link" target="_blank">Need Help? {{ contactPhoneNumber }}</a>
      </div>
      <div>
        <router-link class="footer__link" target="_blank" to="/support">Support</router-link>
        <router-link class="footer__link left-margin" target="_blank" to="/consent">Consent for Treatment</router-link>
        <router-link class="footer__link left-margin" target="_blank" to="/terms">Terms & Conditions</router-link>
      </div>
    </v-row>
  </footer>
</template>

<script>
import { mapState } from "pinia";

import { useAppStore } from "@/pinia-store/app";

export default {
  name: "FooterSection",
  computed: {
    ...mapState(useAppStore, ["brandName", "contactPhoneNumber", "telContactPhoneNumber"]),
    copyrightParams() {
      return { year: new Date().getFullYear(), brandName: this.brandName };
    },
    build() {
      return process.env.VUE_APP_BUILD || "df35df8";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: sticky;
  @include mobile {
    position: inherit;
  }
  grid-area: footer;
  align-self: flex-end;
  bottom: 0;
  margin-top: auto;
  top: auto;
  background-color: #fff;
  padding: 20px 3rem;
  font-size: 12px;
  color: #888;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid $primarylight2;
  z-index: 2;

  &__link {
    display: inline-block;
    text-decoration: none;
    color: #888;
  }
}

.left-margin {
  margin-left: 15px;
}
</style>
