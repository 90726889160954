import deepClone from "deep-clone";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import shortid from "shortid";

import { OwnersAPI } from "@/api/owners";
import { PractitionersAPI } from "@/api/practitioners";
import { GetAllSlotsFilter, SlotsApi } from "@/api/slots";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { usePatientStore } from "@/pinia-store/patient";
import { ISlot, SlotEndTypeEnum, SlotRepeatTypeEnum, useScheduleStore } from "@/pinia-store/schedule";
import { Availabilities, Availability, AvailabilitySlots, CurrentSlot } from "@/types/Availability";
import { takeEventsFromSlots } from "@/utils/generateTimeslots";
import { getWeek, WeekDay } from "@/utils/getWeek";

export interface ISlotsState {
  availabilities: Availabilities;
  initialAvailabilities: Availabilities;
  customAvailabilities: Availabilities;
  initialCustomAvailabilities: Availabilities;
  slots?: ISlot[];
  scheduleSlots?: ISlot[];
  slotsArray: Availability[];
  myDoctorSlot: Availability[];
  weekends: string[];
  currentSlot: CurrentSlot | null;
  slotInformation: any;
  editDialog: boolean;
  isSaving: boolean;
  form: Partial<ISlot>;
}

const workingHours = moment("08:00", "HH:mm");

export enum SlotAppointmentTypeEnum {
  ROUTINE = "ROUTINE",
  WALKIN = "WALKIN",
  CHECKUP = "CHECKUP",
  FOLLOWUP = "FOLLOWUP",
  EMERGENCY = "EMERGENCY",
}

export const defaultFormSlot = {
  repeat: false,
  start: workingHours.format("HH:mm"),
  end: workingHours.add(9, "hour").format("HH:mm"),
  repeatType: SlotRepeatTypeEnum.daily,
  repeatInterval: 1,
  endType: SlotEndTypeEnum.never,
  occurrence: 1,
  endDate: "",
  date: "",
  appointmentTypeId: SlotAppointmentTypeEnum.WALKIN,
  days: [],
  scheduleId: "",
  // days: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
};
export const useSlotsStore = defineStore({
  id: "slots",
  state: (): ISlotsState => ({
    availabilities: [],
    initialAvailabilities: [],
    slots: [],
    weekends: [],
    isSaving: false,
    slotsArray: [],
    form: {
      scheduleId: "",
    },
    scheduleSlots: [],
    myDoctorSlot: [],
    currentSlot: null,
    editDialog: false,
    slotInformation: {},
    customAvailabilities: [],
    initialCustomAvailabilities: [],
  }),
  actions: {
    async fetchSlotsBySchedules({ scheduleIds }: GetAllSlotsFilter) {
      if (scheduleIds?.length) {
        const data = await SlotsApi.getAllFiltered({ scheduleIds });
        this.scheduleSlots = data;
        return data;
      } else {
        this.scheduleSlots = [];
        return [];
      }
    },
    async saveForm() {
      this.isSaving = true;
      const isUpdate = Boolean(this.form.id);
      try {
        const form = { ...this.form };
        form.start = moment(form.date)
          .set("hour", form.start.split(":")[0])
          .set("minute", form.start.split(":")[1])
          .format();
        form.end = moment(form.date).set("hour", form.end.split(":")[0]).set("minute", form.end.split(":")[1]).format();
        let result;
        if (this.form.id) result = await SlotsApi.update(form);
        else result = await SlotsApi.create(form);
        result.date = moment(result.date).format();
        result.start = moment(result.start).format("HH:mm");
        result.end = moment(result.end).format("HH:mm");
        this.form = result;
        snackBarEventBus.$emit(snackBarEventName, {
          message: isUpdate ? "Slot updated" : "Slot created",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "An error occurred",
          type: "error",
        });
      }
      this.isSaving = false;
      this.editDialog = false;
    },
    toggleEditDialogSlot(value: boolean) {
      if (value && typeof value === "boolean") this.editDialog = true;
      else this.editDialog = !this.editDialog;
    },
    setForm(item: Partial<ISlot>) {
      this.form = item || defaultFormSlot;
    },
    setAvailabilities(payload: Availabilities) {
      this.availabilities = payload || { "1": [], "2": [], "3": [], "4": [], "5": [], "6": [], "7": [] };
    },
    setCustomAvailabilities(payload: Availabilities) {
      this.customAvailabilities = payload && payload.map ? payload.map((i) => ({ ...i, id: shortid() })) : [];
    },
    setCurrentSlot(payload: CurrentSlot | null) {
      this.currentSlot = payload;
    },

    async savePractitionerAvailability() {
      let availabilitySlotsString = JSON.stringify(this.availabilities);
      availabilitySlotsString = availabilitySlotsString.replaceAll("true", "false");
      const availabilitySlotsBody: Availabilities = JSON.parse(availabilitySlotsString);
      const customAvailabilitySlotsBody = cleanShortId(this.customAvailabilities);
      const authStore = useAuthStore();
      const { uid } = authStore;
      const { availabilitySlots, customAvailabilitySlots } = await PractitionersAPI.practitionerUpdateSettings(uid, {
        availabilitySlots: availabilitySlotsBody as Availabilities,
        customAvailabilitySlots: customAvailabilitySlotsBody,
      });
      this.initialAvailabilities = availabilitySlots;
      this.initialCustomAvailabilities = customAvailabilitySlots;
    },
    async getPractitionerAvailability() {
      const authStore = useAuthStore();

      const { uid } = authStore;
      const { availabilitySlots, customAvailabilitySlots } = await PractitionersAPI.practitionerGetSettings(uid);
      const weekends = getWeekends(availabilitySlots);
      for (const key in availabilitySlots) {
        availabilitySlots[key].menu = false;
      }
      this.availabilities = availabilitySlots;
      this.initialAvailabilities = deepClone(availabilitySlots);
      this.customAvailabilities = customAvailabilitySlots;
      this.initialCustomAvailabilities = deepClone(customAvailabilitySlots);
      this.slots = deepClone(availabilitySlots);
      this.weekends = weekends;
    },
    async getPractitionerFreeSlots({
      practitionerId,
      date,
      status,
    }: {
      practitionerId?: string;
      date?: string;
      status?: string;
    }) {
      try {
        const patientStore = usePatientStore();
        const id = practitionerId || patientStore?.patientsPractitioner?.id;

        this.slotsArray = await SlotsApi.getAllFiltered({ practitionerId: practitionerId || id, date, status });
      } catch (e) {
        console.error(e);
      }
    },
    async getSlotInformation(id: string) {
      this.slotInformation = await OwnersAPI.getSlotInformation(id);
    },
    async getSlotById(slotId: string) {
      const slot = await SlotsApi.getById(slotId);
      const checkoutStore = useCheckoutStore();
      checkoutStore.setVisitDate(DateTime.fromISO(slot.start).toFormat("yyyy-MM-d"));
      this.currentSlot = slot;
      return slot;
    },
    cleanSlotInformation() {
      this.slotInformation = {};
    },
  },
  getters: {
    slotsOfSchedule: (state) => (scheduleId: string) => {
      return (state.scheduleSlots || []).filter((i: ISlot) => i.scheduleId === scheduleId);
    },
    events(state) {
      const scheduleStore = useScheduleStore();
      return (state.scheduleSlots || [])
        .reduce((acc, ev) => {
          const schedule = scheduleStore.getScheduleById(ev.scheduleId);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          acc = [...acc, ...takeEventsFromSlots(ev, ev.start!, schedule?.end || ev.end!)];
          return acc;
        }, [] as Record<any, any>[])
        .filter((ev) => ev)
        .map((ev) => ({ ...ev, timed: true, name: ev.appointmentTypeId }));

      // return (state.scheduleSlots || []).map((ev) => takeEventsFromSlots(ev, ev.start!, ev.end!)).filter((ev) => ev);
    },
    customInitialAvailabilities(state) {
      return state.initialCustomAvailabilities;
    },
    freeSlots(state) {
      return state.slotsArray.filter((slot) => slot.status === "free");
    },
    myDoctorFreeSlots(state) {
      return state.myDoctorSlot;
    },
    upcomingSlot(state) {
      const currentTime = moment().format().valueOf();
      if (state.slotsArray.length) {
        const busySlots = state.slotsArray.filter(
          (slot) => slot.status !== "free" && moment(slot.start).format().valueOf() > currentTime,
        );
        if (busySlots) {
          const upComing = busySlots.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());
          return upComing[0];
        } else return null;
      } else return null;
    },
    otherSlots(state) {
      if (state.slotsArray.length) {
        return state.slotsArray
          .filter((slot) => slot.status !== "free")
          .map((slot) => {
            return {
              ...slot,
              name: "TEST NAME",
              start: new Date(slot.start).getTime(),
              end: new Date(slot.end).getTime(),
              timed: true,
            };
          });
      } else return [];
    },
  },
});

export const getWeekends = (slots: AvailabilitySlots) => {
  if (!slots) return [];
  return Object.keys(slots).reduce((acc: string[], val) => (slots[val].length ? acc : [...acc, val]), []);
};
export const cleanShortId = (slots: Availabilities) => {
  if (slots) {
    return slots.map((customAvailability) => {
      if (customAvailability.id) {
        if (!/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/.test(customAvailability.id)) {
          delete customAvailability.id;
          return customAvailability;
        } else return customAvailability;
      } else {
        if (
          customAvailability.repeat_id &&
          !/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/.test(customAvailability.repeat_id)
        ) {
          delete customAvailability.repeat_id;
          return customAvailability;
        } else return customAvailability;
      }
    });
  }
  return [];
};

// TODO refactor and add unit tests
export const serializeSlots = (slots: AvailabilitySlots) => {
  if (!slots) return [];
  const week = getWeek(new Date());

  return week.reduce((acc: Availabilities, val: WeekDay, idx: number) => {
    const { year, month, day } = val;
    return acc.concat(
      slots[idx + 1].map((slot) => {
        const [startH, startM] = slot.start.split(":");
        let [endH, endM] = slot.end.split(":");
        if (+endH === 0 && +endM === 0) {
          endH = "23";
          endM = "59";
        }
        return {
          id: shortid.generate(),
          start: DateTime.fromJSDate(new Date(year, month, day, +startH, +startM)).toISO(),
          end: DateTime.fromJSDate(new Date(year, month, day, +endH, +endM)).toISO(),
        };
      }),
    );
  }, []);
};
export const uniqBy = (arr: Availabilities[], predicate: string) => {
  const cb = typeof predicate === "function" ? predicate : (o: any) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const paginate = (array: [], pageSize: number, pageNumber: number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};
export const cleanPhone = (phone: string) => {
  if (!phone) return "";
  return phone.trim().replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("-", "");
};
