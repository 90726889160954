<template>
  <v-dialog
    :class="wrapClass"
    :max-width="maxWidth"
    :min-height="minHeight"
    :persistent="persistent"
    :retain-focus="false"
    :value="dialog"
    :width="width"
    @input="$emit('dialog')"
    @click:outside="onClose"
  >
    <v-card :class="`card ${rootClass}`">
      <v-icon v-if="!hideClose" class="card-close" color="icon" size="medium" @click="onClose">mdi-close</v-icon>
      <v-card-title v-if="!hideTitle" class="card-title">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="!hideDescription" class="card-text">
        {{ text }}
      </v-card-text>
      <slot />
      <v-card-actions class="actions">
        <v-btn v-if="!hideRemove" class="btn btn-delete" color="red" height="46" outlined width="48" @click="onDelete">
          <span>{{ removeText }}</span>
        </v-btn>
        <v-btn
          v-if="!hideCancel"
          class="btn btn-cancel"
          color="#E5E5E5"
          height="46"
          outlined
          width="48"
          @click="onClose"
        >
          <span>{{ cancelText }}</span>
        </v-btn>
        <v-btn
          v-if="!hideConfirm"
          :color="confirmColor || 'success'"
          :disabled="disabledSave"
          class="btn btn-submit"
          dark
          depressed
          height="46"
          width="126"
          @click="onConfirm"
        >
          <v-progress-circular v-if="loading" color="white" indeterminate size="25" width="3" />
          <span v-else>{{ confirmText }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Confirm",
  props: {
    disabledSave: {
      type: Boolean,
      default: () => false,
    },
    hideTitle: {
      type: Boolean,
      default: () => false,
    },
    hideClose: {
      type: Boolean,
      default: () => false,
    },
    hideRemove: {
      type: Boolean,
      default: () => true,
    },
    hideDescription: {
      type: Boolean,
      default: () => false,
    },
    persistent: {
      type: Boolean,
      default: () => false,
    },
    hideConfirm: Boolean,
    hideCancel: Boolean,
    dialog: Boolean,
    confirmText: {
      type: String,
      default: "Confirm",
    },
    removeText: {
      type: String,
      default: "Delete",
    },
    title: String,
    text: String,
    cancelText: {
      type: String,
      default: "Cancel",
    },
    loading: Boolean,
    confirmColor: String,
    maxWidth: {
      type: String,
      default: `410px`,
    },
    minHeight: {
      type: String,
      default: `inherit`,
    },
    width: {
      type: String,
      default: `410px`,
    },
    rootClass: {
      type: String,
      default: "",
    },
    wrapClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClose() {
      this.$emit("dialog");
      this.$emit("toggleDialog");
    },
    onConfirm() {
      this.$emit("confirm");
    },
    onDelete() {
      this.$emit("delete");
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  padding: 15px;
  position: relative;

  &-title {
    word-wrap: break-word;
    word-break: keep-all;
    padding-left: 0 !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 36px !important;
  }

  &-text {
    padding-left: 0 !important;
  }

  &-close {
    position: absolute !important;
    top: 13px;
    right: 13px;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    padding: 10px;
    border-radius: 100%;
    border-top-left-radius: 100% !important;
    border-top-right-radius: 100% !important;
    left: auto;
    float: right;
    z-index: 2;
  }
}

.btn {
  text-transform: capitalize;
  font-weight: 600;

  :deep &.btn-submit {
    &.v-btn--disabled {
      background-color: #fff !important;
      border: 1px solid var(--v-primarylight-base) !important;
      color: rgb(229, 229, 229) !important;
    }
  }

  &-cancel {
    span {
      color: #ccccce;
    }
  }
}

.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 16px;
}

.actions {
  padding-top: 16px !important;
  justify-content: flex-end;
  display: flex;
}
</style>
