import axios, { CancelTokenSource } from "axios";
import { defineStore } from "pinia";

import { HealthcareServiceApi } from "@/api/healthcareService";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { IOrganization } from "@/pinia-store/organization";
import { CodeableConcept } from "@/types/FHIR";

export interface IHealthcareService {
  id?: string;
  name: string;

  providedByOrganizationId: string;
  providedByOrganization?: Partial<IOrganization>;

  categoryCodingCodes: string[];
  category?: CodeableConcept[];

  typeCodingCodes?: string[];
  type?: CodeableConcept[];

  specialtyCodingCodes?: string[];

  active?: boolean;
}

interface IHealthcareServicesStore {
  healthcareServices: IHealthcareService[];
  form: Partial<IHealthcareService>;
  isLoading: boolean;
  isSaving: boolean;
  cancelRequestToken?: CancelTokenSource | null;
  selectedHealthcareService?: IHealthcareService | null;
  healthcareService: Partial<IHealthcareService>;
}

export const useHealthcareServiceStore = defineStore({
  id: "healthcare-services",
  state: (): IHealthcareServicesStore => ({
    healthcareService: {},
    healthcareServices: [],
    form: {},
    isSaving: false,
    selectedHealthcareService: null,
    cancelRequestToken: null,
    isLoading: false,
  }),
  actions: {
    setHealthcareServices(payload: IHealthcareService[]) {
      this.healthcareServices = payload;
    },
    setHealthcareService(payload: IHealthcareService) {
      this.healthcareService = payload;
    },
    changeFormField(value: any, field: string) {
      console.log(value, field);
      this.form[field] = value;
    },
    setForm(item: IHealthcareService) {
      console.log("set form", item);
      this.form = item;
    },
    async saveForm() {
      this.isSaving = true;
      const isUpdate = Boolean(this.form.id);
      try {
        if (this.form.id) this.form = await HealthcareServiceApi.update(this.form);
        else this.form = await HealthcareServiceApi.create(this.form);
        snackBarEventBus.$emit(snackBarEventName, {
          message: isUpdate ? "Healthcare Service updated" : "Healthcare Service created",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "An error occurred",
          type: "error",
        });
      }
      this.isSaving = false;
    },
    async getHealthcareServices(organization: string) {
      const cancelRequestToken = this.cancelRequestToken;
      if (cancelRequestToken) cancelRequestToken.cancel && cancelRequestToken.cancel();
      this.cancelRequestToken = axios.CancelToken.source();
      this.isLoading = true;
      try {
        this.healthcareServices = await HealthcareServiceApi.getAll({ organization });
        this.cancelRequestToken = null;
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
  },
});
