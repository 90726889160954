<template>
  <v-btn
    v-blur
    :block="size === 'x-large' || fullWidth"
    :class="`primary-btn primary-btn__${size} ${className} ${size === 'x-large' ? 'primary-btn__blue' : ''} ${
      appView && 'app-btn'
    }`"
    :dark="!disabled"
    :disabled="disabled"
    :height="_height"
    :loading="loading"
    :ripple="false"
    :rounded="rounded"
    :type="type"
    :width="width"
    depressed
    @click="onClick"
  >
    <slot name="icon-left"></slot>
    <v-icon v-if="leftIcon" class="primary-btn_icon-left" size="20">{{ leftIcon }}</v-icon>
    {{ text }}
    <v-icon v-if="rightIcon" class="primary-btn_icon-right" size="16">{{ rightIcon }}</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "PrimaryButton",
  props: {
    appView: { default: false, type: Boolean },
    rounded: { default: false, type: Boolean },
    size: { default: "medium", type: String },
    text: { required: true, type: String },
    height: { default: null, type: [String, Number] },
    disabled: { default: false, type: Boolean },
    width: { default: undefined, type: String },
    type: { default: "button", type: String },
    fullWidth: { default: false, type: Boolean },
    rightIcon: { default: "", type: String },
    leftIcon: { default: "", type: String },
    loading: { default: false, type: Boolean },
    className: { default: "", type: String },
  },
  computed: {
    _height() {
      if (this.height) return this.height;
      let height = null;

      switch (this.size) {
        case "x-large":
          height = 60;
          break;
        case "large":
          height = 46;
          break;
        case "medium":
          height = 36;
          break;
        case "small":
          height = 30;
          break;
      }
      return height;
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss">
.v-btn.app-btn {
  @include tablet {
    &.primary-btn {
      background: none !important;
      color: $primary !important;

      &:hover {
        background: none !important;
      }

      i {
        display: none;
      }
    }
  }
}
</style>
