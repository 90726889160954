<template>
  <div class="medical-record">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-row class="mt-3">
      <v-col :lg="6" :md="6" :sm="12" cols="12">
        <div class="d-flex align-center flex-wrap">
          <span class="heading-4">{{ patient.firstName }} {{ patient.lastName }}</span>
          <div class="centered-flex">
            <v-icon :x-large="true" class="mx-1" color="icon">mdi-circle-small</v-icon>
            <span class="sub-heading-3">
              ID
              {{ patient.medicalRecordNumber || "" }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col
        :class="{ smAndDown: $vuetify.breakpoint.smAndDown, xs: $vuetify.breakpoint.xs }"
        class="wrap-patient-actions"
        cols="12"
        lg="6"
        md="6"
        sm="12"
      >
        <PrimaryButton
          v-if="showAddNoteAction"
          :full-width="$vuetify.breakpoint.xsOnly"
          left-icon="mdi-plus"
          text="Add Note"
          @onClick="openAddNoteDialog"
        />
        <CallNowAction :phone="patient.phoneNumber" />
        <MeetNowMenu :recipient="patient" />
        <ChatNowAction :phone-number="patient.phoneNumber" :to-user-id="patient.id" />
      </v-col>
      <v-col cols="12">
        <v-tabs :show-arrows="false" :value="activeTab" background-color="transparent" class="tab-wrap" color="primary">
          <v-tab v-for="item in tabItems" :key="item.title" class="text-capitalize tab_item" @click="gotoTab(item.to)">
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-divider class="mb-4"></v-divider>
    <router-view></router-view>
    <PatientMedia v-show="tab === 'files'" />
    <PatientMedia v-show="tab === 'dependents'" component="dependents" />
    <PatientHealthData v-show="tab === 'my-health-data'" :obType="obType" />
    <PrivateNotes v-show="tab === 'privateNotes'" @openAddNoteDialog="openEditNoteDialog" />
    <div v-show="tab === 'search'">
      <v-progress-linear v-if="searchLoading" color="primary"></v-progress-linear>
      <MedicalRecordSearchResult v-if="searchResult.length" :result="searchResult" :search="search" />
      <p v-if="noResults" class="heading-4 text-center">Nothing found</p>
      <v-data-table :headers="headers" :items="recentSearches" disable-sort mobile-breakpoint="0">
        <template v-slot:item.remove="{ item }">
          <v-btn icon small @click="removeSearch(item.recent)">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <AddNote
      :dialog="addNoteDialog"
      :editId="editId"
      :mode="noteMode"
      @close="closeAddNoteDialog"
      @save="reloadNotes"
    />
  </div>
</template>

<script>
import camelCase from "lodash/camelCase";
import { mapActions, mapState } from "pinia";

import { VisitNoteAPI } from "@/api/visitNote";
import ChatNowAction from "@/components/FloatingChat/ChatNowAction.vue";
import PatientHealthData from "@/components/MedicalRecord/PatientHealthData";
import PatientMedia from "@/components/MedicalRecord/PatientMedia";
import AddNote from "@/components/MedicalRecord/PrivateNotes/AddNote";
import PrivateNotes from "@/components/MedicalRecord/PrivateNotes/Index";
import MedicalRecordSearchResult from "@/components/MedicalRecord/Search";
import MeetNowMenu from "@/components/Patients/MeetNowMenu.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import CallNowAction from "@/components/VoIP/CallNowAction.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";
import { usePatientStore } from "@/pinia-store/patient";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";
import { observationTypes } from "@/utils/observationTypes";

export default {
  name: "PractitionerMedicalRecordVisitNotes",
  components: {
    ChatNowAction,
    CallNowAction,
    MeetNowMenu,
    AddNote,
    PrimaryButton,
    PrivateNotes,
    PatientMedia,
    MedicalRecordSearchResult,
    PatientHealthData,
  },
  data() {
    return {
      loading: false,
      tab: "medical-record",
      search: "",
      searchActive: false,
      recentSearches: [],
      searchResult: [],
      searchLoading: false,
      noResults: false,
      obType: null,
      addNoteDialog: false,
      noteMode: "",
      editId: null,
    };
  },
  computed: {
    ...mapState(useMedicalRecordStore, ["encounters"]),
    ...mapState(usePatientStore, ["patient"]),
    ...mapState(useAuthStore, ["uid", "role"]),
    showAddNoteAction() {
      return this.$route.name === this.routes.practitionerMedicalRecordPrivateNotes && this.role === "practitioner";
    },
    routes() {
      return routesEnum;
    },
    tabItems() {
      return [
        {
          title: "Patient summary",
          to: { name: routesEnum.patientMedicalRecord, params: { patientId: this.$route.params.patientId } },
        },
        {
          title: "Medical records",
          to: {
            name: routesEnum.practitionerMedicalRecordVisitNotes,
            params: { patientId: this.$route.params.patientId },
          },
        },
        {
          title: "Monitoring",
          to: {
            name: routesEnum.practitionerMedicalRecordMonitoringOrders,
            params: { patientId: this.$route.params.patientId },
          },
        },
        {
          title: "Files",
          to: {
            name: routesEnum.practitionerMedicalRecordFiles,
            params: { patientId: this.$route.params.patientId },
          },
        },
        {
          title: "Dependents",
          to: {
            name: routesEnum.practitionerMedicalRecordDependents,
            params: { patientId: this.$route.params.patientId },
          },
        },
        {
          title: "Private Notes",
          to: {
            name: routesEnum.practitionerMedicalRecordPrivateNotes,
            params: { patientId: this.$route.params.patientId },
          },
        },
      ];
    },
    activeTab() {
      return this.tabItems.findIndex((i) => i.to.name === this.$route.name);
    },
    cards() {
      return observationTypes;
    },
    headers() {
      return [
        {
          text: "Recent Searches",
          value: "recent",
          width: "90%",
        },
        {
          text: "Remove",
          value: "remove",
          width: "10%",
        },
      ];
    },
  },
  watch: {
    tab: {
      immediate: true,
      handler(val) {
        if (val === "files") {
          this.setFiles([]);

          this.setComponent("");
          this.fetchFiles();
        }
        if (val === "dependents") {
          this.setFiles([]);
          this.setComponent("dependents");
          this.fetchDependentsFiles();
        }
      },
    },
  },
  methods: {
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useMedicalRecordStore, ["getPatient", "getEncounters", "clearAllFields", "getPrivateNotes"]),
    ...mapActions(useMediaStore, ["fetchFiles", "emptyFiles", "setFileType", "setName", "fetchDependentsFiles"]),
    ...mapActions(useMediaStore, ["setUid", "setComponent", "setFiles"]),
    async onChipChange(event) {
      if (this.search) {
        await this.searchInRecords(this.search);
        if (this.tags[event]) {
          this.searchResult = this.searchResult.filter((result) => {
            const [key] = Object.keys(result);
            return key === camelCase(this.tags[event]);
          });
        }
      } else {
        this.search = this.tags[event];
        if (this.search) {
          await this.searchInRecords(camelCase(this.search));
        }
      }
    },
    gotoTab(params) {
      this.$router.push(params);
    },
    openAddNoteDialog() {
      this.addNoteDialog = true;
    },
    closeAddNoteDialog() {
      this.addNoteDialog = false;
    },
    openEditNoteDialog(id) {
      this.noteMode = "edit";
      this.editId = id;
      this.addNoteDialog = true;
    },
    async reloadNotes() {
      const { patientId } = this.$route.params;
      await this.getPrivateNotes({ patientId, practitionerId: this.uid });
      if (this.noteMode === "edit") {
        this.noteMode = "";
        this.editId = null;
      }
      this.closeAddNoteDialog();
    },
    gotoFiles() {
      this.tab = "files";
    },

    async searchInRecords(value) {
      this.searchResult = [];
      if (value !== "" && value) {
        const recent = JSON.parse(localStorage.getItem("recentSearches"));
        if (recent !== null) {
          const currentDoctorsSearchIndex = recent.findIndex((searches) => searches.id === this.uid);
          if (currentDoctorsSearchIndex !== -1) {
            if (recent[currentDoctorsSearchIndex].searches.indexOf(value) === -1) {
              recent[currentDoctorsSearchIndex].searches.push(this.search);
            }
          } else {
            recent.push({
              id: this.uid,
              searches: [value],
            });
          }
          localStorage.setItem("recentSearches", JSON.stringify(recent));
        } else {
          localStorage.setItem("recentSearches", JSON.stringify([{ id: this.uid, searches: [value] }]));
        }
        this.searchLoading = true;
        const response = await VisitNoteAPI.searchVisitNote(this.patient.id, value);
        this.noResults = !response.length;
        response.forEach((result) => {
          const values = Object.values(result);
          values.forEach((objValue) => {
            for (const key in objValue) {
              const parsedInformation = JSON.stringify(objValue[key]);
              if (key === value || parsedInformation.search(value) !== -1) {
                const exists = this.searchResult.findIndex((item) => item[key]);
                if (exists !== -1) {
                  if (Array.isArray(objValue[key])) {
                    const [arrValues] = objValue[key];
                    this.searchResult[exists][key].push(arrValues);
                  } else {
                    this.searchResult[exists][key].push(objValue[key]);
                  }
                } else {
                  if (Array.isArray(objValue[key])) {
                    this.searchResult.push({
                      [key]: objValue[key],
                    });
                  } else {
                    this.searchResult.push({
                      [key]: [objValue[key]],
                    });
                  }
                }
              }
            }
          });
        });
        this.tab = "medical-record";
        this.getRecentSearches();
      }
    },
    showSearchWindow() {
      this.tab = "search";
      this.getRecentSearches();
    },
    removeSearch(name) {
      let recent = JSON.parse(localStorage.getItem("recentSearches"));
      const currentDoctorsSearchIndex = recent.findIndex((searches) => searches.id === this.uid);
      recent[currentDoctorsSearchIndex].searches = recent[currentDoctorsSearchIndex].searches.filter(
        (item) => item !== name,
      );
      localStorage.setItem("recentSearches", JSON.stringify(recent));
      this.getRecentSearches();
    },
    getRecentSearches() {
      if (localStorage.getItem("recentSearches") !== null) {
        const recent = JSON.parse(localStorage.getItem("recentSearches"));
        const currentDoctorsSearchIndex = recent.findIndex((searches) => searches.id === this.uid);
        if (currentDoctorsSearchIndex !== -1) {
          this.recentSearches = recent[currentDoctorsSearchIndex].searches.reverse().map((item) => ({
            recent: item,
          }));
        }
      }
    },
    backToRecords() {
      this.tab = "medical-record";
      this.searchActive = false;
      this.search = "";
    },
  },
  async mounted() {
    const patientId = this.role === RolesEnum.Patient ? this.uid : this.$route.params.patientId;
    await this.getPatientProfile(patientId);
    await this.setUid(patientId);
    if (this.role === RolesEnum.Patient && patientId !== this.uid) {
      await this.$router.push({ path: "/patient/visits" });
    }
    this.loading = true;
    // if (this.$route.query.monitoring) {
    //   const observation = observationTypes.find((ob) => ob.kind === this.$route.query.monitoring);
    //   if (observation) {
    //     this.tab = "my-health-data";
    //     this.obType = this.$route.query.monitoring;
    //   }
    // }
    // await this.getEncounters({ patientId, status: [EncounterStatusEnum.finished, EncounterStatusEnum.onleave] });

    this.loading = false;
  },
  beforeDestroy() {
    this.clearAllFields();
  },
};
</script>

<style lang="scss">
.wrap-patient-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;

  &.smAndDown {
    justify-content: start !important;
  }

  &.xs {
    button {
      width: 100%;
    }
  }
}

.medical-record {
  background: #fafafa;
  padding: 0 30px 30px;
  flex: 1;
  @include mobile {
    padding: 0 14px 30px;
  }

  .tab-wrap {
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }

    .tab_item {
      letter-spacing: normal;
      color: #25233a;
      font-size: 14px;
      opacity: 0.4;
      font-weight: 500;
      font-style: normal;

      &.v-tab--active {
        color: var(--primary);
        opacity: 1;
      }
    }
  }

  .healthdata__add-btn {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: normal;
    box-shadow: none;
    text-transform: capitalize;
  }

  .healthdata__item.v-list-item {
    transition: 0.3s ease;

    &:hover {
      background-color: rgba(47, 207, 111, 0.08);
      color: #2fcf6f;
      cursor: pointer;
    }
  }
}
</style>
