<template>
  <div>
    <v-row>
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-toolbar-title>
              {{ formatDate(currentDate).format("MMMM YYYY") }}
            </v-toolbar-title>
            <v-btn
              :disabled="formatDate(currentDate).format('YYYY-MM') === formatDate(currentMonth).format('YYYY-MM')"
              color="grey darken-2"
              fab
              small
              text
              @click="prevMonth"
            >
              <v-icon> mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn color="grey darken-2" fab small text @click="nextMonth">
              <v-icon> mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <IconicButton left-icon="mdi-plus" lite text="Add availability" @onClick="addSlot" />
            <IconicButton left-icon="mdi-plus" lite text="Create planning" @onClick="addSchedule" />
          </v-toolbar>
        </v-sheet>
      </v-col>
      <v-progress-linear v-if="isLoading" color="primary" indeterminate />
    </v-row>
    <v-row>
      <v-col md="4" order="2">
        <v-card class="mx-auto">
          <v-list>
            <v-list-group v-model="openedSchedules" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Schedules</v-list-item-title>
                </v-list-item-content>
              </template>
              <div v-for="item in schedules" :key="item.id">
                <v-list-item class="pl-2 justify-space-between">
                  <v-list-item-action>
                    <v-switch
                      :value="selectedSchedulesIds.includes(item.id)"
                      class="switcher"
                      label="Show slots"
                      title="Show on calendar"
                      @change="toggleSchedule(item)"
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ toScheduleName(item) }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.comment?.length">{{ item.comment }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="d-flex flex-row gap-1">
                    <v-btn icon @click="() => editSchedule(item)">
                      <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn color="error" icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-for="slot in slotsOfSchedule(item.id)" :key="slot.id" class="pl-2 justify-space-between">
                  <v-list-item-content>
                    <v-list-item-title>{{ slot.appointmentTypeId || "" }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.comment?.length">
                      {{ slot.start }}
                      <p v-if="slot.repeat">Repeat {{ slot.repeatType }}</p>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="d-flex flex-row gap-1">
                    <v-btn icon @click="() => onEditSlot(slot)">
                      <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn color="error" icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col md="8" order="1">
        <v-sheet height="700">
          <v-calendar
            ref="calendar"
            :event-color="getEventColor"
            :events="events"
            :start="currentDate"
            :type="calendarValue"
            @click:time="selectCalendarTime"
            @click:event="selectCalendarEvent"
            @click:more="onMoreClick"
            @click:date="onCalendarDateClick"
          >
            <template v-slot:event="{ event }">
              <div class="d-flex align-center px-2">
                <v-tooltip v-if="event.slot && event.slot.repeat" top>
                  <template v-slot:activator="{ on, attrs }">
                    <img alt="repeat" src="@/assets/icons/repeat-blue.svg" v-bind="attrs" v-on="on" />
                  </template>
                </v-tooltip>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" size="18" v-bind="attrs" v-on="on"> mdi-calendar-today</v-icon>
                  </template>
                </v-tooltip>
                <span class="ml-2" style="font-size: 14px"
                  >{{ formatDate(event.start).format("HH:mm") }} - {{ formatDate(event.end).format("HH:mm") }}</span
                >
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <NewHours :closeDialog="closeNewHoursDialog" :dialog="newHoursDialog" :start="newHoursStartDate" />
    <UpdateHours
      :closeDialog="closeUpdateHoursDialog"
      :dialog="updateHoursDialog"
      :start="newHoursStartDate"
      :updatedEvent="updatedEvent"
    />
    <SlotEditModal @changed="reloadSlots" />
    <ScheduleEditModal />
    <Reset :closeDialog="closeResetDialog" :dialog="resetDialog" />
    <More :availabilities="moreCustomAvailabilities" :closeDialog="closeMoreDialog" :dialog="moreDialog" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState, mapWritableState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";
import More from "@/components/EditAvailability/Modals/More";
import NewHours from "@/components/EditAvailability/Modals/NewHours";
import Reset from "@/components/EditAvailability/Modals/Reset";
import UpdateHours from "@/components/EditAvailability/Modals/UpdateHours";
import IconicButton from "@/components/uikit/IconicButton.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useScheduleStore } from "@/pinia-store/schedule";
import { defaultFormSlot, SlotAppointmentTypeEnum, useSlotsStore } from "@/pinia-store/slots";
import ScheduleEditModal from "@/views/Owner/Organizations/OrganizationSchedule/EditModal.vue";
import SlotEditModal from "@/views/Owner/Organizations/OrganizationSchedule/SlotEditModal.vue";

export default {
  name: "CustomHours",
  components: {
    ScheduleEditModal,
    SlotEditModal,
    IconicButton,
    NewHours,
    Reset,
    UpdateHours,
    More,
  },
  data: () => ({
    ready: false,
    isLoading: false,
    openedSchedules: true,
    currentDate: new Date(),
    newHoursDialog: false,
    resetDialog: false,
    newHoursStartDate: "",
    updateHoursDialog: false,
    calendarValue: "month",
    moreDialog: false,
    updatedEvent: {},
    moreCustomAvailabilities: [],
    slots: [],
  }),
  computed: {
    ...mapWritableState(useScheduleStore, ["editDialog"]),
    ...mapState(useSlotsStore, ["events", "slotsOfSchedule"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useSlotsStore, ["customAvailabilities"]),
    ...mapWritableState(useScheduleStore, ["schedules", "selectedSchedulesIds"]),

    currentMonth() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  async mounted() {
    console.log("mounted");
    await this.fetchSchedules({ practitionerId: this.uid });
  },
  methods: {
    async toggleSchedule(item) {
      this.isLoading = true;
      if (this.selectedSchedulesIds.includes(item.id)) {
        this.selectedSchedulesIds = this.selectedSchedulesIds.filter((id) => id !== item.id);
      } else {
        this.selectedSchedulesIds.push(item.id);
      }
      await this.fetchSlotsBySchedules({ scheduleIds: this.selectedSchedulesIds });
      this.isLoading = false;
    },
    selectCalendarTime(value) {
      console.log(value);
    },
    selectCalendarEvent(value) {
      console.log(value);
      let slot = value.event.slot;
      let start = this.$moment(slot.start);
      let end = this.$moment(slot.end);
      this.setSlotForm({
        ...slot,
        date: start.isValid() ? start.format("YYYY-MM-DD") : "",
        start: start.isValid() ? start.format("HH:mm") : "",
        end: end.format("HH:mm"),
      });
      this.toggleEditDialogSlot(true);
    },
    onEditSlot(slot) {
      let start = this.$moment(slot.start);
      let end = this.$moment(slot.end);
      this.setSlotForm({
        ...slot,
        date: start.isValid() ? start.format("YYYY-MM-DD") : "",
        start: start.isValid() ? start.format("HH:mm") : "",
        end: end.format("HH:mm"),
      });
      this.toggleEditDialogSlot(true);
    },
    async reloadSlots() {
      this.isLoading = true;
      await this.fetchSlotsBySchedules({ scheduleIds: this.selectedSchedulesIds });
      this.isLoading = false;
    },
    toScheduleName(item) {
      const start = this.$moment(item.start);
      const end = this.$moment(item.end);
      return `${start.isValid() ? start.format(timeDisplayFormat) : ""} - ${
        end.isValid() ? end.format(timeDisplayFormat) : ""
      }`;
    },
    toSlotName(item) {
      console.log(item);
      const start = this.$moment(item.start);
      const end = this.$moment(item.end);
      return `${start.isValid() ? start.format("timeDisplayFormat") : ""} - ${
        end.isValid() ? end.format(timeDisplayFormat) : ""
      }`;
    },
    editSchedule(item) {
      this.setForm(item);
      this.editDialog = true;
    },
    addSchedule() {
      this.clearForm();
      this.editDialog = true;
    },
    onCalendarDateClick(e) {
      console.log("on onCalendarDateClick", e);
    },
    getEventColor(event) {
      console.log(event);
      switch (event?.slot?.appointmentTypeId) {
        case SlotAppointmentTypeEnum.EMERGENCY:
          return "#f2994a";
        case SlotAppointmentTypeEnum.FOLLOWUP:
          return "#673ab7";
        case SlotAppointmentTypeEnum.WALKIN:
          return "blue";
        case SlotAppointmentTypeEnum.ROUTINE:
          return "green";
        case SlotAppointmentTypeEnum.CHECKUP:
          return "#fb8c00";
      }
      return "blue";
      // return event.color;
    },
    ...mapActions(useScheduleStore, ["setForm", "clearForm", "fetchSchedules"]),
    ...mapActions(useSlotsStore, { setSlotForm: "setForm" }),
    ...mapActions(useSlotsStore, ["toggleEditDialogSlot", "fetchSlotsBySchedules", "fetchSlotsBySchedules"]),
    addSlot() {
      this.setSlotForm(defaultFormSlot);
      this.toggleEditDialogSlot(true);
    },
    formatDate(datetime = undefined) {
      return moment(datetime);
    },
    prevMonth() {
      this.currentDate = moment(this.currentDate).subtract(1, "month").format();
      this.$refs.calendar.prev();
    },
    nextMonth() {
      this.currentDate = moment(this.currentDate).add(1, "month").format();
      this.$refs.calendar.next();
    },
    closeNewHoursDialog() {
      this.newHoursDialog = false;
      this.newHoursStartDate = "";
    },
    closeUpdateHoursDialog() {
      this.updateHoursDialog = false;
    },
    closeResetDialog() {
      this.resetDialog = false;
    },
    closeMoreDialog() {
      this.moreDialog = false;
    },
    onMonthDateClick(e) {
      if (!this.updateHoursDialog && !this.moreDialog) {
        this.newHoursDialog = true;
        this.newHoursStartDate = e.date;
      }
    },
    onMoreClick(e) {
      this.moreCustomAvailabilities = this.events.filter(
        (availability) => moment(availability.start).format("YYYY:MM:DD") === moment(e.date).format("YYYY:MM:DD"),
      );
      this.moreDialog = true;
    },
    onEventClick(e) {
      this.updateHoursDialog = true;
      this.updatedEvent = JSON.parse(JSON.stringify(e.event));
      this.newHoursStartDate = e.day.date;
    },
  },
};
</script>

<style lang="scss">
.custom-hours-calendar {
  .v-calendar-weekly__head-weekday {
    background: rgba(188, 202, 213, 0.2);
    height: 52px;
    line-height: 52px;

    &.v-past,
    &.v-present,
    &.v-future {
      color: #000000 !important;
    }
  }

  .v-calendar-weekly__day {
    border: 1px solid #e0e0e0;

    &:hover {
      background: #eff6ff !important;
      cursor: pointer;
    }
  }
}
</style>
