import moment from "moment-timezone";
import { defineStore } from "pinia";

import { IScheduleGetAllFilter, ScheduleApi } from "@/api/schedule";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { IPractitionerRole } from "@/pinia-store/practitionerRole";
import { Practitioner } from "@/types/users";

export interface IPeriod {
  start: string;
  end: string;
}

export enum SlotStatusEnum {
  "busy" = "busy",
  "free" = "free",
  "busy-unavailable" = "busy-unavailable",
  "busy-tentative" = "busy-tentative",
  "entered-in-error" = "entered-in-error",
}

export enum SlotEndTypeEnum {
  never = "never",
  endDate = "endDate",
  occurrence = "occurrence",
}

export enum SlotRepeatTypeEnum {
  "daily" = "daily",
  "weekly" = "weekly",
  "bi-weekly" = "bi-weekly",
  "monthly" = "monthly",
  "yearly" = "yearly",
}

export interface ISlot {
  id?: string;
  start?: string;
  end?: string;
  status?: SlotStatusEnum;
  scheduleId: string;
  overbooked?: boolean;
  date?: string;
  repeat?: boolean;
  repeatType?: SlotRepeatTypeEnum;
  repeatInterval?: number;
  days?: string[];
  endType?: SlotEndTypeEnum;
  endDate?: string;
  occurrence?: number;
}

export interface ISchedule {
  id?: string;
  actorHealthcareServiceIds?: string[];
  actorHealthcareServiceId?: string;
  actorPractitionerId?: string;
  comment?: string;
  start?: string;
  end?: string;
  planningHorizon?: IPeriod;
  active?: boolean;
  slots: ISlot[];
}

export interface IScheduleStore {
  practitionersWithSlots: Practitioner[];
  selectedPractitionerWithSlots: string;
  chosenPractitioner: string;
  slotInformation: any;
  pendingCheckouts: [];
  form: Partial<ISchedule>;
  schedule: Partial<ISchedule>;
  schedules: ISchedule[];
  editDialog: boolean;
  isSaving?: boolean;

  selectedSchedulesIds: string[];
}

export const useScheduleStore = defineStore({
  id: "schedule",
  state: (): IScheduleStore => ({
    form: {
      planningHorizon: { start: "", end: "" },
      slots: [],
      comment: "",
      actorHealthcareServiceIds: [],
    },
    editDialog: false,
    isSaving: false,
    schedule: {},
    schedules: [],
    selectedSchedulesIds: [],
    selectedPractitionerWithSlots: "All",
    practitionersWithSlots: [],
    chosenPractitioner: "All",
    slotInformation: {},
    pendingCheckouts: [],
  }),

  actions: {
    async fetchOrganizationSchedules(organization: string): Promise<void> {
      this.schedules = await ScheduleApi.getAll({ organization });
    },
    async fetchSchedules(filters: IScheduleGetAllFilter): Promise<void> {
      this.schedules = await ScheduleApi.getAll(filters);
    },
    setSchedule(item: ISchedule) {
      this.schedule = item;
    },
    setSchedules(item: ISchedule[]) {
      this.schedules = item;
    },
    clearForm() {
      this.form = {
        planningHorizon: { start: "", end: "" },
        slots: [],
        actorHealthcareServiceIds: [],
        actorPractitionerId: "",
        comment: "",
      };
    },
    changeFormField(value: any, field: string) {
      this.form[field] = value;
    },
    appendFormSlot() {
      if (!this.form.slots) this.form.slots = [];
      const workingHours = moment("08:00", "HH:mm");
      this.form.slots.push({
        repeat: false,
        start: workingHours.format("HH:mm"),
        end: workingHours.add(9, "hour").format("HH:mm"),
        repeatType: SlotRepeatTypeEnum.daily,
        repeatInterval: 1,
        endType: SlotEndTypeEnum.never,
        occurrence: 1,
        endDate: "",
        date: "",
        days: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
      });
    },
    changeFormSlotField(value: any, field: string, slotIndex: number) {
      console.log(value, field, slotIndex);
      if (!this.form.slots) this.form.slots = [];
      if (!this.form.slots[slotIndex]) return;
      const item = this.form.slots[slotIndex];
      item[field] = value;
    },
    changeSlotRepeat(value: any, slotIndex: number) {
      if (!this.form.slots) this.form.slots = [];
      if (!this.form.slots[slotIndex]) return;
      const item = this.form.slots[slotIndex];
      item.repeat = value;
      console.log(item);
    },
    removeFormSlot(slotIndex: number) {
      if (!this.form.slots) this.form.slots = [];
      this.form.slots.splice(slotIndex, 1);
    },
    setForm(item: IPractitionerRole) {
      this.form = item;
      if (!this.form.planningHorizon) {
        this.form.planningHorizon = { start: "", end: "" };
      }
      if (!this.form.slots) this.form.slots = [];
    },
    async saveForm() {
      this.isSaving = true;
      const isUpdate = Boolean(this.form.id);
      const data = { ...this.form };
      const start = moment(data.start);
      const end = moment(data.end);
      data.start = start.isValid() ? start.format() : "";
      data.end = end.isValid() ? end.format() : "";
      data.slots = (data.slots || []).map((slot: ISlot) => {
        const start = moment(slot.start);
        const end = moment(slot.end);
        return { ...slot, start: start.isValid() ? start : "", end: end.isValid() ? end : "" };
      });
      try {
        if (this.form.id) this.form = await ScheduleApi.update(data);
        else this.form = await ScheduleApi.create(data);
        snackBarEventBus.$emit(snackBarEventName, {
          message: isUpdate ? "Schedule updated" : "Schedule created",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "An error occurred",
          type: "error",
        });
      }
      this.isSaving = false;
    },
  },
  getters: {
    getScheduleById:
      (state) =>
      (id: string): ISchedule | undefined => {
        return state.schedules.find((schedule: ISchedule) => schedule.id === id);
      },
  },
});
