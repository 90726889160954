<template>
  <div class="page-not-found">
    <img :src="require('@/assets/paper-lists.svg')" />
    <h2 class="heading-2 text-error">The visit has expired</h2>
    <h3 class="sub-heading-1">{{ visitDescription }}</h3>
    <v-btn color="primary" outlined @click="redirectVisitCenter">{{ $t("visits.expire.button") }}</v-btn>
  </div>
</template>
<script>
import { mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  name: "ExpiredEncounter",
  computed: {
    ...mapState(useAuthStore, ["role"]),
    ...mapState(useEncountersStore, ["currentEncounter"]),
    visitDescription() {
      if (this.role === RolesEnum.Patient) {
        return `Your appointment with ${this.currentEncounter?.practitioner?.name} was not attended within the scheduled timeframe. To ensure that your healthcare needs are met, please reschedule your appointment at your earliest convenience.`;
      }
      return `The scheduled session with ${this.currentEncounter?.patient?.name} was not attended. Please review the appointment details and contact the patient to reschedule if necessary.`;
    },
  },
  methods: {
    async redirectVisitCenter() {
      if (this.role === RolesEnum.Practitioner) return await this.$router.push(`/${this.role}/appointments`);
      return await this.$router.push(`/${this.role}/visits/upcoming/`);
    },
  },
};
</script>
