<template>
  <div class="media-uploader">
    <VueFileAgent
      ref="vueFileAgent"
      :accept="acceptFiles"
      :deletable="true"
      :errorText="{
        type: 'Invalid file type. Only images or zip Allowed',
        size: 'Files should not exceed 10MB in size',
      }"
      :maxFiles="14"
      :maxSize="'20MB'"
      :meta="true"
      :multiple="true"
      :theme="'list'"
      class="custom-file-agent"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      @select="filesSelected($event)"
    >
      <template v-slot:file-preview="slotProps">
        <div :key="slotProps.index" class="grid-box-item file-row"></div>
      </template>
      <template v-slot:file-preview-new>
        <div key="new" class="media-uploader__drop-text">
          <div>Drag&drop or <a class="" href="#">Browse file</a></div>
          <div key="new" class="media-uploader__drop-subtitle">File should be PDF, JPEG</div>
        </div>
      </template>
    </VueFileAgent>
    <v-progress-linear v-if="loading" color="primary" indeterminate />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { MediaApi } from "@/api/media";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "MediaUploader",
  data() {
    return {
      loading: false,
      fileRecordsForUpload: [],
      fileRecordsNewlySelected: [],
    };
  },
  computed: {
    ...mapState(useMediaStore, ["uid", "component", "acceptFiles"]),
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchFiles", "emptyFiles", "fetchDependentsFiles"]),
    ...mapActions(useMediaStore, ["setUid", "setComponent"]),
    async filesSelected(fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      await this.uploadFiles();
    },
    async uploadFiles() {
      this.loading = true;
      const formData = new FormData();
      formData.append("patientId", this.uid);
      this.fileRecordsForUpload.map((file) => formData.append("file", file.file));
      if (this.component && this.component?.length > 0) formData.append("component", this.component);
      const filesUploaded = await MediaApi.create(formData);
      await this.$emit("onUploaded", filesUploaded);
      this.fileRecordsForUpload = [];
      this.fileRecordsNewlySelected = [];
      this.loading = false;
      if (this.component && this.component?.length > 0) {
        this.setUid(this.uid);
        this.setComponent(this.component);
        if (this.component === "dependents") {
          await this.fetchDependentsFiles();
        } else {
          await this.fetchFiles();
        }
      } else {
        this.setUid(this.uid);
        await this.fetchFiles();
      }
    },
    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
      }
    },
    fileDeleted: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>

<style lang="scss">
.media-uploader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .file-input {
    opacity: 0;
  }

  .file-preview-wrapper {
    display: none;
  }

  .custom-file-agent .vue-file-agent.file-input-wrapper {
    border: 1.5px dashed #2196f3;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__drop-text {
    display: flex;
    background: #f4fafe;
    padding: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: rgba(33, 150, 243, 0.6);
  }

  &__drop-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    color: var(--primary);
    opacity: 0.6;
    padding: 24px;
    padding-top: 8px;
    padding-bottom: 0;
  }
}
</style>
