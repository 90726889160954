<template>
  <div class="monitoring-layout">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-row>
      <v-col v-if="!hideHeaders" class="header" cols="12">
        <span class="heading-2">{{ title }}</span>
        <PrimaryButton v-if="userIsPatient" :text="`Add ${title}`" leftIcon="mdi-plus" @onClick="onCreate" />
      </v-col>
      <v-col cols="12">
        <OxygenSaturationTable :disable-edit="!userIsPatient" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { OXYGEN_SATURATION_ADDED } from "@/eventBuses/monitoringEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { useObservationsStore } from "@/pinia-store/observations";
import { RolesEnum } from "@/types/Roles.enum";
import OxygenSaturationTable from "@/views/Patient/Monitoring/oxygenSaturation/Table";

export default {
  name: "MonitoringOxygenSaturation",
  components: { OxygenSaturationTable, PrimaryButton },
  props: {
    hideHeaders: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      observationType: {},
      noResults: false,
    };
  },
  computed: {
    ...mapState(useObservationsStore, { order: "oxygenSaturationOrder" }),
    ...mapState(useObservationsStore, ["oxygenSaturation"]),
    ...mapState(useAuthStore, ["uid", "role"]),
    title() {
      return "Oxygen saturation";
    },
    userIsPatient() {
      return this.role === RolesEnum.Patient;
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["getOxygenSaturation"]),
    ...mapActions(useObservationsStore, [
      "setOxygenSaturationOrder",
      "clearEditForm",
      "changeEditFormField",
      "setPatientId",
      "clearOxygenSaturationForm",
      "fillOxygenSaturationForm",
      "fillEditForm",
      "toggleOxygenSaturationFormOpened",
    ]),
    async reload() {
      this.loading = true;
      await this.getOxygenSaturation();
      this.loading = false;
    },

    onCreate() {
      this.clearOxygenSaturationForm();
      this.toggleOxygenSaturationFormOpened();
    },
    async onEdit(item) {
      await this.fillOxygenSaturationForm(item);
      this.toggleOxygenSaturationFormOpened();
    },
  },
  beforeDestroy() {
    this.$root.$off(OXYGEN_SATURATION_ADDED, this.reload);
  },
  async mounted() {
    this.loading = true;
    const patientId = this.userIsPatient ? this.uid : this.$route.params.patientId;
    this.$root.$on(OXYGEN_SATURATION_ADDED, this.reload);
    await this.setPatientId(patientId);

    await this.reload();
    try {
      const order = await ServiceRequestApi.getPatientOxygenSaturationOrder(patientId);
      this.setOxygenSaturationOrder(order);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.v-data-table-header {
  background: rgba(231, 232, 242, 0.21);
}

.table_actions {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary);
}

.monitoring {
  background: #fafafa;
  padding: 0 30px;

  .mark-row {
    display: flex;
    align-items: center;
    gap: 5px;

    &.danger {
      color: red;

      i {
        color: red;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
