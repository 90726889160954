export const cliniciansHeaders = [
  {
    text: "Name",
    value: "name",
    sortable: false,
  },
  {
    text: "Email",
    value: "email",
    sortable: false,
  },
  {
    text: "State",
    value: "state",
  },
  {
    text: "Registrars",
    value: "registrars",
  },
  {
    text: "",
    value: "actions",
    sortable: false,
  },
];

export const timeDisplayFormat = "MM-DD-YYYY";
export const timestampDisplayFormat = "MM-DD-YYYY HH:mm A";
export const timeISO8601OFormat = "YYYY-MM-DD";
export const calendarTimeFormat = "YYYY-MM-DD HH:mm";
