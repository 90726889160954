<template>
  <div>
    <div class="container-filters">
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mr-2 filter-name">Patients:</p>
        <v-select
          :disabled="isLoading || loading"
          :items="patientsOptions"
          class="v-input--hide-details--custom bg-white"
          dense
          height="32px"
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeSelectedPatient($event)"
        ></v-select>
      </div>
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mx-2 filter-name">Date Range:</p>
        <RangeDatePicker
          v-model="currentDate"
          :clearRangeDatePicker="clearRangeDatePicker"
          :dense="true"
          :height="34"
          :hide-title="true"
          :hideDetails="true"
          :inputValue="currentDate"
          :isYearFirst="false"
          className="date-picker__box-shadow v-input--hide-details--custom"
          @setDate="changeCurrentDate"
        />
      </div>
    </div>
    <div>
      <v-progress-linear v-if="loading || isLoading" color="primary" indeterminate />
      <div v-if="showNoScreen" class="ap-list_no-items">
        <img alt="No Items" src="@/assets/no-items-calendar.svg" />
        <p class="heading-4">There is no appointments to display here</p>
        <p class="sub-heading-3">Book a visit with a doctor first.</p>
        <OutlinedButton
          color="primary"
          style="color: var(--primary); opacity: 1; border: 1px solid var(--primary)"
          text="Schedule a New Visit"
          @onClick="$emit('toSchedule')"
        />
      </div>
      <div class="ap-list">
        <div v-for="(pendingAppointments, key) in formatterItems" :key="key" class="ap-list">
          <h1 class="ap-list_wrapTitle">{{ formaWrapTitle(key) }}</h1>
          <AppointmentsListItem
            v-for="appointment in pendingAppointments"
            :key="appointment.id"
            :item="appointment"
            place="pending"
            role="practitioner"
            @onButtonClick="resendInvite"
            @onCancel="cancel"
          />
        </div>
      </div>
    </div>
    <CancelVisit
      v-model="dialog"
      :appointment-id="cancelledId"
      :isFromAppointments="true"
      @success="getPendingAppointmentsAfterCancel"
    />
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { CheckoutApi } from "@/api/checkout";
import AppointmentsListItem from "@/components/AppointmentsListItem/index.vue";
import CancelVisit from "@/components/CancelVisit";
import RangeDatePicker from "@/components/shared/RangeDatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useInvitesStore } from "@/pinia-store/invites";
import { useOwnersStore } from "@/pinia-store/owners";
import { usePatientsStore } from "@/pinia-store/patients";

export default {
  name: "Pending",
  props: ["isLoading", "isSwitchTab"],
  data: () => ({
    currentDate: [moment().format("YYYY-MM-DD"), moment().add(7, "days").format("YYYY-MM-DD")],
    patientId: null,
    dialog: false,
    cancelledId: null,
    loading: false,
    clearRangeDatePicker: false,
  }),
  components: {
    RangeDatePicker,
    AppointmentsListItem,
    CancelVisit,
    OutlinedButton,
  },
  computed: {
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useOwnersStore, ["pendingCheckouts"]),
    ...mapState(useAppointmentsStore, ["pendingAppointments", "pendingAppointmentsPagination"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["timeZone"]),
    showNoScreen() {
      return !this.isLoading && this.formatterItems.length === 0;
    },
    formatterItems() {
      const allItems = [...(this.pendingAppointments || [])];
      allItems.sort((a, b) => new Date(a.start) - new Date(b.start));
      return groupBy(
        (allItems || [])
          .map((i) => ({ ...i, _group: moment(i.start).format("YYYY-MM-DD") }))
          .filter((i) => {
            return !this.$moment.tz(this.timeZone).isAfter(i.start);
          }),
        (i) => i._group,
      );
    },
    datePickerValue() {
      const startWeek = moment(this.currentDate).startOf("isoWeek").weekday(1).format("MMMM, D");
      const endWeek = moment(this.currentDate).endOf("isoWeek").weekday(1).format("MMMM, D");
      return `${startWeek} - ${endWeek}`;
    },
    patientsOptions() {
      const patients = this.patients.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
      patients.unshift({
        text: "All",
        value: null,
      });
      return patients;
    },
  },

  mounted() {
    this.isSwitchTab.subscribe((val) => {
      this.clearRangeDatePicker = ["Past", this.$t("visits.upcoming_short")].includes(val);
      this.currentDate = [
        this.$moment().tz(this.timeZone).format("YYYY-MM-DD"),
        this.$moment().tz(this.timeZone).add(7, "days").format("YYYY-MM-DD"),
      ];
    });
  },

  methods: {
    ...mapActions(useAppointmentsStore, ["getPendingAppointments"]),
    ...mapActions(useInvitesStore, ["sendInvite"]),
    ...mapActions(useOwnersStore, ["getPendingCheckouts"]),
    formaWrapTitle(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    async getPendingAppointmentsAfterCancel() {
      this.loading = true;
      try {
        const datesSorted = this.currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        let queryParams = {
          practitionerId: this.uid,
          patientId: this.patientId,
          status: "pending",
          startDate: datesSorted[0],
        };
        await this.getPendingAppointments(queryParams);
        Object.keys(this.formatterItems).map((key) => {
          const isEqual = this.formatterItems[key].find((item) => item.id === this.cancelledId);
          if (isEqual) {
            this.formatterItems[key] = this.formatterItems[key].filter((item) => item.id !== this.cancelledId);
          }
        });
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async changeSelectedPatient(selected) {
      this.patientId = selected;
      this.loading = true;
      try {
        let queryParams = {
          practitionerId: this.uid,
          patientId: this.patientId,
          status: "pending",
        };
        const datesSorted = this.currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());

        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        } else {
          queryParams.date = datesSorted[0];
        }
        await this.getPendingAppointments(queryParams);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async changeCurrentDate(newDate) {
      this.currentDate = newDate;
      const datesSorted = this.currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
      try {
        this.loading = true;
        let queryParams = {
          practitionerId: this.uid,
          patientId: this.patientId,
          status: "pending",
        };
        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        } else {
          queryParams.date = datesSorted[0];
        }
        await this.getPendingAppointments(queryParams);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async resendInvite(checkoutId) {
      await CheckoutApi.resend(checkoutId);
    },
    cancel(slotId) {
      this.dialog = true;
      this.cancelledId = slotId;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-filters {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 320px));
  padding-bottom: 8px;

  .filter-name {
    min-width: fit-content;
  }
}
</style>
