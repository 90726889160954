<template>
  <v-data-table
    v-if="bloodPressure.length"
    :class="`table fixed data-table data-table__green-border table-bordered `"
    :headers="headers"
    :items="historyItems"
    mobile-breakpoint="0"
  >
    <template v-if="!disableEdit" v-slot:item.action="{ item }">
      <div class="table_actions" @click="onEdit(item)">Edit</div>
    </template>
    <template v-slot:item.systolic="{ item }">
      <div
        v-if="item.systolic"
        :class="`${isDanger(item.systolic, order.systolicLow, order.systolicHigh) && 'danger'} mark-row`"
      >
        <span class="text--accent-1">{{ item.systolic }}</span>
        <span v-if="Object.keys(order).length" class="text--secondary"
          >({{ order.systolicLow }}-{{ order.systolicHigh }})</span
        >
        <v-icon v-if="item.systolic > order.systolicHigh"> mdi-arrow-up-thin</v-icon>
        <v-icon v-if="item.systolic < order.systolicLow"> mdi-arrow-down-thin</v-icon>
      </div>
    </template>
    <template v-slot:item.diastolic="{ item }">
      <div
        v-if="item.diastolic"
        :class="`${isDanger(item.diastolic, order.diastolicLow, order.systolicHigh) && 'danger'} mark-row d-flex`"
      >
        <span class="text--accent-1">{{ item.diastolic }}</span>
        <span v-if="Object.keys(order).length" class="text--secondary"
          >({{ order.diastolicLow }}-{{ order.diastolicHigh }})</span
        >
        <v-icon v-if="item.diastolic > order.diastolicHigh"> mdi-arrow-up-thin</v-icon>
        <v-icon v-if="item.diastolic < order.diastolicLow"> mdi-arrow-down-thin</v-icon>
      </div>
    </template>
    <template v-slot:item.performer="{ item }">
      <span v-for="performer in item.performer" :key="performer.id"
        >{{ performer.firstName }} {{ performer.lastName }}</span
      >
    </template>
    <template v-slot:item.name="{ item }">
      <div class="table_actions">
        {{ item.name }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import inRange from "lodash/inRange";
import { mapActions, mapState } from "pinia";

import { timeDisplayFormat } from "@/components/Clinicians/constants";
import { useAuthStore } from "@/pinia-store/auth";
import { useObservationsStore } from "@/pinia-store/observations";

export default {
  name: "BloodPressureTable",
  props: {
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      tab: "medical-record",
      observationType: {},
      search: "",
      searchActive: false,
      recentSearches: [],
      searchResult: [],
      searchLoading: false,
      noResults: false,
    };
  },
  computed: {
    ...mapState(useObservationsStore, ["bloodPressure"]),
    ...mapState(useObservationsStore, { order: "bloodPressureOrder" }),
    ...mapState(useAuthStore, ["uid"]),
    title() {
      return "Blood pressure";
    },
    historyItems() {
      return this.bloodPressure.map((it) => ({
        ...it,
        datetime: `${this.$moment(it.date).format(timeDisplayFormat)} ${it.time}`,
      }));
    },
    headers() {
      return [
        {
          text: "Systolic (mmHg)",
          value: "systolic",
          width: "175px",
        },
        {
          text: "Diastolic(mmHg)",
          value: "diastolic",
          width: "175px",
        },
        {
          text: "Last update",
          value: "datetime",
          width: "175px",
          separator: ",",
        },
        {
          text: "Updated by",
          value: "performer",
          width: "150px",
          separator: ",",
        },
        {
          text: "Context",
          value: "note",
          width: "125px",
        },
        { text: "", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["getBloodPressure"]),
    ...mapActions(useObservationsStore, [
      "setBloodPressureOrder",
      "clearEditForm",
      "changeEditFormField",
      "setPatientId",
      "clearBloodPressureForm",
      "fillBloodPressureForm",
      "fillEditForm",
      "toggleBloodPressureFormOpened",
    ]),
    isDanger(value, low, high) {
      if (!Object.keys(this.order).length) return false;
      return !inRange(value, low, high);
    },
    async onEdit(item) {
      await this.fillBloodPressureForm(item);
      this.toggleBloodPressureFormOpened();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table-header {
  background: rgba(231, 232, 242, 0.21);
}

.table_actions {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary);
}

.monitoring-layout {
  .mark-row {
    display: flex;
    align-items: center;
    gap: 5px;

    &.danger {
      color: red;

      i {
        color: red;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
