import { defineStore } from "pinia";

export const defaultCssVariables = {
  success: "#2fcf6f",

  error: "#e53935",
  errorlight1: "#FFEFEF",
  primarylight3: "#91909c",
  primarylight2: "#e7e8f2",
  primarylight1: "#eff6ff",
  primarylight: "#EEEFF7",

  primary: "#2196f3",
  primaryblack: "#25233a",
  primaryblack2: "#33343e",
  primaryblack3: "#33343d",
  primaryblack4: "#1e85d9",
  primaryblack5: "#1565c0",

  icon: "#d2d3e1",
};

export interface IThemeColors {
  success: string;
  error: string;
  errorlight1: string;
  primarylight3: string;
  primarylight2: string;
  primarylight1: string;
  primarylight: string;
  primary: string;
  primaryblack: string;
  primaryblack2: string;
  primaryblack3: string;
  primaryblack4: string;
  primaryblack5: string;
  icon: string;
}

export interface IApp {
  logoUrl: string;

  isInMaintenanceMode?: boolean;

  logoReducedUrl: string;
  logoExpandedUrl: string;
  themeColors: IThemeColors;
  brandName: string;
  brandDomain: string;
  brandAddress: string;
  contactPhoneNumber: string;
}

export interface AppStore extends IApp {
  layoutClass: string;
  drawer: boolean;
  sidebarStatus: string;
}

export const useAppStore = defineStore({
  id: "app",
  state: (): AppStore => ({
    themeColors: defaultCssVariables,
    layoutClass: "",
    drawer: false,
    sidebarStatus: "collapsed",
    logoUrl: "",
    logoReducedUrl: "",
    logoExpandedUrl: "",
    brandName: "",
    brandDomain: "",
    brandAddress: "",
    contactPhoneNumber: "",
  }),
  actions: {
    setCssVariables(payload: Record<string, any>) {
      this.cssVariables = payload;
      const doc = document.documentElement;
      console.log("was", payload);
      for (const key in payload) {
        doc.style.setProperty(`--${key}`, `${payload[key]}`);
      }
    },
    setLayoutClass(payload: string) {
      this.layoutClass = payload;
    },
    setDrawer(token: boolean) {
      this.drawer = token;
    },
    setSidebarStatus(token: string) {
      this.sidebarStatus = token;
    },
  },
  getters: {
    telContactPhoneNumber(state: AppStore) {
      return `tel:${state.contactPhoneNumber || ""}`;
    },
  },
});
