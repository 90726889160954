import { apiRequest } from "@/api/apiRequest";

const searchDrugs = async (name: string) => {
  const { data } = await apiRequest.get(`/terminology/search`, { params: { name } });
  return data;
};

const getSmokingBehavior = async () => {
  const { data } = await apiRequest.get(`/terminology/smoking-behavior`);
  return data;
};
const getAllergies = async () => {
  const { data } = await apiRequest.get(`/terminology/allergies`);
  return data;
};
const getDispenseUnits = async () => {
  const { data } = await apiRequest.get(`/terminology/dispenseUnits`);
  return data;
};
const getConditions = async (filter = {}) => {
  const { data } = await apiRequest.get(`/terminology/conditions`, { params: filter });
  return data;
};

const getPharmacySpecialties = async (filter = {}) => {
  const { data } = await apiRequest.get(`/terminology/pharmacy-specialties`, { params: filter });
  return data;
};

const getServiceCategory = async () => {
  const { data } = await apiRequest.get(`/terminology/service-category`);
  return data;
};

const getPractitionerRoles = async () => {
  const { data } = await apiRequest.get(`/terminology/practitioner-role`);
  return data;
};

const getServiceType = async () => {
  const { data } = await apiRequest.get(`/terminology/service-type`);
  return data;
};

const getPracticeCodes = async () => {
  const { data } = await apiRequest.get(`/terminology/practice-codes`);
  return data;
};
export const TerminologyApi = {
  getPractitionerRoles,
  getPracticeCodes,
  getServiceType,
  getServiceCategory,
  getPharmacySpecialties,
  getConditions,
  getAllergies,
  searchDrugs,
  getSmokingBehavior,
  getDispenseUnits,
};
