<template>
  <div class="service-request-observation-root">
    <div v-if="!isEditing" :class="`service-request-observation ${item.status}`">
      <div class="source">
        <img :src="require('@/assets/icons/careIcon.svg')" alt="" />
      </div>
      <ServiceRequestObservationDetails :item="item" />
      <div class="action">
        <div v-if="withEdit" class="edit">
          <v-btn class="edit-btn" depressed @click="$emit('onEdit')">
            <img :src="require('@/assets/icons/restore.svg')" alt="" />
            Change
          </v-btn>
          <v-btn v-if="canRemove" class="remove-btn" depressed @click="$emit('onDelete')">
            <img :src="require('@/assets/icons/trash.svg')" alt="" />
            Remove
          </v-btn>
          <v-btn v-else class="remove-btn" depressed @click="$emit('onDelete')">
            <img :src="require('@/assets/icons/trash.svg')" alt="" />
            Stop
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "pinia";

import ServiceRequestObservationDetails from "@/components/VisitNotes/medicationOrder/ServiceRequestObservationDetails";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { ServiceRequestStatus } from "@/types/ServiceRequestStatus.enum";

export default {
  name: "ServiceRequestObservationField",
  components: { ServiceRequestObservationDetails },
  data() {
    return {
      isEditingStatus: false,
      additionalInstructions: "",
    };
  },
  props: {
    isEditing: {
      type: Boolean,
      default: () => false,
    },
    withStatus: {
      type: Boolean,
      default: () => false,
    },
    withEdit: {
      type: Boolean,
      default: () => false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState(useTerminologyStore, ["practitionerFavMedication"]),
    date() {
      let date = moment(this.item?.startDate);
      return date.isValid() ? date.format("MMM DD, YYYY") : undefined;
    },
    canRemove() {
      return this.item.status === ServiceRequestStatus.draft;
    },
  },
  methods: {
    onChangeStatus(e) {
      this.$emit("onChangeStatus", e);
    },
    onEdit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isEditingStatus = !this.isEditingStatus;
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-request-observation-edit {
  padding: 22px 20px;
}

.service-request-observation {
  display: grid;
  grid-template-columns: 40px auto auto;
  grid-gap: 15px 10px;
  width: 100%;
  padding: 20px 28px 20px 27px;
  border-bottom: 1px solid var(--v-primarylight2-base);
  background-color: #f7fdfa;
  @include tablet {
    display: flex;
    flex-wrap: wrap;
  }

  &.intended {
    background-color: #f7fdf9;
  }

  &.active {
    background-color: white;
  }

  &.completed {
    background-color: white;
  }

  &.stopped {
    background-color: rgba(229, 57, 53, 0.08);
  }

  &.on-hold {
    background-color: #e5e5e5;
  }

  .source {
    flex-basis: 20px;
    @include tablet {
      flex-basis: 100%;
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    @include tablet {
      justify-content: center;
      flex-basis: 100%;
    }

    .edit-btn {
      background-color: $primarylight1 !important;
      color: var(--primary);
    }

    .remove-btn {
      background-color: #fdefef !important;
      color: #e53935;
    }

    .edit {
      display: flex;
      gap: 12px;

      button {
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        .v-btn__content {
          padding: 8px 11px;
        }
      }
    }
  }
}
</style>
