import { apiRequest } from "@/api/apiRequest";

const segment = "/basic/app";
const getApp = async () => {
  const { data } = await apiRequest.get(`${segment}/${process.env.VUE_APP_NAME}`);
  return data;
};
export const AppApi = {
  getApp,
};
