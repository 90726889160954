import { CancelTokenSource } from "axios";

import { IPractitionerRole } from "@/pinia-store/practitionerRole";

import { apiRequest } from "./apiRequest";

const segment = "/practitioner-roles";

const create = async (body: Omit<IPractitionerRole, "id">): Promise<any> => {
  const { data } = await apiRequest.post(segment, body);
  return data;
};

const update = async (body: Partial<IPractitionerRole>): Promise<any> => {
  const { data } = await apiRequest.put(`${segment}/${body.id}`, body);
  return data;
};

const find = async (id: string): Promise<any> => {
  const { data } = await apiRequest.get(`${segment}/${id}`);
  return data;
};

const getAll = async (
  filter: {
    organization?: string;
    practitioner?: string;
  },
  cancelToken?: CancelTokenSource,
): Promise<any> => {
  const { data } = await apiRequest
    .get(segment, {
      params: filter,
      ...(cancelToken ? { cancelToken: cancelToken.token } : {}),
    })
    .catch(() => ({ data: [] }));
  return data;
};
const destroy = async (fileId: string): Promise<any> => {
  const { data } = await apiRequest.delete(`${segment}/${fileId}`);
  return data;
};

export const PractitionerRoleApi = {
  getAll,
  destroy,
  create,
  find,
  update,
};
