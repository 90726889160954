
import { Component, Prop, ProvideReactive, Vue, Watch } from "vue-property-decorator";

import { apiRequest } from "@/api/apiRequest";
import FloatingChat from "@/components/FloatingChat/FloatingChat.vue";
import MaintenancePage from "@/components/MaintenancePage/MaintenancePage.vue";
import AutoSaveIndicator from "@/components/shared/AutoSaveIndicator.vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import SuccessDialog from "@/components/shared/SuccessDialog.vue";
import TwilioContext from "@/context/twilioContext/TwilioContext";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { IApp, useAppStore } from "@/pinia-store/app";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { sleep } from "@/utils/sleep";

//todo: testing mode
// eslint-disable-next-line @typescript-eslint/no-var-requires
@Component({
  components: {
    MaintenancePage,
    AutoSaveIndicator,
    FloatingChat,
    Snackbar,
    SuccessDialog,
  },
})
export default class App extends Vue {
  twilioPlaceholder: TwilioContext | null = null;
  snackbar = false;
  snackbarText = "";
  snackbarType = "";
  logoutDialog = false;
  maintenancePage = false;
  @Prop() readonly appSettings!: IApp;
  @ProvideReactive() injectable = Vue.observable({ twilioContext: this.twilioPlaceholder });
  $userActivityTracker: any;
  private authStore = useAuthStore();
  private appStore = useAppStore();

  get isLoggedIn() {
    return this.authStore.isLoggedIn;
  }

  @Watch(`isLoggedIn`)
  public async isLoggedInWatcher(newVal: boolean) {
    const authStore = useAuthStore();
    if (newVal) {
      this.$userActivityTracker.startTimer();
      window.addEventListener("message", (event) => {
        if (window.ReactNativeWebView && event.data) {
          try {
            const data = JSON.parse(event.data);
            if (data?.notification && data?.data?.kind) {
              let role = authStore.role;
              if (data.data.kind === "message") {
                this.$router.push({ path: `/${role}/chat` });
              }
            }
          } catch (e) {
            console.info(e);
          }
        }
      });
    }

    this.injectable.twilioContext?.shutdown();
    if (!newVal) {
      const patientStore = usePatientStore();
      patientStore.setProxyAccounts([]);
      return;
    }
    this.injectable.twilioContext = new TwilioContext();
    this.injectable.twilioContext?.setCurrentFloatingChannel(null);
    await this.injectable.twilioContext.initialize(authStore.uid);
  }

  onLogoutDialogClose() {
    this.logoutDialog = false;
    if (this.$router.currentRoute.path !== "/auth/signin") this.$router.push({ path: `/auth/signin` });
  }

  async mounted() {
    this.injectable.twilioContext = new TwilioContext();
    console.log("was already mounted");
  }

  async created() {
    // testing more

    this.appStore.setCssVariables(this.appSettings.themeColors);
    if (this.appSettings.isInMaintenanceMode) {
      this.maintenancePage = true;
    }
    this.appStore.$patch({
      logoUrl: this.appSettings.logoUrl,
      logoReducedUrl: this.appSettings.logoReducedUrl,
      logoExpandedUrl: this.appSettings.logoExpandedUrl,
      brandName: this.appSettings.brandName,
      brandDomain: this.appSettings.brandDomain,
      brandAddress: this.appSettings.brandAddress,
      contactPhoneNumber: this.appSettings.contactPhoneNumber,
    });
    // --end testing more
    document.title = this.appSettings.brandDomain || "";

    snackBarEventBus.$on(snackBarEventName, async (message: { message: string; type: string }) => {
      this.snackbar = false;
      await sleep(1);
      this.snackbarText = message.message;
      this.snackbarType = message.type;
      this.snackbar = true;
    });
    window.addEventListener(this.$userActivityTracker.handlerEvent, () => {
      this.authStore.clearLoginInfo();
      this.authStore.logOut();
      if (!this.logoutDialog) {
        this.logoutDialog = true;
      }
    });
    apiRequest.interceptors.response.use(
      (r: any) => r,
      async (err: any) => {
        if (err?.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
          await this.authStore.setRefreshToken();
          err.config.__isRetryRequest = true;
          err.config.headers.Authorization = "Bearer " + this.authStore.token;
          return apiRequest(err.config);
        } else {
          if (
            err?.response?.status === 401 ||
            (err?.response?.status === 400 && err?.response?.data?.error?.code === 400)
          ) {
            this.$userActivityTracker.triggerInactive();
          } else throw err;
        }
      },
    );
    apiRequest.interceptors.request.use(
      (config: any) => {
        if (config && config.headers) {
          config.headers.Authorization = "Bearer " + (this.authStore.token || this.authStore.customToken);
        }
        return config;
      },
      (error: any) => Promise.reject(error),
    );
  }
}

//  helper for mobile
const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", documentHeight);
documentHeight();
