import { Route } from "vue-router";

import { TwoFactorApi } from "@/api/twoFactor";
import { pinia } from "@/main";
import { useAuthStore } from "@/pinia-store/auth";
import { linkToPractitionerPendedNotes } from "@/router/linkGenerator";
import { RolesEnum } from "@/types/Roles.enum";
import { routesEnum } from "@/types/Routes.enum";

export const ifNotAuthenticated = async (to: Route, from: Route, next: any) => {
  const authStore = useAuthStore(pinia);
  const role = authStore.role;
  const isLoggedIn = authStore.isLoggedIn;
  if (!isLoggedIn || !role) {
    return next();
  }
  return next({ path: `/${role}` });
};

export const logOutGuard = async (to: Route, from: Route, next: any) => {
  const authStore = useAuthStore(pinia);

  try {
    await authStore.logOut();
  } catch (err) {
    console.error(err);
  }
  const { role, isLoggedIn } = authStore;
  if (!isLoggedIn) {
    return next();
  }

  return next({ path: `/${role}` });
};

export const roomGuard = async (to: Route, from: Route, next: any) => {
  const authStore = useAuthStore(pinia);

  try {
    await authStore.actualizeUserInfo();
  } catch (err) {
    console.error(err);
  }

  let { isLoggedIn, role } = authStore;

  const detectedRole: RolesEnum = to.query.role ? (to.query.role as RolesEnum) : RolesEnum.Patient;
  if (isLoggedIn && role !== detectedRole) {
    await authStore.logOut();
    isLoggedIn = authStore.isLoggedIn;
    role = authStore.role;
  }

  let redirect;
  if (detectedRole === RolesEnum.Patient) {
    if (isLoggedIn && role === RolesEnum.Patient) return next({ path: `/patient/encounters/${to.params.roomId}` });
    if (!isLoggedIn) redirect = `/patient/encounters/${to.params.roomId}`;
  }
  if (detectedRole === RolesEnum.Practitioner) {
    if (isLoggedIn && role === RolesEnum.Practitioner)
      return next({ path: `/practitioner/encounters/${to.params.roomId}` });
    if (!isLoggedIn) redirect = `/practitioner/encounters/${to.params.roomId}`;
  }

  if (redirect && !isLoggedIn) {
    return next({ path: `/auth/signin?redirect=${redirect}` });
  }
  if (!isLoggedIn) {
    return next();
  }
  if (role === RolesEnum.Practitioner)
    return next({ name: routesEnum.practitionerEncounterReview, params: { encounterId: to.params.roomId } });

  return next({ name: "PatientEncounter", params: { encounterId: to.params.roomId } });
};
export const isAuthenticated = () => {
  const authStore = useAuthStore(pinia);
  return authStore.isAuthenticated; // Assuming isAuthenticated is a property in your auth store
};
export const ifAuthenticated = async (to: Route, from: Route, next: any) => {
  const authStore = useAuthStore(pinia);

  const redirect = to.path;
  const parentRoute = to.matched.find((record) => record.meta.authorize);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { authorize } = parentRoute!.meta;
  try {
    await authStore.actualizeUserInfo();
    const { role, isLoggedIn } = authStore;
    const success = await TwoFactorApi.checkOutQrcode();
    const login = success?.setting ? !success.setting.isTwoFactorAuthenticationEnabled : !success?.success;
    if (login) {
      if (!role || !isLoggedIn) {
        return next({ path: `/auth/signin?redirect=${redirect}` });
      }
      if (authorize && role && !authorize.includes(role)) {
        next({ path: `/${role}` });
      } else {
        next();
      }
    } else {
      if (localStorage.isOk) {
        next();
      } else {
        next({ path: "/auth/code" });
      }
    }
  } catch (err) {
    next({ path: "/auth/signin" });
    console.error(err);
  }
};

export const patientListBreadcrumbs = [
  {
    text: "patients.title",
    to: "patients",
    link: "patients",
    href: "patients",
  },
];
export const clinicianListBreadcrumbs = [
  {
    text: "owner.clinician.title",
  },
];
export const conversationLogs = [
  {
    text: "conversation.logs",
  },
];
export const patientMedicalRecordBreadcrumbs = [
  ...patientListBreadcrumbs,
  {
    text: "Medical Record",
  },
];

export const medicalRecordBreadcrumbs = [
  {
    text: "Medical Record",
  },
];

export const appointmentsBreadcrumbs = [
  {
    text: "practitioner.appointments.title",
    link: "appointments",
  },
];

export const quickActionsBreadcrumbs = [
  {
    text: "general.sidebar.quickActions",
  },
];

export const visitCenterBreadcrumbs = [
  {
    text: "visits.visitCenter",
  },
];

export const chatBreadcrumbs = [
  {
    text: "general.sidebar.messages",
  },
];
export const clinicalRequestsBreadcrumbs = [
  {
    text: "Clinical requests",
  },
];

export const coordinationOfCareBreadcrumbs = [
  {
    text: "Coordination of Care",
  },
];

export const dependentsBreadcrumb = [
  {
    text: "Dashboard ",
    link: "",
  },
  {
    text: "general.sidebar.dependents",
  },
];

export const monitoringBreadcrumbs = [
  {
    text: "My Health Data ",
    link: "monitoring",
  },
];
export const notificationsBreadcrumbs = [
  {
    text: "Dashboard ",
    link: "",
  },
  {
    text: "Notifications ",
    link: "notifications",
  },
];

export const pendingNotesBreadcrumbs = [
  {
    text: "Dashboard ",
    link: "",
  },
  {
    text: "Pended Notes ",
    link: "pended-notes",
  },
];
export const meetingRoomBreadcrumbs = [
  {
    text: "Dashboard ",
    link: "",
  },
  {
    text: "Meeting Room ",
    link: "meeting-room",
  },
];
export const mediaBreadcrumbs = [
  {
    text: "Medical records",
  },
];

export const conversationsBot = [
  ...conversationLogs,
  {
    text: "conversation.log",
  },
];

export const scheduledAppointmentsBreadcrumbs = [
  ...appointmentsBreadcrumbs,
  {
    text: "general.status.scheduled",
  },
];

export const appointmentsArchiveBreadcrumbs = [
  ...appointmentsBreadcrumbs,
  {
    text: "visits.archive",
  },
];

export const EditAvailabilityBreadcrumbs = [
  ...appointmentsBreadcrumbs,
  {
    text: "practitioner.appointments.editAvailability.title",
  },
];

export const requestVisitBreadcrumbs = [
  {
    text: "general.sidebar.scheduleVisit",
  },
];

export const visitCenterUpcomingBreadcrumbs = [
  ...visitCenterBreadcrumbs,
  {
    text: "visits.upcoming_short",
  },
];

export const visitCenterArchiveBreadcrumbs = [
  ...visitCenterBreadcrumbs,
  {
    text: "visits.archive",
  },
];

export const practitionerScheduleVisitBreadcrumbs = [
  ...appointmentsBreadcrumbs,
  {
    text: "general.sidebar.scheduleVisit",
  },
];
export const addPastVisit = [
  ...quickActionsBreadcrumbs,
  {
    text: "practitioner.addPastVisit",
  },
];

export const ownerScheduleVisitBreadcrumbs = [
  ...appointmentsBreadcrumbs,
  {
    text: "general.sidebar.scheduleVisit",
  },
];

export const registrarScheduleVisitBreadcrumbs = [
  ...appointmentsBreadcrumbs,
  {
    text: "general.sidebar.scheduleVisit",
  },
];

export const profileSettingsBreadcrumbs = [
  {
    text: "settings.profile.title",
  },
];

export const freeTextBreadcrumbs = [
  {
    text: "Free text",
  },
];

export const templateBreadcrumbs = [
  {
    text: "templates.title",
  },
];

export const layoutOwnerNavTabs = (i18n: Record<any, any>) => [
  {
    id: "visitCenter",
    //Add in translate
    title: "Clinic Schedule",
    icon: "sidebar/calendar.svg",
    to: "/owner/appointments",
  },
  {
    id: "organizations",
    //Add in translate
    title: "Organizations",
    icon: "sidebar/calendar.svg",
    to: `/owner/${routesEnum.organizations}`,
  },
  {
    id: "patients",
    title: i18n.t("patients.title"),
    icon: "sidebar/user.svg",
    to: "/owner/patients",
  },
  {
    id: "clinicians",
    title: i18n.t("owner.clinician.title"),
    icon: "sidebar/user.svg",
    to: "/owner/clinicians",
  },
  {
    id: "conversation",
    title: i18n.t("conversation.logs"),
    icon: "chat/chatBot.svg",
    to: "/owner/conversation",
  },
];
export const layoutAdminNavTabs = (i18n: Record<any, any>) => [
  {
    id: "visitCenter",
    //Add in translate
    title: "Clinic Schedule",
    icon: "sidebar/calendar.svg",
    to: "/admin/appointments",
  },
  {
    id: "patients",
    title: i18n.t("patients.title"),
    icon: "sidebar/user.svg",
    to: "/admin/patients",
  },
  {
    id: "clinicians",
    title: i18n.t("owner.clinician.title"),
    icon: "sidebar/user.svg",
    to: "/admin/clinicians",
  },
  {
    id: "visitNoteTemplates",
    title: i18n.t("templates.title"),
    icon: "sidebar/templates.svg",
    to: "/admin/templates",
  },
];

export const layoutAdminQuickActions = (i18n: Record<any, any>) => [
  {
    id: "scheduleVisit",
    title: i18n.t("general.sidebar.scheduleVisit"),
    icon: "sidebar/visit.svg",
    to: "/admin/encounters/schedule",
  },
  {
    id: "addPractitioner",
    title: "Add a Practitioner",
    icon: "sidebar/addPerson.svg",
    to: "/admin/practitioners",
  },
  {
    id: "invitePatient",
    title: i18n.t("general.sidebar.invitePatient"),
    icon: "sidebar/user-plus.svg",
    action: "toggleInviteDialog",
  },
  {
    id: "generateCode",
    title: "Generate code",
    icon: "sidebar/key-plus.svg",
    action: "toggleGenerateCode",
  },
];
export const layoutPatientNavTabs = (i18n: Record<any, any>) => [
  {
    id: "visitCenter",
    title: i18n.t("visits.visitCenter"),
    icon: "sidebar/calendar.svg",
    to: "/patient/visits",
  },
  {
    id: "records",
    title: i18n.t("general.sidebar.medicalRecords"),
    icon: "sidebar/records.svg",
    to: `/patient/records`,
  },
  {
    id: "monitoring",
    title: i18n.t("general.sidebar.monitoring"),
    icon: "sidebar/health.svg",
    to: "/patient/monitoring",
  },
  {
    id: "messages",
    title: i18n.t("general.sidebar.messages"),
    icon: "sidebar/messages.svg",
    to: "/patient/chat",
    action: "toggleChat",
  },
  {
    id: "dependents",
    title: i18n.t("general.sidebar.dependents"),
    icon: "sidebar/dependents.svg",
    to: "/patient/dependents",
  },
  {
    id: "notifications",
    title: "Notifications",
    icon: "sidebar/notification.svg",
    to: "/patient/notifications",
  },
];

export const layoutPatientQuickActions = (i18n: Record<any, any>) => [
  {
    id: "scheduleVisit",
    title: i18n.t("general.sidebar.scheduleVisit"),
    icon: "sidebar/visit.svg",
    to: "/patient/encounters/schedule",
  },
  {
    id: "addHealthData",
    title: i18n.t("general.sidebar.addHealthData"),
    icon: "sidebar/healthData.svg",
    action: "togglePatientAddMonitoringData",
  },
];

export const layoutPractitionerQuickActions = (i18n: Record<any, any>) => [
  {
    id: "scheduleVisit",
    title: i18n.t("general.sidebar.scheduleVisit"),
    icon: "sidebar/visit.svg",
    to: "/practitioner/encounters/schedule",
  },
  {
    id: "pended-notes",
    title: "Pended Notes",
    icon: "sidebar/pend-note.svg",
    to: linkToPractitionerPendedNotes(),
  },

  {
    id: "floatingChat",
    title: "Chat Room",
    icon: "sidebar/sms.svg",
    action: "floatingChat",
  },
  {
    id: "addPastVisit",
    title: i18n.t("practitioner.addPastVisit"),
    icon: "history.svg",
    to: "/practitioner/encounters/pastVisit",
  },
  {
    id: "meeting-room",
    title: "Meeting Room",
    icon: "sidebar/meeting-room.svg",
    action: "openMeetingRooms",
  },

  {
    id: "registerPatient",
    title: "Create a Patient",
    icon: "sidebar/user-plus.svg",
    to: "/practitioner/register-patient",
  },
  {
    id: "invitePatient",
    title: i18n.t("general.sidebar.invitePatient"),
    icon: "sidebar/addEmail.svg",
    action: "toggleInviteDialog",
  },
  {
    id: "addAttachment",
    title: "Send an Attachment",
    icon: "sidebar/plusBox.svg",
    action: "toggleMediaDialog",
  },
  {
    id: "openDosespot",
    title: "DoseSpot",
    icon: "sidebar/pills.svg",
    action: "toggleDoseSpotUI",
  },
];

export const layoutPractitionerNavTabs = (i18n: Record<any, any>) => [
  {
    id: "visitCenter",
    title: i18n.t("practitioner.appointments.title"),
    icon: "sidebar/calendar.svg",
    to: "/practitioner/appointments",
  },

  {
    id: "patients",
    title: i18n.t("patients.title"),
    icon: "sidebar/user.svg",
    to: "/practitioner/patients",
  },
  {
    id: "clinicalRequest",
    title: "Clinical requests",
    icon: "sidebar/calendar.svg",
    to: "/practitioner/clinical-requests",
  },
  {
    id: "messages",
    title: i18n.t("general.sidebar.messages"),
    icon: "sidebar/messages.svg",
    to: "/practitioner/chat",
    action: "toggleChat",
  },
  {
    id: "coordination-of-care",
    title: "Coordination of Care",
    icon: "sidebar/coordination-care.svg",
    action: "toggleCareCoordination",
  },
  {
    id: "voipCall",
    title: "Make a call",
    icon: "sidebar/call.svg",
    action: "voipCall",
  },
];

export const layoutOwnerQuickActions = (i18n: Record<any, any>) => [
  {
    id: "scheduleVisit",
    title: i18n.t("general.sidebar.scheduleVisit"),
    icon: "sidebar/visit.svg",
    to: "/owner/encounters/schedule",
  },
  {
    id: "invitePatient",
    title: i18n.t("general.sidebar.invitePatient"),
    icon: "sidebar/user-plus.svg",
    action: "toggleInviteDialog",
  },
  {
    id: "generateCode",
    title: "Generate code",
    icon: "sidebar/key-plus.svg",
    action: "toggleGenerateCode",
  },
  {
    id: "freeText",
    title: "Free text",
    icon: "sidebar/freeText.svg",
    to: "/owner/free-text",
  },
];
