<template>
  <v-card class="calendar">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <div class="d-flex align-center justify-space-between flex-sm-column flex-md-row flex-column">
      <div
        class="calendar_header_doctors-select col-md-3 col-lg-3 col-xl-3 col-sm-12 col-12 order-sm-1 order-1 order-md-0"
      >
        <v-col class="d-flex pa-0 align-center mr-2" cols="12" sm="12">
          <p class="ma-0 mr-2">Doctors:</p>
          <v-select
            :items="practitionersWithSlotsName"
            :value="this.selectedPractitionerWithSlots"
            class="calendar_header_doctors-select_select"
            dense
            height="32px"
            hide-details="true"
            outlined
            placeholder="All"
            @change="changeSelectedPractitionerData($event)"
          ></v-select>
        </v-col>
      </div>
      <div class="calendar_header col-md-9 col-lg-9 col-xl-9 col-sm-12 col-12">
        <OutlinedButton
          :text="
            calendarValue === 'day' || calendarValue === 'category'
              ? $t('general.status.upcoming')
              : $t('practitioner.appointments.thisWeek')
          "
          class="mx-3 calendar_header_today-btn"
          @onClick="moveToday"
        />
        <div class="calendar_header_buttons">
          <v-btn
            :min-width="30"
            :ripple="false"
            :width="30"
            class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
            outlined
            @click="movePrev"
          >
            <img class="calendar_header_icon__rotate" src="../../assets/icons/arrow.svg" />
          </v-btn>
          <DatePicker
            v-model="currentDate"
            :dense="true"
            :height="34"
            :hideDetails="true"
            :inputValue="datePickerValue"
            :isYearFirst="false"
            className="date-picker__box-shadow calendar_header_date-picker-calendar v-input--hide-details2"
            @setDate="changeCurrentDate"
          />
          <v-btn
            :min-width="30"
            :ripple="false"
            :width="30"
            class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
            depressed
            outlined
            @click="moveNext"
          >
            <img src="../../assets/icons/arrow.svg" />
          </v-btn>
        </div>
        <v-select
          :items="calendarValues"
          :value="calendarValue"
          class="calendar_header_date-picker"
          dense
          hide-details="true"
          outlined
          placeholder="Day"
          @change="changeCalendarValue($event)"
        ></v-select>
      </div>
    </div>
    <v-row class="calendar-content">
      <v-sheet
        :class="selectedPractitionerWithSlots && selectedPractitionerWithSlots !== 'All' ? 'one_doctor' : 'full-width'"
        class="calendar-content_sheet"
        height="700"
      >
        <v-calendar
          ref="calendar"
          :categories="practitionerWithSlotsName"
          :category-hide-dynamic="true"
          :class="calendarValue === 'day' ? 'daily__calendar-day' : ''"
          :event-color="getEventColor"
          :events="events"
          :interval-format="intervalFormat"
          :start="currentDate"
          :type="calendarValue"
          category-show-all
          first-interval="0"
          interval-count="24"
          interval-height="96"
          @click:event="openModalEvent"
          @click:time-category="startTimeCategory"
          @click:time="startTime"
          @click:more="onMoreClick"
          @click:date="startTime"
        >
          <template v-slot:event="{ event }">
            <div
              v-if="selectedPractitionerWithSlots === 'All'"
              :class="{ onGoing: event.onGoing }"
              :style="{ 'border-bottom': `1px solid ${event.uiColor}` }"
              style="height: 100%"
            >
              <span v-if="$vuetify.breakpoint.width < 900" class="pa-0" style="color: var(--primary); font-size: 8px"
                >{{ makeRoundtime(event.start, event.end) }} {{ event.patientName }}</span
              >
              <span v-else class="pa-0" style="color: var(--primary)"
                >{{ makeRoundtime(event.start, event.end) }} {{ event.patientName }}</span
              >
              <span v-if="$vuetify.breakpoint.width < 400" class="pa-1" style="color: var(--primary); font-size: 4px"
                >{{ makeRoundtime(event.start, event.end) }} {{ event.patientName }}</span
              >
            </div>
            <div
              v-else
              :class="{
                pending: event.status === 'pending',
                scheduled: event.status === 'booked',
                onGoing: event.onGoing,
              }"
              class="d-flex align-center single_doctor"
            >
              <span v-if="$vuetify.breakpoint.width < 900" class="pa-0" style="color: var(--primary); font-size: 5px"
                >{{ makeRoundtime(event.start, event.end) }} {{ event.patientName }}</span
              >
              <span v-else class="pa-0" style="color: var(--primary)"
                >{{ makeRoundtime(event.start, event.end) }} {{ event.patientName }}</span
              >
              <span v-if="$vuetify.breakpoint.width < 400" class="pa-1" style="color: var(--primary); font-size: 3px"
                >{{ makeRoundtime(event.start, event.end) }} {{ event.patientName }}</span
              >
            </div>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div :class="{ first: date === week[0].date }" :style="{ top: nowY }" class="v-current-time"></div>
          </template>
          <template v-slot:day-label-header>
            <div></div>
          </template>
          <template
            v-if="selectedPractitionerWithSlots && selectedPractitionerWithSlots !== 'All' && calendarValue === 'day'"
            v-slot:day-label-header
          >
            <p class="my-3 doctors-schedule__title text-left ml-5">
              {{ `${selectedPractitionerWithSlots}'s schedule` }}
            </p>
          </template>
          <template v-else-if="calendarValue !== 'week'" v-slot:day-label-header>
            <div></div>
          </template>
          <template v-slot:category="{ category }">
            <div class="d-flex flex-column align-center">
              <Avatar :userId="category.split(',')[1]" />
              <p class="text-center mt-1 text--secondary">
                {{ category.split(",")[0] }}
              </p>
            </div>
          </template>
        </v-calendar>
      </v-sheet>

      <v-card
        v-if="selectedPractitionerWithSlots && selectedPractitionerWithSlots !== 'All'"
        class="one_doctor_upcoming_info"
      >
        <UpcomingAppointment
          v-if="isShowSlotInformation"
          :appointmentInformation="appointmentInformation"
          @cancel="cancelVisitDialog = true"
          @viewDetails="viewDetails"
        />
        <div v-else>
          <v-card-text class="text--secondary p-0 up_next--divider mb-6">Up next</v-card-text>
          <div class="pa-5 text-center">
            <img alt="No Items" class="mx-auto" src="@/assets/no-items.svg" />
            <p class="heading-4 mt-5">There are no next appointments today</p>
            <p class="text--secondary">Today is empty. Future visits will be displayed here after scheduling.</p>
            <OutlinedButton
              color="primary"
              style="color: var(--primary); opacity: 1; border: 1px solid var(--primary)"
              text="Schedule a New Visit"
              @onClick="openScheduleDialog"
            />
          </div>
        </div>
      </v-card>
    </v-row>
    <ScheduleDialog
      v-if="dialog"
      :closeScheduleDialog="closeScheduleDialog"
      :dialog="dialog"
      :selectedTime="selectedTime"
      @on-close="dialog = false"
    />
    <ModalEdit
      :closeEventModal="closeEventModal"
      :dialog="isOpenSlotModal"
      :loading="editModalLoading"
      :selectedTime="selectedTime"
      :updateCalendarInfo="getScheduledAppointments"
      practitionerId="''"
      role="owner"
    />
    <CancelVisit
      v-if="cancelVisitDialog"
      v-model="cancelVisitDialog"
      :appointmentId="appointmentInformation.id"
      :closeEventModal="closeEventModal"
      :slotId="appointmentInformation.slotId"
      :updateCalendarInfo="getScheduledAppointments"
    />
    <EventMore :date="eventMoreDate" :dialog="eventMoreDialog" :events="moreSlots" @close="eventMoreDialog = false" />
  </v-card>
</template>
<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import EventMore from "@/components/Calendar/modals/More";
import UpcomingAppointment from "@/components/Calendar/modals/UpcomingAppointment";
import CancelVisit from "@/components/CancelVisit";
import DatePicker from "@/components/shared/DatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { calendarMixin } from "@/mixins/calendar.ts";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useOwnersStore } from "@/pinia-store/owners";

import ModalEdit from "../../views/Owner/Appoinments/ModalEdit";
import ScheduleDialog from "../../views/Owner/Appoinments/ScheduleDialog";

export default {
  name: "Calendar",
  mixins: [calendarMixin],
  components: {
    UpcomingAppointment,
    DatePicker,
    OutlinedButton,
    ScheduleDialog,
    ModalEdit,
    Avatar,
    CancelVisit,
    EventMore,
  },
  data: () => ({
    ready: false,
    date: new Date(),
    schedulesList: [],
    loading: false,
    dialog: false,
    isOpenSlotModal: false,
    cancelDialog: false,
    scheduleInDialog: false,
    isReadOnly: true,
    currentDate: new Date(),
    calendarValues: [
      {
        text: "Day",
        value: "day",
      },
      {
        text: "Week",
        value: "week",
      },
      {
        text: "Month",
        value: "month",
      },
    ],
    calendarValue: "category",
    updateTimeInterval: null,
    events: [],
    createStart: null,
    scheduleDialog: false,
    selectedTime: {},
    categories: [],
    openEdit: false,
    editModalLoading: false,
    cancelVisitDialog: false,
    eventMoreDialog: false,
    moreSlots: [],
    eventMoreDate: "",
    isShowSlotInformation: true,
  }),

  computed: {
    ...mapState(useAuthStore, ["sidebarStatus"]),
    ...mapState(useEncountersStore, ["encounters"]),
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(useOwnersStore, [
      "practitionersWithSlots",
      "practitionersWithSlotsName",
      "practitionerWithSlotsName",
      "practitionerOtherSlots",
      "practitionerFreeSlots",
      "selectedPractitionerWithSlots",
      "slotInformation",
    ]),
    ...mapState(useAppointmentsStore, ["appointmentInformation"]),
    isSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    datePickerValue() {
      if (this.calendarValue === "category" || this.calendarValue === "day") {
        const startWeek = DateTime.fromJSDate(this.currentDate).toFormat("LLLL, d");
        return startWeek;
      } else if (this.calendarValue === "month") {
        const month = DateTime.fromJSDate(this.currentDate).toFormat("DD");
        return month;
      } else {
        const startWeek = DateTime.fromJSDate(this.currentDate).startOf("week").toFormat("LLLL, d");
        const endWeek = DateTime.fromJSDate(this.currentDate).endOf("week").toFormat("LLLL, d");
        return `${startWeek} - ${endWeek}`;
      }
    },
  },
  methods: {
    ...mapActions(useCheckoutStore, ["setStringFieldByName"]),
    ...mapActions(useOwnersStore, ["setSelectedPractitioner"]),
    ...mapActions(useEncountersStore, ["getAllEncounters"]),
    ...mapActions(useOwnersStore, [
      "changeSelectedPractitioner",
      "getAllPractitioners",
      "chosePractitioner",
      "getSlotInformation",
      "cleanSlotInformation",
    ]),
    ...mapActions(useCheckoutStore, ["setStringFieldByName", "setVisitDate"]),
    ...mapActions(useAppointmentsStore, ["getAppointmentInformation", "cleanAppointmentInformation"]),
    ...mapActions(useAppointmentsStore, ["setAppointmentInformation"]),
    openScheduleDialog() {
      this.dialog = true;
    },
    intervalFormat(interval) {
      return interval.time;
    },
    async changeSelectedPractitionerData(selected) {
      this.changeSelectedPractitioner(selected);
      this.cleanSlotInformation();
      if (selected !== "All") {
        this.calendarValue = "day";
        let sortedSlots = [];
        const selectedPractitioner = this.practitionerOtherSlots.filter((slot) => slot.name === selected);
        if (selectedPractitioner.length) {
          this.isShowSlotInformation = true;
          sortedSlots = selectedPractitioner.sort((a, b) => a.star - b.start);
          await this.getSlotInformation(sortedSlots[0].id);
        } else {
          this.isShowSlotInformation = false;
          return;
        }
      } else {
        this.calendarValue = "category";
      }
    },
    makeRoundtime(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return `${startDate.getHours()}:${
        startDate.getMinutes() < 10 ? "0" + startDate.getMinutes() : startDate.getMinutes()
      } - ${endDate.getHours()}:${endDate.getMinutes() < 10 ? "0" + endDate.getMinutes() : endDate.getMinutes()}`;
    },
    async openModalEvent({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      if (this.calendarValue !== "day") {
        this.isOpenSlotModal = true;
        this.editModalLoading = true;
      } else {
        this.cleanAppointmentInformation();
      }

      await this.getAppointmentInformation(event.id);
      this.editModalLoading = false;
    },
    closeEventModal() {
      this.isOpenSlotModal = false;
      this.cleanSlotInformation();
    },
    closeScheduleDialog() {
      this.dialog = false;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;
      return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    },
    startTime(tms) {
      if (tms.future === true) {
        if (this.calendarValue === "category") {
          return null;
        } else {
          const mouse = this.toTime(tms);
          this.createStart = this.roundTime(mouse);
          const time = this.makeRoundtime(this.createStart, this.createStart + 900000);
          const slot = this.practitionerFreeSlots.find((slot) => slot.time === time);
          if (slot) {
            let value = slot.id;
            this.setStringFieldByName({ value, fieldName: "slotId" });
          } else {
            let value = "";
            this.setStringFieldByName({ value, fieldName: "slotId" });
          }
          this.createEvent = {
            color: "blue",
            timed: true,
            date: tms.date,
          };
          this.setVisitDate(tms.date);
          if (this.selectedPractitionerWithSlots === "All") {
            this.selectedTime = this.createEvent;
          } else {
            const selectedPractitioner = this.practitionersWithSlots.find((practitioner) => {
              return `${practitioner.firstName} ${practitioner.lastName}` === this.selectedPractitionerWithSlots;
            });
            const newCotegory = {
              text: `${selectedPractitioner.firstName} ${selectedPractitioner.lastName}`,
              value: selectedPractitioner.id,
            };
            this.createEvent.category = newCotegory;
            this.selectedTime = this.createEvent;
          }

          this.openScheduleDialog();
        }
      } else {
        return;
      }
    },
    startTimeCategory(tms) {
      if (tms.future === true) {
        const mouse = this.toTime(tms);
        this.createStart = this.roundTime(mouse);
        if (tms.category) {
          const time = this.makeRoundtime(this.createStart, this.createStart + 900000);
          this.chosePractitioner(tms.category.split(",")[1]);
          const slot = this.practitionerFreeSlots.find((slot) => slot.time === time);
          if (slot) {
            let value = slot.id;
            this.setStringFieldByName({ value, fieldName: "slotId" });
          } else {
            let value = "";
            this.setStringFieldByName({ value, fieldName: "slotId" });
          }
          let newCategory = {
            text: tms.category.split(",")[0],
            value: tms.category.split(",")[1],
          };

          this.createEvent = {
            name: tms.category.split(",")[0],
            color: "blue",
            start: this.createStart,
            end: this.createStart + 900000,
            timed: true,
            category: newCategory,
            date: tms.date,
          };
          this.setVisitDate(tms.date);
          this.openScheduleDialog();
          this.selectedTime = this.createEvent;
        }
      } else return;
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      if (this.cal) {
        this.cal.scrollToTime(first);
      }
    },
    updateTime() {
      this.updateTimeInterval = setInterval(() => {
        if (this.cal) this.cal.updateTimes();
      }, 60 * 1000);
    },
    toggleCancelDialog() {
      this.dialog = !this.dialog;
    },
    onCancelVisit() {
      this.dialog = false;
      this.cancelDialog = true;
    },
    onSuccessCancel() {
      const { id } = this.scheduleInDialog;
      this.schedulesList = this.schedulesList.filter((sl) => sl.id !== id);
    },
    changeView(view) {
      this.view = view;
    },
    movePrev() {
      if (this.calendarValue === "category" || this.calendarValue === "day") {
        const newDate = moment(this.currentDate).subtract(1, "days");
        const newCurrentDate = new Date(newDate);
        this.currentDate = newCurrentDate;
      }
      if (this.calendarValue === "week") {
        const newDate = moment(this.currentDate).subtract(7, "days");
        const newCurrentDate = new Date(newDate);
        this.currentDate = newCurrentDate;
      }
      if (this.calendarValue === "month") {
        const newDate = moment(this.currentDate).subtract(1, "M");
        const newCurrentDate = new Date(newDate);
        this.currentDate = newCurrentDate;
      }
    },
    moveNext() {
      if (this.calendarValue === "category" || this.calendarValue === "day") {
        const newDate = moment(this.currentDate).add(1, "days");
        const newCurrentDate = new Date(newDate);
        this.currentDate = newCurrentDate;
      }
      if (this.calendarValue === "week") {
        const newDate = moment(this.currentDate).add(7, "days");
        const newCurrentDate = new Date(newDate);
        this.currentDate = newCurrentDate;
      }
      if (this.calendarValue === "month") {
        const newDate = moment(this.currentDate).add(1, "M");
        const newCurrentDate = new Date(newDate);
        this.currentDate = newCurrentDate;
      }
    },
    moveToday() {
      this.currentDate = new Date();
    },
    changeCurrentDate(newDate) {
      this.currentDate = newDate;
    },
    changeCalendarValue(e) {
      if (e === "day" && this.selectedPractitionerWithSlots === "All") {
        this.calendarValue = "category";
      } else {
        this.calendarValue = e;
      }
    },
    onBeforeUpdateSchedule(e) {
      const {
        schedule: { id },
        changes: { end, start },
      } = e;
      this.scheduleList = this.scheduleList.map((s) =>
        s.id === id
          ? {
              ...s,
              ...(start ? { start } : {}),
              ...(end ? { end } : {}),
            }
          : s,
      );
    },
    onClickSchedule(e) {
      const { schedule } = e;
      if (!schedule.raw.available) return null;
      this.openScheduleDialog();
      this.scheduleInDialog = schedule;
    },
    formatDate(date = undefined) {
      return moment(date);
    },
    updateSelectedPractitioner() {
      const newSelectedPractitioner = localStorage.getItem("selectedPractitioner");
      if (newSelectedPractitioner) {
        this.changeSelectedPractitioner(newSelectedPractitioner);

        if (newSelectedPractitioner !== "All") {
          this.calendarValue = "day";
        } else {
          this.calendarValue = "category";
        }
      }
      const newChosenPractitioner = localStorage.getItem("chosenPractitioner");
      if (newChosenPractitioner) {
        this.chosePractitioner(newChosenPractitioner);
      }
    },
    onMoreClick(event) {
      const moreArr = this.practitionerOtherSlots.filter((slot) => {
        const eventTime = moment(event.date).format("YYYY-MM-DD");
        const slotTime = moment(slot.start).format("YYYY-MM-DD");
        return eventTime === slotTime;
      });
      this.moreSlots = moreArr;
      this.eventMoreDialog = true;
      this.eventMoreDate = event.date;
    },
    viewDetails() {
      if (this.appointmentInformation && this.appointmentInformation.status === "booked") {
        this.$router.push(`/owner/encounters/${this.appointmentInformation.encounterId}`);
      } else {
        this.$router.push(`/owner/pending/${this.appointmentInformation.slotId}`);
      }
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
    async getScheduledAppointments() {
      await this.getAllPractitioners();

      const colors = ["#9B51E0", "#F2994A", "#2AB561", "#E84393", "#5F27CD", "#E17055", "#F2C94C", "#6FCF97"];

      this.practitionersWithSlots.map((practitioner) => {
        const category = [`${practitioner.firstName} ${practitioner.lastName}`, practitioner.id];
        practitioner.appointments.map((appointment) => {
          this.events.push({
            category: category.toString(),
            color: "rgba(242, 153, 74, 0.1)",
            end: moment(appointment.end).valueOf(),
            id: appointment.id,
            name: category[0],
            start: moment(appointment.start).valueOf(),
            status: appointment.status,
            timed: true,
            uiColor: colors[Math.floor(Math.random() * colors.length)],
            patientName: appointment.participant[1].actor.display,
          });
        });
      });

      if (moment(this.currentDate.toISOString()).format("DD/MM") === moment().format("DD/MM")) {
        this.events.forEach((ev) => {
          if (ev.start / 1000 <= moment().unix() && ev.end / 1000 >= moment().unix()) {
            ev.onGoing = true;
            ev.color = "#1E85D9";
          }
        });
      }
    },
  },
  watch: {
    isSmAndDown() {
      if (this.isSmAndDown) {
        this.setSelectedPractitioner(
          `${this.practitionersWithSlots[0].firstName} ${this.practitionersWithSlots[0].lastName}`,
        );
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.updateTimeInterval);
  },
  async mounted() {
    this.ready = true;
    this.loading = true;
    await this.getScheduledAppointments();
    this.scrollToTime();
    this.updateTime();
    this.updateSelectedPractitioner();
    if (this.isSmAndDown) {
      this.setSelectedPractitioner(
        `${this.practitionersWithSlots[0].firstName} ${this.practitionersWithSlots[0].lastName}`,
      );
    }
    this.$refs.calendar && this.$refs.calendar.checkChange();
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.v-future .v-current-time {
  display: none;
}

.v-past .v-current-time {
  background: repeating-linear-gradient(to right, #ea4335, #ea4335 8px, transparent 8px, transparent 15px);
}

.v-present .v-current-time::before {
  content: "";
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

.v-present .v-calendar-category__column:not(:first-child) .v-current-time::before {
  display: none;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.onGoing {
  span,
  p {
    color: #fff !important;
  }

  .dot {
    background: #fff !important;
  }
}

.one_doctor_upcoming_info {
  width: 40%;
}

.one_doctor {
  width: 60%;
}

.up_next--divider {
  border-bottom: 2px solid #33a3ff;
}

.single_doctor.scheduled {
  background: rgba(30, 133, 217, 0.1);
  border-left: 2px solid var(--primary);
}

.single_doctor.pending {
  background: #f6f6f6;
  border-left: 2px solid #f2994a;
}

.single_doctor.pending span,
.single_doctor.pending .status {
  color: #f2994a;
}

.single_doctor.pending .status .dot {
  width: 4px;
  height: 4px;
  background: #f2994a;
  border-radius: 50%;
  margin-right: 6px;
}

.single_doctor .text-dark {
  color: #33343e;
}

.single_doctor.scheduled span,
.single_doctor.scheduled .status {
  color: var(--primary);
}

.single_doctor.scheduled .status .dot {
  width: 4px;
  height: 4px;
  background: #2196f3;
  border-radius: 50%;
  margin-right: 6px;
}

.single_doctor.scheduled > span {
  border-right: 2px solid #ffffff;
}

.v-input__slot {
  margin-bottom: 0 !important;
}

.event__more__dialog {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  max-height: 235px;
  overflow-y: auto;

  .dialog__head__close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.more__list__item {
  &:hover {
    transition: 0.3s ease;
    background: #f6f6f6;
  }

  .dot {
    width: 8px;
    height: 8px;
    background: red;
  }
}

.calendar_header_buttons {
  justify-content: start;
}
</style>
<style lang="scss">
.v-input--hide-details2 {
  max-width: 250px !important;
}

.v-input--hide-details2 .v-input__control .v-input__slot {
  margin-bottom: 0 !important;
}

.v-calendar .v-event-timed {
  overflow: hidden !important;
}
</style>
