<template>
  <div>
    <v-col class="px-0">
      <div class="mb-2">
        <v-col class="d-flex align-center gap-1">
          <Select
            :hide-details="true"
            :items="scheduleItems"
            :labelActive="true"
            :value="form.scheduleId"
            fieldName="repeatType"
            item-text="name"
            item-value="value"
            label="Schedule"
            @change="(value) => (form.scheduleId = value)"
          />
        </v-col>
        <v-col class="d-flex align-center gap-1">
          <Select
            :hide-details="true"
            :items="appointmentTypeValues"
            :labelActive="true"
            :value="form.appointmentTypeId"
            fieldName="repeatType"
            item-text="name"
            item-value="value"
            label="Appointment type"
            @change="(value) => (form.appointmentTypeId = value)"
          />
        </v-col>
        <v-col class="d-flex align-center gap-1">
          <DatePicker
            :dense="true"
            :height="34"
            :hideDetails="true"
            :isYearFirst="false"
            :labelActive="true"
            :max-date="schedule.end"
            :min-date="schedule.start"
            :value="form.date"
            placeholder="Date"
            @setDate="(date) => (form.date = date)"
          />
        </v-col>
        <div class="pol-container">
          <div :style="dynamicStyles" class="pol-border">
            <v-icon class="mr-2" color="primary" size="24px">mdi-clock</v-icon>
            <TimePicker
              :hide-icon="true"
              :labelActive="true"
              :value="form.start"
              class="time-picker"
              placeholder="Start Time"
              @change="(time) => (form.start = time)"
            />
          </div>
          <TimePicker
            :hide-icon="true"
            :labelActive="true"
            :value="form.end"
            class="time-picker"
            placeholder="End Time"
            @change="(time) => (form.end = time)"
          />
        </div>
        <div class="px-4">
          <v-switch
            :input-value="form.repeat"
            color="primary"
            label="Repeat"
            @change="(value) => (form.repeat = value)"
          />
          <div v-if="form.repeat" class="d-flex justify-between gap-1 align-center">
            Repeat every:
            <BaseInput
              v-model="form.repeatInterval"
              class="w-100"
              type="number"
              @change="(value) => (form.repeatInterval = value)"
            />
            <Select
              :hide-details="true"
              :items="repeatTypeValues"
              :label-active="true"
              :labelActive="true"
              :value="form.repeatType"
              class="w-130"
              fieldName="repeatType"
              item-text="name"
              item-value="value"
              label="Repeat type"
              @change="(value) => (form.repeatType = value)"
            />
          </div>
          <div
            v-if="form.repeatType === SlotRepeatTypeEnum.weekly && form.repeat"
            class="d-flex justify-between gap-1 align-center weekdays-chip-group"
          >
            <v-chip-group class="weekdays-chip-group">
              <v-chip
                v-for="index in weekDays"
                :key="index"
                :color="isActiveDay(index) ? 'primary' : ''"
                @click="onRepeatDayClick(index)"
              >
                {{ getWeekDay(index) }}
              </v-chip>
            </v-chip-group>
          </div>
        </div>
        <v-divider class="mt-3" />
      </div>
    </v-col>
  </div>
</template>
<script>
import { upperFirst } from "lodash-es";
import moment from "moment-timezone";
import { mapActions, mapState, mapWritableState } from "pinia";

import { ScheduleApi } from "@/api/schedule";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import DatePicker from "@/components/shared/DatePicker.vue";
import TimePicker from "@/components/shared/TimePicker.vue";
import BaseInput from "@/components/uikit/BaseInput.vue";
import Select from "@/components/uikit/Select.vue";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { SlotRepeatTypeEnum, useScheduleStore } from "@/pinia-store/schedule";
import { SlotAppointmentTypeEnum, useSlotsStore } from "@/pinia-store/slots";
import { weekDays } from "@/utils/getWeek";

export default {
  name: "SlotEditForm",
  data() {
    return {
      isLoading: false,
      backgroundArrowImageUrl: require("@/assets/right-arrow-border.svg"),

      repeatTypeValues: Object.values(SlotRepeatTypeEnum).map((v) => ({ value: v, name: upperFirst(v) })),
    };
  },
  components: { TimePicker, BaseInput, DatePicker, Select },
  computed: {
    ...mapState(useScheduleStore, ["schedules"]),
    ...mapState(useHealthcareServiceStore, ["healthcareServices"]),
    ...mapState(useSlotsStore, ["isSaving"]),
    ...mapWritableState(useSlotsStore, ["form"]),
    schedule() {
      let sched = this.schedules.find((item) => item.id === this.form.scheduleId);
      console.log(sched);
      if (sched)
        return {
          start: sched.planningHorizon.start,
          end: sched.planningHorizon.end,
        };
      return { start: "", end: "" };
    },
    SlotRepeatTypeEnum() {
      return SlotRepeatTypeEnum;
    },
    weekDays() {
      return weekDays;
    },
    dynamicStyles() {
      return {
        backgroundImage: `url(${this.backgroundArrowImageUrl})`,
      };
    },
    scheduleItems() {
      return this.schedules.map((item) => ({
        ...item,
        value: item.id,
        name: `${this.$moment(item.planningHorizon.start).format(timeDisplayFormat)} - ${this.$moment(
          item.planningHorizon.end,
        ).format(timeDisplayFormat)} ${item.comment}`,
      }));
    },
    appointmentTypeValues() {
      return Object.keys(SlotAppointmentTypeEnum).map((v) => ({ value: v, name: upperFirst(v) }));
    },
  },
  methods: {
    getWeekDay(number) {
      return moment().day(number).format("dd")[0];
    },
    isActiveDay(index) {
      return this.form.days.includes(moment().day(index).format("dddd").toLocaleLowerCase());
    },
    onRepeatDayClick(index) {
      const dayFormat = moment().day(index).format("dddd").toLocaleLowerCase();
      console.log(moment().day(index).format("dddd"));
      if (this.form.days.includes(dayFormat)) this.form.days = this.form.days.filter((i) => i !== dayFormat);
      else this.form.days.push(dayFormat);
    },
    ...mapActions(useHealthcareServiceStore, ["getHealthcareServices"]),
    ...mapActions(useScheduleStore, [
      "changeFormField",
      "changeFormSlotField",
      "saveForm",
      "setForm",
      "appendFormSlot",
      "removeFormSlot",
      "changeSlotRepeat",
    ]),
  },

  async mounted() {
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await ScheduleApi.find(this.$route.params.id);
      console.log(item);
      this.isLoading = false;
    }

    await this.getHealthcareServices(this.$route.params.organizationId);
    console.log("Schedule mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}

:deep {
  .time-picker {
    min-width: 120px;

    .base-input .v-input__control .v-input__slot fieldset {
      border: none !important;
    }

    .v-input__slot {
      border: none !important;
      cursor: pointer !important;
    }
  }

  .w-130 {
    width: 130px;

    .v-input__control {
      width: inherit;
    }
  }

  .w-100 {
    width: 100px;

    .v-input__control {
      width: inherit;
    }
  }
}

.pol-container {
  border-bottom: 1px solid #eeeff7;
  border-top: 1px solid #eeeff7;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 12px;
}

.pol-border {
  // clip-path: polygon(80% 0, 0 0, 0 100%, 80% 100%, 100% 50%);
  display: flex;
  background-position: right center;
  border: none;
  padding: 10px 80px 10px 10px;
  background-size: auto 100%;
}
</style>
