import { ObservationEnum } from "@/types/ObservationEnum";

export const linkToPractitionerMonitoringListKindOrders = (patientId: string, kind: ObservationEnum) => {
  return `/practitioner/orders/patient/${patientId}/monitoring/${kind}`;
};
export const linkToPractitionerPendedNotes = (): string => `/practitioner/pended-notes`;
export const linkToPractitionerFax = (): string => `/practitioner/fax`;
export const linkToPractitionerMeetingRoom = (): string => `/practitioner/meeting-room`;
export const linkToPractitionerPendedNotesPending = (): string => `/practitioner/pended-notes/pending`;
export const linkToOrganizationView = (id: string): string => `/owner/organizations/view/${id}/`;
export const linkToOrganizationViewHealthcareServices = (id: string): string =>
  `/owner/organizations/view/${id}/healthcare-service`;

export const linkToOrganizationViewSchedule = (id: string): string => `/owner/organizations/view/${id}/schedule`;
export const linkToOrganizationViewPractitioners = (id: string): string =>
  `/owner/organizations/view/${id}/practitioner-roles`;
