<template>
  <div>
    <div class="container-filters">
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mr-2 filter-name">Patients:</p>
        <v-select
          :disabled="isLoading || loading"
          :items="patientsOptions"
          :value="upcomingTabFilter.patientId"
          class="v-input--hide-details--custom bg-white"
          dense
          height="32px"
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeSelectedPatient($event)"
        ></v-select>
      </div>
      <div class="d-flex pa-0 align-center mt-3">
        <p class="ma-0 mx-2 filter-name">Date Range:</p>
        <RangeDatePicker
          v-model="upcomingTabFilter.currentDate"
          :clearRangeDatePicker="false"
          :dense="true"
          :height="34"
          :hide-title="true"
          :hideDetails="true"
          :isYearFirst="false"
          className="date-picker__box-shadow v-input--hide-details--custom"
          @setDate="changeCurrentDate"
        />
      </div>
    </div>
    <div>
      <v-progress-linear v-if="loading || isLoading" color="primary" indeterminate />
      <div v-if="!upcomingItems.length" class="ap-list_no-items">
        <img alt="No Items" src="@/assets/no-items.svg" />
        <p class="heading-4">There is no appointments to display here</p>
        <p class="sub-heading-3">Book a visit with a doctor first.</p>
        <OutlinedButton
          color="primary"
          style="color: var(--primary); opacity: 1; border: 1px solid var(--primary)"
          text="Schedule a New Visit"
          @onClick="$emit('toSchedule')"
        />
      </div>
      <div class="ap-list">
        <div v-for="(item, key) in upcomingItems" :key="key" class="ap-list">
          <h1 class="ap-list_wrapTitle">{{ item.date }}</h1>
          <AppointmentsListItem
            v-for="encounter in item.items"
            :key="encounter.id"
            :item="encounter"
            place="upcoming"
            role="practitioner"
            @onButtonClick="joinRoom"
            @onCancel="onCancel"
          />
        </div>
      </div>
      <CancelVisit v-model="dialog" :appointmentId="cancelledId" @success="getPractitionerUpcomingEncounters" />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import AppointmentsListItem from "@/components/AppointmentsListItem/index.vue";
import CancelVisit from "@/components/CancelVisit";
import RangeDatePicker from "@/components/shared/RangeDatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { upcomingPractitionerListMixin } from "@/mixins/upcomingPractitionerList";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { usePatientsStore } from "@/pinia-store/patients";

export default {
  name: "Upcoming",
  props: ["isLoading", "getListScheduledEncounters", "isSwitchTab"],
  data: () => ({
    practitionerId: null,
    patientId: null,
    menu: false,
    clearRangeDatePicker: false,
  }),
  components: {
    AppointmentsListItem,
    CancelVisit,
    RangeDatePicker,
    OutlinedButton,
  },
  mixins: [upcomingPractitionerListMixin],
  computed: {
    ...mapState(useEncountersStore, ["upcomingTabFilter"]),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useAuthStore, ["timeZone"]),

    patientsOptions() {
      const patients = this.patients.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
      patients.unshift({
        text: "All",
        value: null,
      });
      return patients;
    },
  },
  methods: {
    ...mapActions(useEncountersStore, ["setUpcomingTabFilter"]),
    ...mapActions(useEncountersStore, ["getPractitionerUpcomingEncounters"]),
    async changeSelectedPatient(selected) {
      this.loading = true;

      this.setUpcomingTabFilter({ ...this.upcomingTabFilter, patientId: selected });
      await this.getPractitionerUpcomingEncounters();
      this.loading = false;
    },
    async changeCurrentDate(newDate) {
      this.loading = true;
      try {
        this.setUpcomingTabFilter({
          ...this.upcomingTabFilter,
          currentDate: newDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf()),
        });
        await this.getPractitionerUpcomingEncounters();
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getPractitionerUpcomingEncounters();
  },
};
</script>

<style lang="scss" scoped>
.container-filters {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 320px));
  padding-bottom: 8px;

  .filter-name {
    min-width: fit-content;
  }
}
</style>
