import { defineStore } from "pinia";

import { MedicationApi } from "@/api/medication";
import { TerminologyApi } from "@/api/terminology";
import { useAuthStore } from "@/pinia-store/auth";
import { Coding } from "@/types/FHIR";

interface DispenseUnit {
  name: string;
  id: number;
}

interface PharmacySpecialties {
  name: string;
  id: number;
}

interface SmokingBehavior {
  name: string;
  id: number;
  term?: string;
  code?: number;
}

interface TerminologyState {
  smokingBehavior: SmokingBehavior[];
  smokingBehaviorLoaded: boolean;

  dispenseUnits: DispenseUnit[];
  dispenseUnitsLoaded: boolean;
  pharmacySpecialties: PharmacySpecialties[];
  pharmacySpecialtiesLoaded: boolean;
  practitionerFavMedication: Record<any, any>[];
  practitionerFavMedicationLoaded: boolean;
  serviceCategories: Coding[];
  serviceTypes: Coding[];
  practiceCodes: Coding[];
  practitionerRoles: Coding[];
  serviceTypeLoaded: boolean;
  serviceCategoriesLoaded: boolean;
  practiceCodesLoaded: boolean;
  practitionerRolesLoaded: boolean;
}

// interface TerminologyContext {
//   state: TerminologyState;
//   rootState: any;
//   rootGetters: any;
//   commit: any;
// }

export const useTerminologyStore = defineStore({
  id: "terminology",
  state: (): TerminologyState => ({
    smokingBehavior: [],
    dispenseUnits: [],
    serviceCategories: [],
    serviceTypes: [],
    practiceCodes: [],
    practitionerRoles: [],
    smokingBehaviorLoaded: false,
    dispenseUnitsLoaded: false,
    pharmacySpecialties: [],
    pharmacySpecialtiesLoaded: false,
    practitionerFavMedication: [],
    practitionerFavMedicationLoaded: false,
    serviceTypeLoaded: false,
    serviceCategoriesLoaded: false,
    practiceCodesLoaded: false,
    practitionerRolesLoaded: false,
  }),
  actions: {
    async preloadServiceTypes() {
      if (this.serviceTypeLoaded) return;
      this.serviceTypes = await TerminologyApi.getServiceType();
      this.serviceTypeLoaded = true;
    },
    async preloadPractitionerRoles() {
      if (this.practitionerRolesLoaded) return;
      this.practitionerRoles = await TerminologyApi.getPractitionerRoles();
      this.practitionerRolesLoaded = true;
    },
    async preloadServiceCategories() {
      if (this.serviceCategoriesLoaded) return;
      this.serviceCategories = await TerminologyApi.getServiceCategory();
      this.serviceCategoriesLoaded = true;
    },
    async preloadPracticeCodes() {
      if (this.practiceCodesLoaded) return;
      this.practiceCodes = await TerminologyApi.getPracticeCodes();
      this.practiceCodesLoaded = true;
    },
    async getDispenseUnits() {
      this.dispenseUnits = await TerminologyApi.getAllergies();
      this.dispenseUnitsLoaded = true;
    },
    async preloadDispenseUnits() {
      if (this.dispenseUnitsLoaded) return;
      this.dispenseUnits = await TerminologyApi.getAllergies();
      this.dispenseUnitsLoaded = true;
    },
    async preloadPharmacySpecialties() {
      if (this.pharmacySpecialtiesLoaded) return;
      this.pharmacySpecialties = await TerminologyApi.getPharmacySpecialties();
      this.pharmacySpecialtiesLoaded = true;
    },
    async preloadSmokingBehavior() {
      if (this.smokingBehaviorLoaded) return;
      this.smokingBehavior = await TerminologyApi.getSmokingBehavior();
      this.smokingBehaviorLoaded = true;
    },
    async getPractitionerFavMedication(payload?: string) {
      const authStore = useAuthStore();
      try {
        this.practitionerFavMedication = await MedicationApi.getPractitionerMedication(authStore.uid || payload);
        this.practitionerFavMedicationLoaded = true;
      } catch (e) {
        console.info("Practitioner Fav Medication not found");
      }
    },
    async preloadPractitionerFavMedication() {
      if (this.practitionerFavMedicationLoaded) return;
      this.getPractitionerFavMedication();
    },
  },
});
