<template>
  <div class="schedule-form">
    <v-row class="mb-3">
      <v-col cols="6">
        <DatePicker
          :dense="true"
          :height="34"
          :hideDetails="true"
          :isYearFirst="false"
          :labelActive="true"
          :value="form.planningHorizon.start"
          placeholder="Start date"
          @setDate="changeStartDate"
        />
      </v-col>
      <v-col cols="6">
        <DatePicker
          :dense="true"
          :height="34"
          :hideDetails="true"
          :isYearFirst="false"
          :labelActive="true"
          :value="form.planningHorizon.end"
          placeholder="End date"
          @setDate="changeEndDate"
        />
      </v-col>
    </v-row>
    <template v-if="userIsPractitioner">
      <div v-for="item in practitionerRoles" :key="item.id">
        <v-list-item class="px-0 py-0 organization-row">
          <v-list-item-avatar>
            <Avatar :src="item.organization?.logoUrl" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item?.organization?.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="service in item.healthcareService" :key="service.id">
          <v-checkbox
            :false-value="false"
            :true-value="true"
            :value="form.actorHealthcareServiceIds.includes(service.id)"
            @change="triggerChangeHealthcareService(service.id)"
          >
            <template v-slot:label>
              <v-list-item-content>
                <v-list-item-title>{{ service.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-checkbox>
        </v-list-item>
      </div>
    </template>
    <Select
      v-else
      :hide-details="true"
      :items="healthcareServices"
      :label-active="true"
      :labelActive="true"
      :multiple="true"
      :value="form.actorHealthcareServiceIds"
      class="mb17"
      fieldName="actorHealthcareServiceIds"
      item-text="name"
      item-value="id"
      label="For healthcare services"
      @change="changeFormField"
    />
    <BaseInput
      :label-active="true"
      :value="form.comment"
      fieldName="comment"
      placeholder="Notes"
      @change="changeFormField"
    />
  </div>
</template>
<script>
import { upperFirst } from "lodash-es";
import { mapActions, mapState, mapWritableState } from "pinia";

import { ScheduleApi } from "@/api/schedule";
import Avatar from "@/components/Avatar/Index.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import BaseInput from "@/components/uikit/BaseInput.vue";
import Select from "@/components/uikit/Select.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { usePractitionerRoleStore } from "@/pinia-store/practitionerRole";
import { SlotRepeatTypeEnum, useScheduleStore } from "@/pinia-store/schedule";

export default {
  name: "ScheduleEditForm",
  data() {
    return {
      isLoading: false,
      backgroundArrowImageUrl: require("@/assets/right-arrow-border.svg"),
      repeatTypeValues: Object.values(SlotRepeatTypeEnum).map((v) => ({ value: v, name: upperFirst(v) })),
    };
  },
  components: { Avatar, BaseInput, DatePicker, Select },
  computed: {
    SlotRepeatTypeEnum() {
      return SlotRepeatTypeEnum;
    },
    dynamicStyles() {
      return {
        backgroundImage: `url(${this.backgroundArrowImageUrl})`,
      };
    },
    ...mapState(useAuthStore, ["userIsPractitioner"]),
    ...mapState(useHealthcareServiceStore, ["healthcareServices"]),
    ...mapState(useScheduleStore, ["isSaving"]),
    ...mapState(usePractitionerRoleStore, ["practitionerRoles"]),
    ...mapWritableState(useScheduleStore, ["form"]),
    slots() {
      return this.form.slots || [];
    },
  },
  methods: {
    ...mapActions(useHealthcareServiceStore, ["getHealthcareServices"]),
    ...mapActions(useScheduleStore, [
      "changeFormField",
      "changeFormSlotField",
      "saveForm",
      "setForm",
      "appendFormSlot",
      "removeFormSlot",
      "changeSlotRepeat",
    ]),
    triggerChangeHealthcareService(id) {
      if (this.form.actorHealthcareServiceIds.includes(id)) {
        this.form.actorHealthcareServiceIds = this.form.actorHealthcareServiceIds.filter((v) => v !== id);
      } else this.form.actorHealthcareServiceIds.push(id);
    },
    changeStartDate(date) {
      this.setForm({ ...this.form, planningHorizon: { ...(this.form.planningHorizon || {}), start: date } });
    },
    changeEndDate(date) {
      console.log(date);
      this.setForm({ ...this.form, planningHorizon: { ...(this.form.planningHorizon || {}), end: date } });
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await ScheduleApi.find(this.$route.params.id);
      this.setForm(item);
      this.isLoading = false;
    }

    await this.getHealthcareServices(this.$route.params.organizationId);
    console.log("Schedule mounted");
  },
};
</script>

<style lang="scss" scoped>
.schedule-form {
  .organization-row {
    border-bottom: 1px solid #eeeff7;
  }

  :deep .v-input--checkbox {
    margin-top: 0;
    padding-top: 0;
  }

  :deep .v-input__control {
    .v-messages {
      display: none;
    }
  }
}

:deep {
  .time-picker {
    min-width: 120px;

    .base-input .v-input__control .v-input__slot fieldset {
      border: none !important;
    }

    .v-input__slot {
      border: none !important;
      cursor: pointer !important;
    }
  }

  .w-130 {
    width: 130px;

    .v-input__control {
      width: inherit;
    }
  }

  .w-100 {
    width: 100px;

    .v-input__control {
      width: inherit;
    }
  }
}

.pol-container {
  border-bottom: 1px solid #eeeff7;
  border-top: 1px solid #eeeff7;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 12px;
}

.pol-border {
  // clip-path: polygon(80% 0, 0 0, 0 100%, 80% 100%, 100% 50%);
  display: flex;
  background-position: right center;
  border: none;
  padding: 10px 80px 10px 10px;
  background-size: auto 100%;
}
</style>
