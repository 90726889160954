<template>
  <div v-if="encounters.length" class="medical-record-encounters">
    <v-row>
      <v-col :lg="12" :md="12" cols="12" sm="12">
        <EncountersSlide :active-id="selectedEncounterId" :items="encountersList" @onSelect="onEncounterChange" />
      </v-col>
      <v-col :lg="12" :md="12" class="justify-end d-flex" cols="12" sm="12">
        <Pagination
          :hide-showing="true"
          :page="selectedEncounterIndex + 1"
          :total="encountersList.length || 1"
          @onFirst="onFirstEncounter"
          @onNext="onNextEncounter"
          @onPrev="onPrevEncounter"
        />
      </v-col>
      <v-col :lg="12" :md="12" cols="12" sm="12">
        <v-row>
          <v-col :class="$vuetify.breakpoint.lgAndUp && 'pr-0 '" cols="12">
            <v-skeleton-loader
              v-if="loading && selectedEncounterId"
              class="full-width skeleton-loader"
              type="list-item, actions, divider, article, divider, divider, article, divider, article, list-item-avatar"
            ></v-skeleton-loader>
            <p v-if="!encountersList.length" class="heading-4">Nothing to show</p>
            <SignedVisitNote v-if="!loading && selectedEncounterId && !userIsPatient" :item="item" />
            <SignedVisitNotePatient v-if="!loading && selectedEncounterId && userIsPatient" :item="item" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import EncountersSlide from "@/components/MedicalRecord/EncountersSlide/EncountersSlide";
import Pagination from "@/components/Pagination/View";
import { ADDENDUM_NOTE_ADDED } from "@/eventBuses";
import { useAuthStore } from "@/pinia-store/auth";
import { useEncountersStore } from "@/pinia-store/encounters";
import { initialVisitDescription } from "@/pinia-store/interfaces/initialState";
import { useMediaStore } from "@/pinia-store/media";
import { useMedicalRecordStore } from "@/pinia-store/medicalRecord";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import SignedVisitNote from "@/views/Practitioner/SignedVisit/SignedVisitNote";
import SignedVisitNotePatient from "@/views/Practitioner/SignedVisitPatient/SignedVisitNotePatient.vue";

export default {
  name: "MedicalRecordEncounters",
  props: {
    showFiles: { type: Boolean, default: true },
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      menu: false,
      loading: false,
      filter: false,
      filteredEncounters: [],
      isViewMoreEnabled: false,
      item: {},
      selectedEncounterId: null,
    };
  },
  components: {
    SignedVisitNotePatient,
    Pagination,
    EncountersSlide,
    SignedVisitNote,
  },
  watch: {
    async patientId(patientId) {
      if (patientId) {
        await this.setUid(patientId);
        await this.fetchFiles();
      }
    },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["userIsPatient"]),
    ...mapState(useMedicalRecordStore, ["patient", "encounters"]),
    ...mapState(useVisitNoteStore, ["appointmentId", "patientId"]),
    ...mapState(useEncountersStore, ["currentEncounter"]),
    selectedEncounterIndex() {
      const ind = this.encountersList.findIndex((encounter) => encounter.id === this.selectedEncounterId);
      return ind === -1 ? 1 : ind;
    },
    encountersList() {
      return this.encounters;
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["clearPatientInfoFields", "clearMedicalInfoFields"]),
    ...mapActions(useVisitNoteStore, [
      "setEncounterId",
      "setPatientEmail",
      "setPractitionerEmail",
      "setVisitDescription",
    ]),
    ...mapActions(useEncountersStore, ["getCurrentEncounterById"]),
    ...mapActions(useMediaStore, ["setUid"]),
    ...mapActions(useMediaStore, ["fetchFiles", "emptyFiles"]),
    async onEncounterChange(id) {
      if (this.encountersList.length) {
        const selectedEncounter = this.encountersList.find((i) => i.id === id);
        this.selectedEncounterId = id;
        this.setEncounterId(selectedEncounter.id);
      }
      this.loading = true;
      try {
        this.item = await EncountersAPI.getDocument(this.selectedEncounterId);
        await this.getCurrentEncounterById(this.selectedEncounterId);
      } catch (e) {
        console.error(e);
        this.item = {};
      }
      this.loading = false;
    },
    async onPrevEncounter() {
      if (this.selectedEncounterIndex === 0) return;

      if (this.encountersList.length) {
        const selectedEncounter = this.encountersList[this.selectedEncounterIndex - 1];
        this.selectedEncounterId = selectedEncounter.id;
        this.setEncounterId(selectedEncounter.id);
      }
      this.loading = true;
      try {
        this.item = await EncountersAPI.getDocument(this.selectedEncounterId);
        await this.getCurrentEncounterById(this.selectedEncounterId);
      } catch (e) {
        console.error(e);
        this.item = {};
      }
      this.loading = false;
    },
    async onFirstEncounter() {
      if (!this.encountersList?.[0]) return;

      if (this.encountersList.length) {
        const selectedEncounter = this.encountersList[0];
        this.selectedEncounterId = selectedEncounter.id;
        this.setEncounterId(selectedEncounter.id);
      }
      this.loading = true;
      try {
        this.item = await EncountersAPI.getDocument(this.selectedEncounterId);
        await this.getCurrentEncounterById(this.selectedEncounterId);
      } catch (e) {
        console.error(e);
        this.item = {};
      }
      this.loading = false;
    },
    async onNextEncounter() {
      if (this.selectedEncounterIndex === this.encountersList.length - 1) return;

      if (this.encountersList.length) {
        const selectedEncounter = this.encountersList[this.selectedEncounterIndex + 1];
        this.selectedEncounterId = selectedEncounter.id;
        this.setEncounterId(selectedEncounter.id);
      }
      this.loading = true;
      try {
        this.item = await EncountersAPI.getDocument(this.selectedEncounterId);
        await this.getCurrentEncounterById(this.selectedEncounterId);
      } catch (e) {
        console.error(e);
        this.item = {};
      }
      this.loading = false;
    },
    async loadEncounter() {
      if (this.selectedEncounterId) {
        this.loading = true;
        try {
          this.item = await EncountersAPI.getDocument(this.selectedEncounterId);
          await this.getCurrentEncounterById(this.selectedEncounterId);
        } catch (e) {
          console.error(e);
          this.item = {};
        }
        this.loading = false;
      }
    },
    formatDate(date = undefined) {
      return moment(date);
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },

  beforeDestroy() {
    this.clearPatientInfoFields();
    this.clearMedicalInfoFields();
    this.setVisitDescription(initialVisitDescription);
    this.setPatientEmail("");
    this.setPractitionerEmail("");
    this.$root.$off(ADDENDUM_NOTE_ADDED, this.loadEncounter);
  },

  async mounted() {
    await this.setUid(this.$route.params.patientId);
    if (this.encountersList.length) {
      this.selectedEncounterId = this.encountersList[0].id;
      await this.onEncounterChange(this.selectedEncounterId);
    }
    this.$root.$on(ADDENDUM_NOTE_ADDED, this.loadEncounter);
  },
};
</script>

<style lang="scss" scoped>
.view-more {
  letter-spacing: normal;
  text-transform: none;
  font-size: 15px;
}

.skeleton-loader {
  max-width: 756px;
  margin: 0 auto;
}

.active-encounter {
  color: var(--primary);
  background: #edf7fe;
  border: 1px solid var(--primary);
  border-left-width: 3px;
  transition: 0.3s ease;

  p {
    color: var(--primary);
  }
}

.slider-card {
  margin-top: 11px;
}
</style>
