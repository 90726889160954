<template>
  <div class="full-width fill-height floating-start-channel">
    <div class="wrap-content">
      <v-autocomplete
        :allow-overflow="false"
        :filter="customFilter"
        :items="selectPatients"
        :menu-props="{ class: 'menu-class ', rootClass: 'menu-class ' }"
        :search-input.sync="predictionsSearch"
        :value="toUserId"
        append-icon=""
        class="autocomplete-search"
        filled
        height="46"
        hide-details
        item-value="id"
        placeholder="First/Last name"
        @input="onPatientSelect"
      >
        <template v-slot:selection="data">
          <v-chip color="white">
            <Avatar
              v-if="data.item.id !== 'generated'"
              :src="data.item.photoURL"
              :userId="data.item.value"
              class="mr-3"
            />
            <v-avatar v-else class="mr-2" color="primary" size="36">
              <span class="white--text text-h5">{{ data.item.firstName && data.item.firstName.toUpperCase() }}</span>
            </v-avatar>
            {{ data.item.firstName || "" }} {{ data.item.lastName || "" }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-avatar>
              <Avatar v-if="data.item.id !== 'generated'" :src="data.item.photoURL" :userId="data.item.id" />
              <v-avatar v-else color="primary" size="36">
                <span class="white--text text-h5">
                  {{ data.item.firstName || "" }} {{ data.item.lastName || "" }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="list-content-invite-meeting-room">
              <v-list-item-title> {{ data.item.firstName || "" }} {{ data.item.lastName || "" }}</v-list-item-title>
              <v-list-item-subtitle v-if="data.item.medicalRecordNumber">
                {{ data.item.medicalRecordNumber }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <Select :items="chatTypes" :value="ticketCategory" label="Category" @change="onTypeSelect" />
      <PhoneInput
        v-if="!isPhoneValidationDisabled"
        v-model="phoneNumber"
        :errors="phoneNumberErrors"
        class-name="mb-5"
        placeholder="Phone Number"
        @blur="$v.phoneNumber.$touch()"
        @change="onPhoneNumberChange"
      />
      <BaseInput
        v-else
        v-model="phoneNumber"
        :errors="phoneNumberErrors"
        class-name="mb-5"
        placeholder="Phone Number"
        @blur="$v.phoneNumber.$touch()"
        @change="onPhoneNumberChange"
      />
      <PrimaryButton :disabled="$v.$invalid" :loading="sendLoading" text="Send invite" @onClick="submit" />
    </div>
  </div>
</template>

<script>
import { orderBy, startCase } from "lodash-es";
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import Avatar from "@/components/Avatar/Index.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import BaseInput from "@/components/uikit/BaseInput.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import Select from "@/components/uikit/Select.vue";
import { ChatTicketType } from "@/context/twilioContext/enums/ChatTicketType";
import { useAuthStore } from "@/pinia-store/auth";
import { FloatingChatTabs, useChatStore } from "@/pinia-store/chat";
import { usePatientsStore } from "@/pinia-store/patients";
import { isValidPhoneNumber } from "@/utils/validations";

export default {
  name: "FloatingStartChannel",
  components: { BaseInput, Avatar, PhoneInput, PrimaryButton, Select },
  inject: ["injectable"],

  props: {
    channel: Object,
    active: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isPhoneValidationDisabled: true,
      toUserId: "",
      ticketCategory: ChatTicketType.clinical,
      phoneNumber: "",
      message: "",
      files: null,
      sendLoading: false,
      toPatientId: false,
      archiveDialog: false,
      archiveLoading: false,
      attachmentsTab: false,
      showFAQ: false,
      typingVisible: false,
      predictionsSearch: "",
    };
  },
  methods: {
    ...mapActions(useChatStore, [
      "toggleFloatingChatVisible",
      "setFloatingChatTab",
      "updateNewFloatingChatData",
      "setNewFloatingChatData",
    ]),
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    customFilter(item, queryText) {
      const firstName = item?.firstName?.toLowerCase();
      const lastName = item?.lastName?.toLowerCase();
      const phoneNumber = item?.phoneNumber?.toLowerCase() || "";
      const email = item?.email?.toLowerCase() || "";
      const searchText = queryText.toLowerCase();
      return (
        firstName.indexOf(searchText) > -1 ||
        lastName.indexOf(searchText) > -1 ||
        email.indexOf(searchText) > -1 ||
        phoneNumber.indexOf(searchText) > -1
      );
    },
    onPhoneNumberChange(value) {
      this.updateNewFloatingChatData({ field: "phoneNumber", value });
      this.phoneNumber = value;
      this.$v.phoneNumber.$touch();
    },

    onPatientSelect(value) {
      const patient = this.patients.find((i) => i.id === value);
      if (patient) {
        this.updateNewFloatingChatData({ field: "toUserId", value });
        this.toUserId = value;
        if (patient.phoneNumber) {
          this.phoneNumber = patient.phoneNumber;
          this.updateNewFloatingChatData({ field: "phoneNumber", value: patient.phoneNumber });
        }
      }
    },
    onTypeSelect(value) {
      this.ticketCategory = value;
    },
    async submit() {
      if (this.$v.$invalid) {
        return;
      }
      this.sendLoading = true;
      await this.injectable.twilioContext.createNewTicketConversation({
        toUserId: this.toUserId,
        ticketCategory: this.ticketCategory,
        displayName: this.phoneNumber,
        phoneNumber: this.phoneNumber,
      });
      this.sendLoading = false;
      this.setNewFloatingChatData({ toUserId: "", phoneNumber: "", ticketCategory: ChatTicketType.clinical });
      this.setFloatingChatTab(FloatingChatTabs.channelsList);
    },
  },
  validations: {
    phoneNumber: { required, isValidPhoneNumber },
    ticketCategory: { required },
  },
  computed: {
    ...mapState(useAuthStore, ["uid", "isLoggedIn"]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useChatStore, ["newFloatingChatData"]),
    selectPatients() {
      return orderBy(
        this.patients.map((i) => ({
          ...i,
          text: `${i.firstName || ""} ${i.lastName || " "}`,
          sortColumn: `${i.firstName || ""} ${i.lastName || " "}`.toLowerCase(),
        })),
        ["sortColumn"],
      );
    },
    chatTypes() {
      return Object.values(ChatTicketType).map((i) => ({ value: i, text: startCase(i) }));
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      (!this.$v.phoneNumber.required || !this.$v.phoneNumber.isValidPhoneNumber) &&
        errors.push(this.$t("validation.required", { field: "Mobile phone number" }));
      return errors;
    },
  },

  mounted() {
    this.phoneNumber = this.newFloatingChatData.phoneNumber || "";
    this.toUserId = this.newFloatingChatData.toUserId || "";
    this.ticketCategory = this.newFloatingChatData.ticketCategory || ChatTicketType.clinical;
    if (this.phoneNumber) {
      this.$v.$touch();
    }
  },
};
</script>

<style lang="scss" scoped>
.floating-start-channel {
  :deep .autocomplete-search {
    margin-bottom: 24px;

    .v-input__control > .v-input__slot {
      &:hover {
        background: transparent !important;
      }

      background: transparent;
      border: 1px solid #ddd;
    }
  }

  height: calc(100% - 50px);
  overflow-x: auto;
  padding: 18px;
  align-items: center;
  display: flex;

  .wrap-content {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
</style>
