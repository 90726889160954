<template>
  <v-row id="signed-visit-note-root" :class="isPrinting && 'print'" class="signed-visit-note-root">
    <v-col cols="12">
      <div class="signed-visit-note-side white mb-5">
        <v-row dense>
          <v-col :cols="isPrinting ? 5 : 12" :md="isAddNoteAvailable ? 5 : 6">
            <div class="doctor-card">
              <Avatar :src="practitioner.photoURL" />
              <div>
                <div class="title">{{ practitioner.firstName }} {{ practitioner.lastName }}</div>
                <div class="description">{{ practitioner.qualification }}</div>
              </div>
            </div>
          </v-col>
          <v-col :cols="isPrinting ? 4 : 12" :md="isAddNoteAvailable ? 4 : 6">
            <div class="visit-info">
              <span class="head">
                <img
                  :src="require('@/assets/icons/rounded-sick.svg')"
                  alt="Chief complaint"
                  class="d-visit-desc_tick-icon"
                />
                <span>{{ $t("visits.chiefComplaint") }} </span>
              </span>
              <div class="value">
                {{ translateChiefComplaint(visitNote.chiefComplaint) || encounter.chiefComplaint }}
              </div>
            </div>
          </v-col>
          <v-col
            v-if="isAddNoteAvailable"
            :cols="isPrinting ? 3 : 12"
            class="justify-end d-flex gap-1 flex-column"
            md="3"
          >
            <template v-if="!isPrinting">
              <v-btn color="primary" outlined small @click="onAddNote">
                <v-icon small>mdi-plus</v-icon>
                Add addendum
              </v-btn>
              <v-btn color="primary" outlined small @click="printNote">
                <v-icon small>mdi-file-pdf-box</v-icon>
                Download PDF
              </v-btn>
              <v-btn :loading="sendToPatientLoading" color="primary" outlined small @click="sendToPatientEmail">
                <v-icon small>mdi-email-arrow-right</v-icon>
                Send to patient
              </v-btn>
            </template>
          </v-col>
          <v-col cols="12">
            <div class="visit-info-reason">
              <span class="head">
                <span>Visit Reason Note </span>
              </span>
              <div class="value">
                <div class="value" v-html="visitNote.followUp" />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="signed-visit-note white section-header-logo">
        <div class="edit-section pad">
          <div
            :class="`status-band ${status}`"
            :style="{
              'background-image': `url('${
                isFinished ? require('@/assets/icons/band-green.svg') : require('@/assets/icons/band-yelow.svg')
              }')`,
            }"
          >
            {{ statusText }}
          </div>
          <v-btn v-if="isEditAvailable" class="pend-btn" color="#f2994a" small @click="onEdit">
            <v-icon small>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </div>
        <div class="section-one pad pt-0">
          <div class="left">
            <img :src="logoExpandedUrl" alt="" class="logo" />
            <p>
              <span class="label">Date Issued:</span>
              <span class="value"> {{ visitDate }}</span>
            </p>
            <p>
              <span class="label">Visit Number: </span>
              <span class="value">{{ visitId }}</span>
            </p>
          </div>
          <div class="right">
            <p>
              <span class="label">Big Binary LLC</span>
            </p>
            <p>
              <span class="label">350 SW 148th Ave</span>
            </p>
            <p>
              <span class="label">Miramar FL 33027</span>
            </p>
            <p>
              <span class="label">United States</span>
            </p>
          </div>
        </div>
        <v-expansion-panels v-model="panel" accordion flat multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="head-section"> Patient information</v-expansion-panel-header>
            <v-expansion-panel-content class="section-two">
              <PatientSummaryContactInfo :item="patientInfo" :show-full-name="true" />
              <v-row class="pad">
                <v-col cols="12">
                  <p class="label">Duration</p>
                  <p class="value value-pb">{{ startDuration }} - {{ endDuration }} {{ gmtText }}</p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class=" ">
            <v-expansion-panel-header class="head-section"> Medical Information</v-expansion-panel-header>
            <v-expansion-panel-content class="section-three pad">
              <v-row>
                <v-col v-if="allergies" cols="12">
                  <div class="title">Allergies</div>
                  <div class="value">{{ allergies }}</div>
                </v-col>
                <v-col v-if="pmh" cols="12">
                  <div class="title">Past Medical History</div>
                  <div class="value">{{ pmh }}</div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="medications.length" class=" ">
            <v-expansion-panel-header class="head-section"> Medications</v-expansion-panel-header>
            <v-expansion-panel-content class="section-three pad">
              <v-row v-for="item in medications" :key="item.id">
                <v-col cols="10">
                  <MedicationDetails :isPrinting="isPrinting" :item="item" />
                </v-col>
                <v-col cols="2">
                  {{ toStatus(item.status) }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="pharmacy" class=" ">
            <v-expansion-panel-header class="head-section"> Pharmacy</v-expansion-panel-header>
            <v-expansion-panel-content class="section-three pad">
              <v-row>
                <v-col cols="12">
                  <PharmacyDetails :item="pharmacy" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="Boolean(serviceRequests.length)" class=" ">
            <v-expansion-panel-header class="head-section"> Orders</v-expansion-panel-header>
            <v-expansion-panel-content class="section-three pad">
              <v-row v-for="item in serviceRequests" :key="item.id">
                <v-col cols="12">
                  <ServiceRequestObservationDetails :item="item" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="head-section"> Encounter Notes</v-expansion-panel-header>
            <v-expansion-panel-content class="section-three pad">
              <v-row>
                <v-col cols="12">
                  <div class="title">Chief Complaint</div>
                  <div class="value paragraph">{{ translateChiefComplaint(visitNote.chiefComplaint) }}</div>
                </v-col>
                <v-col v-if="visitNote.assessment" cols="12">
                  <div class="title">Assessment</div>
                  <div class="value paragraph" v-html="visitNote.assessment" />
                </v-col>
                <v-col v-if="visitNote.plan" cols="12">
                  <div class="title">Plan / Patient Instructions</div>
                  <div class="value paragraph" v-html="visitNote.plan" />
                </v-col>
                <v-col v-if="visitNote.followUp" cols="12">
                  <div class="title">Dear {{ patientFullName }}</div>
                  <div class="value paragraph" v-html="visitNote.followUp" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="notes.length">
            <v-expansion-panel-header class="head-section"> Addendums</v-expansion-panel-header>
            <v-expansion-panel-content class="section-three pad">
              <v-row>
                <v-col v-if="notes.length" cols="12">
                  <AddendumNote v-for="note in notes" :key="note.id" :item="note" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="section-three pad">
          <v-row>
            <v-col cols="12">
              <v-col cols="12">
                <div class="title pb-2">Signed by:</div>
                <div class="practitioner">
                  <Avatar :src="practitioner.photoURL" />
                  <div>
                    <div class="value">Dr. {{ practitioner.firstName }} {{ practitioner.lastName }}</div>
                    <div class="subtitle">{{ practitioner.qualification }}</div>
                  </div>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </div>
      <AddAddendumNote
        :dialog="addNoteDialog"
        :encounterId="this.encounter.id"
        :patient-id="this.patientId"
        @close="onCloseNote"
        @save="onSaveNote"
      />
      <Confirm :dialog="isPrinting" :hide-cancel="true" :hide-close="true" :hide-confirm="true">
        <div class="d-flex justify-center">
          <v-progress-circular v-if="isPrinting" color="primary" indeterminate size="25" width="3" />
        </div>
      </Confirm>
    </v-col>
  </v-row>
</template>

<script>
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import { upperFirst } from "lodash-es";
import { mapActions, mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import Avatar from "@/components/Avatar/Index";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import PatientSummaryContactInfo from "@/components/MedicalRecord/PatientSummary/ContactInfo";
import Confirm from "@/components/shared/Confirm.vue";
import AddendumNote from "@/components/VisitNotes/AddendumNote";
import ServiceRequestObservationDetails from "@/components/VisitNotes/medicationOrder/ServiceRequestObservationDetails";
import MedicationDetails from "@/components/VisitNotes/medicationReconsiliation/MedicationDetails";
import PharmacyDetails from "@/components/VisitNotes/PharmacyDetails";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { timeZoneMixin } from "@/mixins/timeZone";
import { useAppStore } from "@/pinia-store/app";
import { useAuthStore } from "@/pinia-store/auth";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import AddAddendumNote from "@/views/Practitioner/SignedVisit/AddAddendumNote";

let doc = new jsPDF("p", "px", "a4");
const pageWidth = doc.internal.pageSize.getWidth();
const pageHeight = doc.internal.pageSize.getHeight();

export default {
  name: "SignedVisitNote",
  components: {
    Confirm,
    PatientSummaryContactInfo,
    AddAddendumNote,
    ServiceRequestObservationDetails,
    PharmacyDetails,
    Avatar,
    MedicationDetails,
    AddendumNote,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    panel: [0],
    isPrinting: false,
    sendToPatientLoading: false,
    addNoteDialog: false,
    a4: {
      width: pageWidth,
      height: pageHeight,
    },
  }),
  mixins: [timeZoneMixin],
  watch: {
    isPrinting(val) {
      if (val) {
        setTimeout(() => {
          this.generatePDF();
        }, 1000);
      }
    },
  },
  methods: {
    ...mapActions(useVisitNoteStore, ["togglePreviewSignNote"]),
    toStatus(status = "") {
      return upperFirst(status.replace("-", " "));
    },
    async sendToPatientEmail() {
      this.sendToPatientLoading = true;
      try {
        await EncountersAPI.sendVisitNoteToPatient(this.item.encounter.id);
        snackBarEventBus.$emit(snackBarEventName, { message: "Visit note was sent!", type: "info" });
      } catch (e) {
        snackBarEventBus.$emit(snackBarEventName, { message: "Error sending visit note", type: "error" });
      } finally {
        this.sendToPatientLoading = false;
      }
    },
    generatePDF() {
      const element = document.getElementById("signed-visit-note-root"); // Get the specific section to print
      const opt = {
        margin: 10,
        filename: "visit-note.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      };
      html2pdf().from(element).set(opt).save();
      setTimeout(() => {
        this.isPrinting = false;
      }, 1000);
    },
    printNote() {
      this.isPrinting = true;
      this.panel = [0, 1, 2, 3, 4];
    },
    onAddNote() {
      this.addNoteDialog = true;
    },
    onCloseNote() {
      this.addNoteDialog = false;
    },
    onEdit() {
      this.$router.push(`/${this.role}/encounters/${this.item.encounter.id}`);
    },
    onSaveNote() {
      this.addNoteDialog = false;
    },
    onClosePreview() {
      this.togglePreviewSignNote();
    },
    translateChiefComplaint(val) {
      return this.$t("general.chiefComplaints")[val] || val || "-";
    },
  },
  computed: {
    ...mapState(useAppStore, ["logoExpandedUrl"]),
    ...mapState(useVisitNoteStore, ["previewSignNote"]),
    ...mapState(useAuthStore, ["timeZone", "uid", "userIsPractitioner", "role"]),
    encounter() {
      return this.item.encounter || {};
    },
    isFinished() {
      return this.encounter.status === EncounterStatusEnum.finished;
    },
    isOnLeave() {
      return this.encounter.status === EncounterStatusEnum.onleave;
    },
    isEditAvailable() {
      return (
        this.item.encounter?.status === EncounterStatusEnum.onleave &&
        this.userIsPractitioner &&
        this.item.practitioner?.id === this.uid
      );
    },
    isAddNoteAvailable() {
      return this.item.encounter?.status === EncounterStatusEnum.finished && this.userIsPractitioner;
    },
    status() {
      return this.item.encounter?.status || "";
    },
    statusText() {
      return this.item.encounter?.status === EncounterStatusEnum.onleave ? "Status: Pending" : "Status: Signed";
    },
    notes() {
      return this.item.notes || [];
    },
    startDuration() {
      const start = this.$moment(this.item?.encounter?.start);
      return start.isValid() ? start.tz(this.timeZone).format("HH:mm") : "--";
    },
    endDuration() {
      const end = this.$moment(this.item?.encounter?.end);
      return end.isValid() ? end.tz(this.timeZone).format("HH:mm") : "--";
    },
    patientFullName() {
      return `${this.item?.patient?.firstName || ""} ${this.item?.patient?.lastName || " "}`;
    },
    patientDoB() {
      const dob = this.$moment(this.item?.patient?.birthDate);
      return dob.isValid() ? dob.format(timeDisplayFormat) : "";
    },
    patientId() {
      return this.item?.patient?.id;
    },
    patientInfo() {
      return {
        ...(this.item?.patient || {}),
        practitioner: this?.item?.practitioner || {},
        patient: this.item?.patient || {},
      };
    },
    allergies() {
      return this.item?.allergies ? this.item?.allergies.map((i) => i.name).join(", ") : undefined;
    },
    practitioner() {
      return this?.item?.practitioner || {};
    },
    medications() {
      return this?.item?.medications || [];
    },
    serviceRequests() {
      return this?.item?.serviceRequests || [];
    },
    pharmacy() {
      return Object.keys(this?.item?.pharmacy || {}).length ? this?.item?.pharmacy : null;
    },
    pmh() {
      return this.item?.pmh ? this.item?.pmh.map((i) => i.name).join(", ") : undefined;
    },
    visitNote() {
      return this.item?.visitNote || {};
    },
    visitDate() {
      const date = this.$moment(this.item?.encounter?.start);
      return date.isValid() ? date.format(timeDisplayFormat) : "--";
    },
    visitId() {
      if (!this.item?.patient) return "";
      return `${this.item.patient?.medicalRecordNumber || "--"}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  max-height: 36px;
}

.root-preview {
  background-color: white;

  .v-card {
    background: #ffffff;
    border: 1px solid #e7e8f2;
    box-sizing: border-box;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
  }
}

.signed-visit-note-root {
  max-width: 756px;
  margin: 0 auto;

  &.print {
    width: 756px !important;

    .edit-section {
      display: none !important;
    }
  }

  :deep canvas {
    //display: none !important;
  }

  &.print {
    :deep .v-expansion-panel-header__icon i {
      display: none !important;
    }
  }

  .edit-section {
    display: flex;
    justify-content: flex-end;
  }

  .pend-btn {
    background: rgba(242, 153, 74, 0.2) !important;
    color: #f2994a;

    .v-btn__content {
      color: #f2994a;
    }
  }

  .add-btn {
    background: rgba(33, 150, 243, 0.2) !important;
    color: var(--primary);
    text-transform: none;

    .v-btn__content {
      color: var(--primary);
    }
  }

  flex-direction: row-reverse;

  .section-header-logo {
    position: relative;
  }

  .pad {
    padding: 40px 30px;

    :deep .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .status-band {
    background-size: contain;
    height: 33px;
    padding: 6px 10px 16px;
    position: absolute;
    left: -10px;
    display: flex;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    align-items: center;
    letter-spacing: 0.2px;
    color: #ffffff;

    &.onleave {
      color: yellow;
    }
  }

  .signed-visit-note-side {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .title {
      padding: 12px 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #25233a;
    }

    .doctor-card {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title {
        padding-left: 20px;
        padding-top: 0;
        padding-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #33343e;
      }

      .description {
        padding-left: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(37, 35, 58, 0.4021);
      }
    }

    .visit-info-reason {
      padding-top: 30px;

      .head {
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #25233a;
          mix-blend-mode: normal;
          opacity: 0.4;
        }

        + .value {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #33343e;
          opacity: 0.9;
        }
      }
    }

    .visit-info {
      .head {
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #25233a;
          mix-blend-mode: normal;
          opacity: 0.4;
        }

        + .value {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #33343e;
          opacity: 0.9;
        }
      }

      .second-head {
        padding-top: 20px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;

        + .value {
          font-size: 14px;
        }

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          color: #25233a;
          mix-blend-mode: normal;
          opacity: 0.4;
        }

        img {
          height: 30px;
          margin-right: 10px;
        }
      }

      .value {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #33343e;
        opacity: 0.9;
      }
    }
  }
}

.signed-visit-note {
  padding: 20px 0;

  .head-section {
    min-height: 33px;
    padding: 6px 28px;
    color: var(--primary);
    background: #eff6ff;
  }

  .practitioner {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  p {
    margin-bottom: 3px;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #33343e;
  }

  .value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    gap: 8px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    img {
      width: 16px;
    }
  }

  .paragraph {
    margin: 1em 0 0 0.7em;
  }

  width: 100%;

  .section-one {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }

  .section-two {
    :deep .v-expansion-panel-content__wrap {
      padding: 0 10px;
    }

    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #9c9c9c;

      &.dark {
        flex-wrap: wrap;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #33343e;
      }
    }

    .value {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      color: #33343e;
    }

    .value-pb {
      padding-bottom: 20px;
    }

    .name {
      font-style: normal;
      font-weight: 400;
      font-size: 19.5px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #000000;
      opacity: 0.92;
    }
  }
}
</style>
