<template>
  <div class="d-appoinments">
    <v-row class="d-appoinments_header" justify="space-between" no-gutters>
      <v-col class="d-appoinments_header_title">
        <span v-if="this.$route.meta.layout === 'default'" class="heading-2">
          {{ $te("practitioner.schedule.scheduleTitle") ? $t("practitioner.schedule.scheduleTitle") : "Schedule" }}
        </span>
        <template v-if="this.$route.meta.layout === 'edit'">
          <span class="heading-2">
            {{ $t("practitioner.appointments.editAvailability.title") }}
          </span>
        </template>
      </v-col>
      <v-col v-if="this.$route.meta.layout === 'default'" class="d-appoinments_header_actions">
        <PrimaryButton
          :text="$t('practitioner.appointments.editAvailability.title')"
          class="primary-btn__red d-appoinments_header_actions__margin"
          color="error"
          size="medium"
          @onClick="toEditAvailabilty"
        />
        <PrimaryButton
          :text="$t('practitioner.appointments.scheduleVisit')"
          leftIcon="mdi-plus"
          size="medium"
          @onClick="toSchedule"
        />
      </v-col>
    </v-row>
    <Tabs
      v-if="this.$route.meta.layout !== 'edit'"
      :arrows="true"
      :tabs="tabs"
      :value="tabValue"
      class="d-appoinments_header-tabs"
    />
    <router-view></router-view>
    <ScheduleDialog :dialog="scheduleDialog" @on-close="scheduleDialog = false" />
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Tabs from "@/components/shared/Tabs";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useSlotsStore } from "@/pinia-store/slots";
import { routesEnum } from "@/types/Routes.enum";

import ScheduleDialog from "./ScheduleDialog";

export default {
  name: "AppointmentsView",
  components: {
    Tabs,
    PrimaryButton,
    ScheduleDialog,
  },
  data: () => ({
    loading: false,
    scheduleDialog: false,
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    tabValue() {
      return this.$route.name === routesEnum.practitionerSchedule ? 0 : 1;
    },
    tabs() {
      return [
        {
          text: "Calendar",
          to: "/practitioner/appointments",
        },
        {
          text: "Appointments",
          to: "/practitioner/appointments/scheduled",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["savePractitionerAvailability"]),
    async saveAvailability() {
      this.loading = true;
      try {
        await this.savePractitionerAvailability();
        this.loading = false;

        await this.$router.push({ name: routesEnum.practitionerSchedule });
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("practitioner.appointments.availabilityWasChanged"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err.message || err,
          type: "error",
        });
      }
      this.loading = false;
    },
    async toEditAvailabilty() {
      await this.$router.push({ path: "/practitioner/appointments/edit" });
    },
    async toSchedule() {
      this.scheduleDialog = true;
    },
    async toCalendar() {
      await this.$router.push({ name: routesEnum.practitionerSchedule });
    },
  },
};
</script>
