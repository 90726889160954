<template>
  <v-select
    :cache-items="false"
    :clearable="clearable"
    :disabled="disabled"
    :error-messages="errors"
    :flat="!outlined"
    :height="multiple ? 'auto' : '47'"
    :hide-details="hideDetails"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :label="label"
    :menu-props="menuProps"
    :multiple="multiple"
    :outlined="!!errors.length || outlined"
    :solo="!outlined"
    :value="value"
    append-icon="mdi-menu-down"
    class="base-input"
    dense
    @blur="onBlur"
    @change="onChange"
    @focus="outlined = true"
  >
    <template v-slot:append>
      <v-icon color="icon" size="24"> mdi-menu-down</v-icon>
    </template>
  </v-select>
</template>
<script>
export default {
  name: "Select",
  props: {
    value: [String, Number],
    label: String,
    clearable: Boolean,
    items: Array,
    itemText: String,
    itemValue: String,
    errors: { default: () => [], type: Array },
    fieldName: { default: "", type: String },
    hideDetails: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    multiple: { default: false, type: Boolean },
    menuProps: { default: () => ({ auto: true }), type: Object },
    labelActive: { default: false, type: Boolean },
  },
  data: () => ({
    outlined: false,
  }),
  methods: {
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
    onBlur() {
      if (!this.labelActive) this.outlined = false;
      this.$emit("blur");
    },
  },
  created() {
    if (this.labelActive) this.outlined = true;
  },
};
</script>
