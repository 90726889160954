<template>
  <div>
    <Confirm
      :dialog="value"
      :loading="loading"
      cancelText="Close"
      confirmText="Rename"
      rootClass="media-rename"
      title="Modify File Name"
      @confirm="performRename"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-top">
        <v-row>
          <v-col cols="12">
            <BaseInput :value="file.name" @change="onNameChange" />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { MediaApi } from "@/api/media";
import Confirm from "@/components/shared/Confirm";
import BaseInput from "@/components/uikit/BaseInput";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "MediaRename",
  props: ["value", "file"],
  components: {
    Confirm,
    BaseInput,
  },
  data: () => ({
    fileName: "",
    loading: false,
  }),
  validations: {
    fileName: { required },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
  },
  watch: {
    value: function (status) {
      if (status && this.file) {
        this.fileName = this.file?.name;
      }
    },
  },
  methods: {
    ...mapActions(useMediaStore, ["setUid"]),
    onNameChange(value) {
      this.fileName = value;
    },

    toggleDialog() {
      this.$emit("toggleDialog");
    },
    async performRename() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      const newFile = await MediaApi.renameFile(this.file.id, this.fileName);
      this.$emit("onChange", newFile);
      this.toggleDialog();
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.media-rename {
  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
