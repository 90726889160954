<template>
  <div class="invite-meeting-room">
    <v-row :class="{ 'pa-2': $vuetify.breakpoint.smAndUp }">
      <v-col v-if="kind === 'inline'" class="header" cols="12">
        <h4 class="title-text">Please complete required information to invite patient to visit via phone or email.</h4>
        <p class="subtitle-text">
          To invite someone to your waiting room complete the fields and click button “Send Invite”.
        </p>
      </v-col>
    </v-row>
    <v-row :class="{ form: kind === 'inline', 'pa-2': $vuetify.breakpoint.smAndUp }">
      <v-col cols="12">
        <v-autocomplete
          :allow-overflow="false"
          :disabled="usersLoading"
          :error="Boolean(inviterErrors.length)"
          :filter="customFilter"
          :items="participantOptions"
          :loading="usersLoading"
          :menu-props="{ class: 'menu-class', rootClass: 'menu-class' }"
          :search-input.sync="predictionsSearch"
          :value="id"
          append-icon=""
          class="autocomplete-search"
          filled
          height="46"
          hide-details
          item-value="id"
          placeholder="First/Last name"
          @blur="fullNameTouch = true"
          @input="onSelectReceiver"
        >
          <template v-slot:selection="data">
            <v-chip color="white">
              <Avatar
                v-if="data.item.id !== 'generated'"
                :src="data.item.photoURL"
                :userId="data.item.value"
                class="mr-3"
              />
              <v-avatar v-else class="mr-2" color="primary" size="36">
                <span class="white--text text-h5">{{ data.item.fullName && data.item.fullName[0].toUpperCase() }}</span>
              </v-avatar>
              {{ data.item.fullName }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <Avatar v-if="data.item.id !== 'generated'" :src="data.item.photoURL" :userId="data.item.id" />
              <v-avatar v-else color="primary" size="36">
                <span class="white--text text-h5">{{ data.item.fullName && data.item.fullName[0].toUpperCase() }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="list-content-invite-meeting-room">
              <v-list-item-title>{{ data.item.fullName }}</v-list-item-title>
              <v-list-item-subtitle v-if="data.item.medicalRecordNumber"
                >{{ data.item.medicalRecordNumber }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-tabs :value="tabValue" background-color="transparent" class="tab_methods">
          <v-tab class="tab_methods__tab" @click="changeInviteMethod('email')">Invite by Email</v-tab>
          <v-tab class="tab_methods__tab" @click="changeInviteMethod('phone')">Invite by Phone</v-tab>
        </v-tabs>
        <BaseInput
          v-if="inviteMethod === 'email'"
          :errors="emailErrors"
          :fullWidth="true"
          :height="48"
          :hide-details="true"
          :value="inviteEmail"
          fieldName="email"
          outlined
          placeholder="Enter email"
          required
          @blur="$v.inviteEmail.$touch()"
          @change="onEmailChange"
        />
        <PhoneInput
          v-else
          :errors="phoneNumberErrors"
          :value="invitePhoneNumber"
          class="mb-4"
          fieldName="phoneNumber"
          placeholder="Enter Phone number"
          @change="phoneNumberChange"
        />
      </v-col>

      <v-col cols="12">
        <Textarea :resize="true" :value="inviteText" @change="onInviteTextChange" />
      </v-col>
      <v-col cols="12">
        <PrimaryButton
          :disabled="this.$v.$invalid"
          :full-width="true"
          :loading="buttonLoading"
          size="large"
          text="Send invite"
          @onClick="saveChanges"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { email, required } from "vuelidate/lib/validators";

import Avatar from "@/components/Avatar/Index.vue";
import PhoneInput from "@/components/shared/PhoneInput";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Textarea from "@/components/uikit/Textarea";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";
import { usePatientsStore } from "@/pinia-store/patients";
import { defaultLocale } from "@/utils/constants";
import { templateString } from "@/utils/locales.helpers";

export default {
  name: "MeetingRoomCreateForm",
  components: { Textarea, PrimaryButton, Avatar, PhoneInput, BaseInput },
  props: {
    kind: {
      type: String,
      default: () => "inline",
    },
  },
  data: () => ({
    buttonLoading: false,
    fullNameTouch: false,
    usersLoading: false,
    predictionsSearch: "",
    locale: defaultLocale,
    recipient: {},
  }),
  watch: {
    predictionsSearch(value) {
      this.setParticipantOptions([
        { id: "generated", fullName: value, value: "generated" },
        ...this.participantOptions.filter(($participant) => $participant.id !== "generated"),
      ]);
    },
  },
  validations() {
    const validations = { email: {}, phoneNumber: {} };
    if (this.inviteMethod === "phone") {
      validations.invitePhoneNumber = { required };
    }
    if (this.inviteMethod === "email") {
      validations.inviteEmail = { required, email };
    }
    return validations;
  },

  computed: {
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(useAuthStore, ["displayName"]),
    ...mapState(useMeetingRoomStore, [
      "inviteEmail",
      "inviteId",
      "inviteFullName",
      "invitePhoneNumber",
      "invite",
      "inviteEmail",
      "inviteMethod",
      "inviteText",
      "participantOptions",
      "inviteDialog",
      "template",
    ]),

    inviterErrors() {
      const errors = [];
      if (!this.inviteFullName && this.fullNameTouch) {
        errors.push([this.$t("validation.required", { field: this.$t("general.inputs.email") })]);
      }
      return errors;
    },

    tabValue() {
      return this.inviteMethod === "email" ? 0 : 1;
    },
    id() {
      return this.invite.id;
    },

    fullName() {
      return this.invite?.fullName || "";
    },
    phoneNumber() {
      return this.invite?.phoneNumber || "";
    },

    emailErrors() {
      const errors = [];
      if (this.inviteMethod !== "email") return [];
      if (!this.$v.inviteEmail.$dirty) return errors;
      if (!this.$v.inviteEmail.email) errors.push(this.$t("validation.email"));
      if (!this.$v.inviteEmail.required)
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (this.inviteMethod !== "phone") return [];
      if (!this.$v.invitePhoneNumber.$dirty) return errors;
      !this.$v.invitePhoneNumber.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
  },
  methods: {
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    ...mapActions(useMeetingRoomStore, ["sendInvite", "getMeetingRooms"]),
    ...mapActions(useMeetingRoomStore, [
      "cleanUpInvite",
      "setInviteText",
      "setInviteEmail",
      "setInviteReceiver",
      "setInviteMethod",
      "setInviteFullName",
      "setInviteId",
      "setInvitePhoneNumber",
      "setParticipantOptions",
      "toggleInviteDialog",
    ]),
    onInviteTextChange(value) {
      this.setInviteText(value);
    },
    changeInviteMethod(method) {
      this.setInviteMethod(method);
    },
    fillParticipants() {
      const participants = (this.patients || []).map(($patient) => ({
        ...$patient,
        value: $patient.id,
        fullName: `${$patient.firstName || ""} ${$patient.lastName}`,
      }));
      if (this.receiver?.id) participants.push(this.receiver);
      this.setParticipantOptions(participants);
    },
    fillTemplate() {
      const inviteText = templateString(this.template, {
        id: this.inviteId,
        name: this.inviteFullName || "",
        practitioner: this.displayName || "",
        link: "%link%",
      });
      this.setInviteText(inviteText);
    },
    onSelectReceiver(value) {
      const receiver = this.participantOptions.find(($participant) => $participant.id === value);

      if (receiver) {
        this.setInviteReceiver(receiver);
        if (receiver.phoneNumber) {
          this.setInvitePhoneNumber(receiver.phoneNumber);
          this.setInviteMethod("phone");
        }
        if (receiver.email) {
          this.setInviteEmail(receiver.email);
          this.setInviteMethod("email");
        }
        this.setInviteFullName(receiver.fullName);
        this.setInviteId(receiver.id);
        this.fillTemplate();
      }
    },
    customFilter(item, queryText) {
      const fullName = item?.fullName?.toLowerCase();
      const phoneNumber = item?.phoneNumber?.toLowerCase() || "";
      const email = item?.email?.toLowerCase() || "";
      const searchText = queryText.toLowerCase();
      return (
        fullName.indexOf(searchText) > -1 || email.indexOf(searchText) > -1 || phoneNumber.indexOf(searchText) > -1
      );
    },
    phoneNumberChange(phoneNumber) {
      this.setInvitePhoneNumber(phoneNumber);
      this.$v.invitePhoneNumber.$touch();
      this.setInviteMethod("phone");
    },
    onEmailChange(value) {
      this.setInviteEmail(value);
      this.setInviteMethod("email");
      this.$v.inviteEmail.$touch();
    },
    cleanUp() {
      this.$v.$reset();
      this.cleanUpInvite();
      this.fullNameTouch = false;
    },
    async saveChanges() {
      if (!this.inviteFullName) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "First/Last name name is required",
          type: "warn",
        });
        return;
      }
      this.buttonLoading = true;
      try {
        await this.sendInvite({
          id: this.inviteId,
          fullName: this.inviteFullName || this.fullName,
          email: this.inviteEmail,
          inviteMethod: this.inviteMethod,
          inviteText: this.inviteText,
          phoneNumber: this.invitePhoneNumber,
          locale: this.locale || defaultLocale,
        });
        snackBarEventBus.$emit(snackBarEventName, {
          message: "An invitation has been successfully sent.",
          type: "success",
        });
        this.$root.$i18n.locale = this.locale;
        this.cleanUp();
        await this.getMeetingRooms();
        if (this.inviteDialog) this.toggleInviteDialog();
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      } finally {
        this.buttonLoading = false;
      }
    },
  },
  async mounted() {
    this.fillTemplate();
    if (!this.patients?.length) {
      this.usersLoading = true;
      await this.getAllPatients();
      this.usersLoading = false;
    }
    this.fillParticipants();
  },
};
</script>

<style lang="scss">
.v-autocomplete__content {
  .v-list-item.v-list-item--link {
    border-bottom: 1px solid #e7e8f2;
  }
}

:deep .v-menu__content {
  max-width: 230px;
}

.list-content-invite-meeting-room {
  .v-list-item__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #33343e;
  }

  .v-list-item__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #acadb1;
    text-transform: uppercase;
  }
}

.v-list-item--highlighted {
  &:before {
    background: #eff6ff;
  }

  .list-content-invite-meeting-room {
    .v-list-item__title {
      color: var(--primary) !important;
    }

    .v-list-item__subtitle {
      color: #9ccffa !important;
    }
  }
}

.invite-meeting-room {
  .autocomplete-search {
    .v-chip {
      padding-left: 0;

      .v-chip__content {
        background: #eff6ff;
        border-radius: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #33343e;
        padding: 4px 16px 4px 4px;
      }
    }

    .v-input__control > .v-input__slot {
      &:before {
        border-color: inherit;
      }

      border-radius: 4px;
      background: white;
      border: 1px solid #eeeff7;

      &:hover {
        background: white !important;
      }
    }
  }

  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #33343e;
  }

  .tab_methods {
    width: 100%;
    margin-bottom: 20px;

    &__tab {
      width: 100%;
      text-transform: none;
      font-size: 14px;
    }
  }

  background-color: white;

  .header {
    margin: 0 auto;
    margin-top: 72px;
    justify-content: center;
    max-width: 680px;
    @include mobile {
      margin-top: 48px;
    }

    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #33343e;
      @include mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .subtitle-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.32px;

      &:first-of-type {
        padding-top: 15px;
      }
    }
  }

  .form {
    max-width: 464px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    border-radius: 6px;
    margin: 36px auto 0;
  }

  .title-text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #33343e;
  }

  .step-2 {
    .header {
      .title-text {
        font-size: 28px;
        line-height: 34px;
      }

      .subtitle-text {
        margin: 0 auto;
        max-width: 500px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.32px;
      }
    }
  }
}
</style>
