import { CancelTokenSource } from "axios";

import { ISchedule } from "@/pinia-store/schedule";

import { apiRequest } from "./apiRequest";

const segment = "/schedule";

const create = async (body: Omit<ISchedule, "id">): Promise<any> => {
  const { data } = await apiRequest.post(segment, body);
  return data;
};

const update = async (body: Partial<ISchedule>): Promise<any> => {
  const { data } = await apiRequest.put(`${segment}/${body.id}`, body);
  return data;
};

const find = async (id: string): Promise<any> => {
  const { data } = await apiRequest.get(`${segment}/${id}`);
  return data;
};

export interface IScheduleGetAllFilter {
  organization?: string;
  practitionerId?: string;
}

const getAll = async (filter: IScheduleGetAllFilter, cancelToken?: CancelTokenSource): Promise<any> => {
  const { data } = await apiRequest
    .get(segment, {
      params: filter,
      ...(cancelToken ? { cancelToken: cancelToken.token } : {}),
    })
    .catch(() => ({ data: [] }));
  return data;
};
const destroy = async (id: string): Promise<any> => {
  const { data } = await apiRequest.delete(`${segment}/${id}`);
  return data;
};

export const ScheduleApi = {
  getAll,
  destroy,
  create,
  find,
  update,
};
