import { DateTime, Duration } from "luxon";
import moment from "moment-timezone";

export interface WeekDay {
  year: number;
  month: number;
  day: number;
}

// Todo refactor
export const getWeek = (currentDate: Date) => {
  const week = [];
  let currentDateTime = DateTime.fromJSDate(currentDate).startOf("week");
  for (let i = 0; i <= 6; i++) {
    week.push(currentDateTime.toFormat("yyyy-M-d"));
    currentDateTime = currentDateTime.plus(Duration.fromObject({ day: 1 }));
  }
  return week.map((d) => {
    const [year, month, day] = d.split("-");
    return {
      year: +year,
      month: +month - 1,
      day: +day,
    };
  });
};

export const weekDays = [1, 2, 3, 4, 5, 6, 7];
export const getWeekDay = (number: number) => {
  return moment().day(number).format("dd")[0];
};
