<template>
  <div>
    <div class="p-schedule-visit__content__title">
      <span class="heading-4">{{ title }}</span>
    </div>
    <div class="p-schedule-visit__content__visit-reason">
      <template>
        <span class="heading-5 p-schedule-visit__content__visit-reason__title mt-4">
          {{ $t("practitioner.schedule.choosePatient") }}
          <span class="p-schedule-visit__content__required">*</span>
        </span>
        <Select
          :hideDetails="false"
          :items="patientsOptions"
          :label="$t('practitioner.schedule.choosePatientFromList')"
          :value="patientId"
          @change="onPatientId"
        />
      </template>
      <span class="heading-5 p-schedule-visit__content__visit-reason__title mt-4">
        {{ $t("visits.chiefComplaint") }}
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <Select
        :hideDetails="false"
        :items="chiefComplaints"
        :label="$t('visits.schedule.first.chiefComplaint')"
        :value="chiefComplaint"
        @change="onChiefComplaintChange"
      />
      <span class="heading-5 p-schedule-visit__content__visit-reason__title">
        {{ $t("visits.schedule.first.describeProblem") }}
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <Textarea
        :height="67"
        :hideDetails="true"
        :placeholder="$t('visits.schedule.first.visitReason')"
        :value="complaintDescription"
        @change="onVisitReasonChange"
      />
    </div>
    <div class="p-schedule-visit__content__visit-reason">
      <span class="heading-5 p-schedule-visit__content__calendar__title">
        <img class="p-schedule-visit__content__icon" src="../../assets/icons/calendar-blue.svg" />
        Choose a Date
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <v-date-picker
        v-model="picker"
        :max="maxDate"
        color="primary"
        full-width
        no-title
        scrollable
        @change="handleOnDate"
        @weekday-format="formatWeekDay"
      >
        <v-spacer></v-spacer>
      </v-date-picker>
    </div>
    <div class="p-schedule-visit__content__visit-reason">
      <span class="heading-5 p-schedule-visit__content__calendar__title">
        <img class="p-schedule-visit__content__icon" src="../../assets/clock.svg" />
        When did you call?
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <Select :hideDetails="false" :items="time" :value="clock" label=" Choose Visit Duration" @change="handleOnTime" />
    </div>
    <div class="p-schedule-visit__content__btn-container">
      <PrimaryButton
        :disabled="isButtonDisabled"
        :fullWidth="true"
        :loading="localButtonLoading"
        :text="submitButtonText"
        className="primary-btn__blue"
        size="large"
        @onClick="nextStep"
      />
    </div>
    <v-progress-linear v-if="loading" class="slot-progress" color="primary" indeterminate />
  </div>
</template>

<script>
import orderBy from "lodash/orderBy";
import moment from "moment-timezone";
import { mapState as mapPiniaState } from "pinia";
import { mapActions, mapState } from "pinia";

import { EncountersAPI } from "@/api/encounters";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import Textarea from "@/components/uikit/Textarea";
import { useAllergiesStore } from "@/pinia-store/allergies";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useMediaStore } from "@/pinia-store/media";
import { usePastVisitStore } from "@/pinia-store/pastVisit";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { useVisitNoteStore } from "@/pinia-store/visitNote";
import { generateTimeslots } from "@/utils/generateTimeslots";

export default {
  name: "PastVisitStep",
  props: {
    mode: String,
    buttonLoading: { default: false, type: Boolean },
  },
  data: () => ({
    maxDate: moment().toISOString(),
    loading: false,
    currentDate: new Date(),
    practitionerList: [],
    selectedPractitioner: "",
    doctorHasSlot: false,
    doctorName: "",
    picker: new Date().toISOString().substr(0, 10),
    time: generateTimeslots("00:00", "23:45", 15),
    start: "",
    end: "",
  }),
  components: {
    Textarea,
    PrimaryButton,
    Select,
  },
  computed: {
    ...mapPiniaState(useAuthStore, ["uid", "role"]),
    ...mapActions(useEncountersStore, ["currentEncounter"]),
    ...mapState(usePatientsStore, ["practitionerOwnPatients", "practitioners", "patients"]),
    ...mapState(usePastVisitStore, [
      "patientId",
      "patientInfo",
      "chiefComplaint",
      "complaintDescription",
      "describeProblem",
      "dateStart",
      "dateEnd",
      "visitStart",
      "visitEnd",
      "clock",
    ]),
    ...mapState(usePatientStore, ["patient"]),
    isButtonDisabled() {
      return (
        !this.patientId || !this.chiefComplaint || !this.complaintDescription || !this.dateStart || !this.visitStart
      );
    },
    patientsOptions() {
      return orderBy(
        this.patients.map(({ firstName, lastName, id }) => ({
          text: `${firstName} ${lastName}`,
          value: id,
        })),
        (i) => i.text.toLowerCase(),
      );
    },
    chiefComplaints() {
      const chiefComplaints = this.$t("general.chiefComplaints");
      if (!chiefComplaints) {
        return [];
      }
      return Object.keys(chiefComplaints).map((key) => ({ text: chiefComplaints[key], value: key }));
    },
    title() {
      return this.$t("visits.schedule.first.title");
    },
    submitButtonText() {
      return "Continue to Chart review";
    },
    localButtonLoading: {
      get() {
        return this.buttonLoading;
      },
      set(newValue) {
        this.$emit("update:buttonLoading", newValue);
      },
    },
  },
  methods: {
    ...mapActions(useEncountersStore, ["setCurrentEncounter"]),
    ...mapActions(useCheckoutStore, ["setStringFieldByName", "setVisitDate"]),
    ...mapActions(usePastVisitStore, [
      "setPatientId",
      "setPatientInfo",
      "setChiefComplaint",
      "setDescribeProblem",
      "setComplaintDescription",
      "setDateStart",
      "setDateEnd",
      "setVisitStart",
      "setVisitEnd",
    ]),
    ...mapActions(usePatientsStore, ["getAllPatients"]),
    ...mapActions(useCheckoutStore, ["preloadPatientProfile"]),
    ...mapActions(useVisitNoteStore, ["clearEncounterNoteFields"]),
    ...mapActions(useMediaStore, ["setUid"]),
    ...mapActions(useVisitNoteStore, ["setEncounterNotesField", "setOtherVisitNotes"]),
    ...mapActions(useVisitNoteStore, ["setEncounter"]),
    ...mapActions(useVisitNoteStore, { setVisitNotePatientId: "setPatientId" }),
    ...mapActions(useAllergiesStore, { setAllergiesPatientId: "setPatientId" }),
    ...mapActions(usePastVisitStore, ["setStep", "setClock"]),
    formatWeekDay(val) {
      return val;
    },
    async nextStep() {
      try {
        this.localButtonLoading = true;
        const data = await EncountersAPI.createPastVisitEncounter({
          start: this.dateStart,
          end: this.dateEnd,
          id: this.currentEncounter?.id,
          patientId: this.patientId,
          practitionerId: this.uid,
          chiefComplaint: this.chiefComplaint,
          complaintDescription: this.complaintDescription,
        });
        this.setCurrentEncounter(data);
        this.setVisitNotePatientId(data.patient?.id);
        this.setEncounter(data);
        this.setStep(2);
      } catch (err) {
        console.warn(err);
      } finally {
        this.localButtonLoading = false;
      }
    },
    handleOnDate() {
      this.setDateStart(this.picker + "T" + this.clock.split(" - ")[0] + ":00.000Z");
      this.setDateEnd(this.picker + "T" + this.clock.split(" - ")[1] + ":00.000Z");
    },
    handleOnTime(value) {
      if (!this.clock) {
        value = "00:00 - 00:15";
      }
      this.setClock(value);
      this.setVisitStart(this.picker + "T" + value.split(" - ")[0] + ":00.000Z");
      this.setVisitEnd(this.picker + "T" + value.split(" - ")[1] + ":00.000Z");
      this.setDateStart(this.picker + "T" + value.split(" - ")[0] + ":00.000Z");
      this.setDateEnd(this.picker + "T" + value.split(" - ")[1] + ":00.000Z");
    },
    onChiefComplaintChange(value) {
      this.setStringFieldByName({ value, fieldName: "chiefComplaint" });
      this.setEncounterNotesField({ value, fieldName: "chiefComplaint" });
      this.setChiefComplaint(value);
    },
    onPatientId(value) {
      if (value) {
        const patientInfo = this.patients.find((p) => p.id === value);
        this.setPatientInfo(patientInfo);
        this.setUid(value);
        this.setPatientId(value);
        this.setAllergiesPatientId(value);
      }
    },
    onVisitReasonChange(value) {
      this.setStringFieldByName({ value, fieldName: "complaintDescription" });
      this.setComplaintDescription(value);
    },
    async onSubmit() {
      this.loading = true;
      await this.preloadPatientProfile(this.patientId);
      this.loading = false;
      this.$emit("submit", 2);
    },
  },
  mounted() {
    this.handleOnDate();
    this.loading = true;
    this.getAllPatients();
    this.practitionerList = this.patients.map((practitioner) => ({
      id: practitioner.id,
      name: `${practitioner.firstName} ${practitioner.lastName}`,
    }));
    this.setVisitDate(new Date().toISOString().substr(0, 10));
    if (this.patient.practitioner)
      this.doctorName =
        (this.patient.practitioner?.firstName || "") + " " + (this.patient.practitioner?.lastName || "");
    this.loading = false;
  },
};
</script>

<style scoped></style>
