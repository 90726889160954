import { ISlot } from "@/pinia-store/schedule";

import { apiRequest } from "./apiRequest";

export interface GetAllSlotsFilter {
  practitionerId?: string;
  scheduleId?: string;
  scheduleIds?: string[];
  status?: string;
  date?: string;
}

const segment = "/slot";

const find = async (id: string): Promise<any> => {
  const { data } = await apiRequest.get(`${segment}/${id}`);
  return data;
};
const create = async (body: Omit<ISlot, "id">): Promise<any> => {
  const { data } = await apiRequest.post(segment, body);
  return data;
};

const update = async (body: Partial<ISlot>): Promise<any> => {
  const { data } = await apiRequest.put(`${segment}/${body.id}`, body);
  return data;
};

const getAllFiltered = async (params: GetAllSlotsFilter) => {
  const { data } = await apiRequest.get(`/slot`, { params: { ...(params || {}) } });
  return data;
};

const setOnHold = async (slotId: string) => {
  const { data } = await apiRequest.post(`/slot/${slotId}/on-hold`);
  return data;
};

const getAllOnHold = async () => {
  const { data } = await apiRequest.get(`/slot/on-hold`);
  return data;
};

const destroy = async (slotId: string) => {
  const { data } = await apiRequest.delete(`/slot/${slotId}`);
  return data;
};

const destroyOnHold = async (slotId: string) => {
  const { data } = await apiRequest.delete(`/slot/${slotId}/on-hold`);
  return data;
};
const getById = async (slotId: string) => {
  if (!slotId) return null;
  const { data } = await apiRequest.get(`/slot/${slotId}`);
  return data;
};
const getIsAvailable = async (params: {
  dateOfAppointment?: string;
  gender?: string;
  language?: string;
  uid?: string;
  practitionerId?: string;
  startDate?: string;
  endDate?: string;
  excludeRegistrars?: boolean;
}) => {
  const { dateOfAppointment, gender, language, uid, practitionerId, startDate, endDate, excludeRegistrars } = params;
  const { data } = await apiRequest.get(`/slot/available`, {
    params: {
      ...(excludeRegistrars ? { excludeRegistrars: true } : {}),
      ...(dateOfAppointment ? { startDate: dateOfAppointment } : {}),
      ...(dateOfAppointment ? { endDate: dateOfAppointment } : {}),
      ...(language ? { locale: language } : {}),
      ...(gender ? { gender: gender } : {}),
      ...(gender ? { excludeId: uid } : {}),
      ...(practitionerId ? { practitionerId } : {}),
      startDate,
      endDate,
    },
  });
  return data;
};

const getAll = async () => {
  const { data } = await apiRequest.get(`/slot`);
  return data;
};

export const SlotsApi = {
  find,
  create,
  update,
  getAllFiltered,
  getById,
  getAll,
  getAllOnHold,
  getIsAvailable,
  setOnHold,
  destroy,
  destroyOnHold,
};
