<template>
  <Confirm
    :dialog="editDialog"
    :hide-cancel="true"
    :hideDescription="true"
    :hideRemove="!form.id"
    :loading="isSaving"
    confirm-text="Save"
    max-width="90%"
    title="Slots availability"
    width="480px"
    @confirm="triggerSave"
    @delete="onDeleteSlot"
    @toggleDialog="() => (editDialog = !editDialog)"
  >
    <SlotEditForm />
  </Confirm>
</template>
<script>
import { mapActions, mapState, mapWritableState } from "pinia";

import { SlotsApi } from "@/api/slots";
import Confirm from "@/components/shared/Confirm.vue";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { useSlotsStore } from "@/pinia-store/slots";
import SlotEditForm from "@/views/Owner/Organizations/OrganizationSchedule/SlotEditForm.vue";

export default {
  name: "SlotEditModal",
  components: { SlotEditForm, Confirm },
  computed: {
    ...mapState(useHealthcareServiceStore, ["healthcareServices"]),
    ...mapWritableState(useSlotsStore, ["editDialog", "isSaving", "form"]),
    slots() {
      return this.form.slots || [];
    },
  },
  methods: {
    ...mapActions(useHealthcareServiceStore, ["getHealthcareServices"]),
    ...mapActions(useSlotsStore, [
      "changeFormField",
      "changeFormSlotField",
      "saveForm",
      "setForm",
      "appendFormSlot",
      "removeFormSlot",
      "changeSlotRepeat",
    ]),
    async onDeleteSlot() {
      await SlotsApi.destroy(this.form.id);
      this.$emit("changed");
      this.editDialog = false;
    },
    async triggerSave() {
      await this.saveForm();
      this.$emit("changed");
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await SlotsApi.find(this.$route.params.id);
      this.setForm(item);
      this.isLoading = false;
    }

    await this.getHealthcareServices(this.$route.params.organizationId);
  },
};
</script>

<style lang="scss" scoped>
.organization-form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}

:deep {
  .time-picker {
    min-width: 120px;

    .base-input .v-input__control .v-input__slot fieldset {
      border: none !important;
    }

    .v-input__slot {
      border: none !important;
      cursor: pointer !important;
    }
  }

  .w-130 {
    width: 130px;

    .v-input__control {
      width: inherit;
    }
  }

  .w-100 {
    width: 100px;

    .v-input__control {
      width: inherit;
    }
  }
}

.pol-container {
  border-bottom: 1px solid #eeeff7;
  border-top: 1px solid #eeeff7;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 12px;
}

.pol-border {
  // clip-path: polygon(80% 0, 0 0, 0 100%, 80% 100%, 100% 50%);
  display: flex;
  background-position: right center;
  border: none;
  padding: 10px 80px 10px 10px;
  background-size: auto 100%;
}
</style>
