<template>
  <v-card class="calendar">
    <div class="calendar_header calendar_header__dense">
      <span class="calendar_header_title">
        {{ $t("practitioner.appointments.editAvailability.subtitle") }}
      </span>
    </div>
    <calendar
      id="availabilityCalendar"
      ref="tuiCalendar2"
      :isReadOnly="false"
      :scheduleView="scheduleView"
      :schedules="renderedSchedules"
      :taskView="taskView"
      :template="templates"
      :theme="theme"
      :usageStatistics="false"
      :useCreationPopup="false"
      :useDetailPopup="false"
      :view="view"
      :week="week"
      style="height: 800px"
      @beforeCreateSchedule="onBeforeCreateSchedule"
      @beforeUpdateSchedule="onBeforeUpdateSchedule"
      @clickSchedule="deleteSchedule"
    >
    </calendar>
    <div class="calendar_timezone">
      <span>
        <span class="calendar_timezone__bold">
          {{ $t("practitioner.appointments.editAvailability.activeTimezone", { timezone: renderedTimeZone }) }}
        </span>
        {{ $t("practitioner.appointments.editAvailability.timezoneHint") }}
        <router-link to="/practitioner/settings">{{
          $t("practitioner.appointments.editAvailability.here")
        }}</router-link>
      </span>
    </div>
  </v-card>
</template>
<script>
import { Calendar } from "@toast-ui/vue-calendar";
import { DateTime } from "luxon";
import { mapActions, mapState } from "pinia";
import shortid from "shortid";
import Vue from "vue";

import { calendarMixin } from "@/mixins/calendar.ts";
import { useAuthStore } from "@/pinia-store/auth";
import { useSlotsStore } from "@/pinia-store/slots";
import { convertDayNames } from "@/utils/locales.helpers";

// TODO Completely refactor this file
const scheduleCustomStyles = {
  width: "100%",
  background: "linear-gradient(0deg, rgba(33, 150, 243, 0.05), rgba(33, 150, 243, 0.05)), #FFFFFF",
  border: "1px solid var(--primary)",
  "border-radius": "3px",
  margin: "0 5px",
};
export default Vue.extend({
  name: "EditAvailabilityCalendar",
  mixins: [calendarMixin],
  components: {
    calendar: Calendar,
  },
  data: () => ({
    theme: {
      "week.timegridOneHour.height": "51px",
      "week.today.backgroundColor": "inherit",
      "week.timegridHorizontalLine.borderBottom": "1px dashed #e5e5e5",
    },
  }),
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(useSlotsStore, ["availabilities", "weekends"]),
    renderedSchedules() {
      return this.availabilities.map((s) => {
        return {
          ...s,
          category: "time",
          isReadOnly: true,
          dueDateClass: "",
          calendarId: "1",
          customStyle: Object.keys(scheduleCustomStyles)
            .map((k) => `${k}: ${scheduleCustomStyles[k]}`)
            .join("; "),
        };
      });
    },
    renderedTimeZone() {
      return DateTime.local().toFormat("ZZZZ");
    },
    templates() {
      return {
        time: (schedule) => {
          const start = schedule.start.toDate();
          const end = schedule.end.toDate();
          const timeInterval = (end - start) / 60000;
          const startTime = DateTime.fromJSDate(start).toFormat("H:mm");
          const endTime = DateTime.fromJSDate(end).toFormat("H:mm");
          return `<div class="c-week-availability" id="${schedule.id}">
        <div class="c-week-availability-info">
          <span>${startTime} - ${endTime}</span>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.42">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.55976 7.00004L9.50909 5.05068C9.66364 4.89614 9.66364 4.64545 9.50909 4.49091C9.35455 4.33636 9.10387 4.33636 8.94933 4.49091L7 6.44027L5.05067 4.49091C4.89613 4.33636 4.64545 4.33636 4.49091 4.49091C4.33636 4.64545 4.33636 4.89614 4.49091 5.05068L6.44024 7.00004L4.49091 8.9494C4.33636 9.10395 4.33636 9.35463 4.49091 9.50917C4.56801 9.58661 4.6694 9.625 4.77079 9.625C4.87218 9.625 4.97324 9.58628 5.05067 9.50917L7 7.55981L8.94933 9.50917C9.02676 9.58661 9.12782 9.625 9.22921 9.625C9.3306 9.625 9.43166 9.58628 9.50909 9.50917C9.66364 9.35463 9.66364 9.10395 9.50909 8.9494L7.55976 7.00004Z" fill="#2196F3"/>
              <path d="M7.55976 7.00004L9.50909 5.05068C9.66364 4.89614 9.66364 4.64545 9.50909 4.49091C9.35455 4.33636 9.10387 4.33636 8.94933 4.49091L7 6.44027L5.05067 4.49091C4.89613 4.33636 4.64545 4.33636 4.49091 4.49091C4.33636 4.64545 4.33636 4.89614 4.49091 5.05068L6.44024 7.00004L4.49091 8.9494C4.33636 9.10395 4.33636 9.35463 4.49091 9.50917C4.56801 9.58661 4.6694 9.625 4.77079 9.625C4.87218 9.625 4.97324 9.58628 5.05067 9.50917L7 7.55981L8.94933 9.50917C9.02676 9.58661 9.12782 9.625 9.22921 9.625C9.3306 9.625 9.43166 9.58628 9.50909 9.50917C9.66364 9.35463 9.66364 9.10395 9.50909 8.9494L7.55976 7.00004" stroke="#2196F3" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </div>
        <div class="c-week-availability-resize tui-full-calendar-time-resize-handle${
          timeInterval === 30 ? " c-week-availability-resize--hidden" : ""
        }"><div></div></div>
        </div>`;
        },
        weekDayname: (model) => {
          const { day } = model;
          const weekends = this.weekends.map((w) => (w === "7" ? "0" : w));
          const isWeekend = weekends.some((weekend) => +weekend === day);
          if (isWeekend) {
            return `<div class="c-week-dayname">
          <div class="c-week-dayname--weekend">
          <span>${model.dayName}</span>
          </div>
        </div>`;
          }
          return `<div class="c-week-dayname">
          <span>${model.dayName}</span>
        </div>`;
        },
      };
    },
    week() {
      const dayNames = convertDayNames(this.$t("general.calendar.dayNames"), "Sunday");
      return {
        daynames: dayNames,
        startDayOfWeek: 1,
      };
    },
  },
  methods: {
    ...mapActions(useSlotsStore, ["setAvailabilities"]),
    ...mapActions(useSlotsStore, ["getPractitionerAvailability"]),
    onBeforeCreateSchedule(event) {
      const { start, end } = event;
      const alo = this.compareSchedules(start, end);
      this.setAvailabilities(alo);
    },
    deleteSchedule(e) {
      const {
        schedule: { id },
      } = e;
      const schedules = this.availabilities.filter((s) => s.id !== id);
      this.setAvailabilities(schedules);
    },
    onBeforeUpdateSchedule(e) {
      const {
        schedule,
        changes: { end },
      } = e;
      let availabilities = this.availabilities.filter((a) => a.id !== schedule.id);
      const newScheduleStart = schedule.start.toDate();
      const newScheduleEnd = end.toDate();
      const newScheduleWeekDay = DateTime.fromJSDate(newScheduleStart).weekday;

      const newSchedule = {
        id: schedule.id,
        start: schedule.start,
        end,
      };
      availabilities = availabilities.reduce(
        (acc, val) => {
          if (newScheduleWeekDay === DateTime.fromISO(val.start).weekday) {
            if (new Date(val.start) >= newScheduleStart && newScheduleEnd >= new Date(val.end)) {
              return acc;
            }
            if (new Date(val.start) >= newScheduleStart && newScheduleEnd <= new Date(val.end)) {
              return acc.map((el) => (el.id === newSchedule.id ? { ...el, end: val.end } : el));
            }
          }
          return acc.concat([val]);
        },
        [newSchedule],
      );

      const bottomBorder = availabilities.find(({ start }) => {
        return (
          newScheduleWeekDay === DateTime.fromISO(start).weekday &&
          DateTime.fromJSDate(new Date(start)).toFormat("t") === DateTime.fromJSDate(newScheduleEnd).toFormat("t")
        );
      });
      if (bottomBorder) {
        const bottomBorderEnd = DateTime.fromJSDate(new Date(bottomBorder.end)).toISO();
        availabilities = availabilities
          .filter(({ id }) => bottomBorder.id !== id)
          .map((el) => (el.id === newSchedule.id ? { ...el, end: bottomBorderEnd } : el));
      }
      this.setAvailabilities(availabilities);
    },
    compareSchedules(newStart, newEnd) {
      const { availabilities } = this;
      const start1 = newStart.toDate();
      const end1 = newEnd.toDate();
      const startTime = DateTime.fromJSDate(start1).toFormat("t");
      const endTime = DateTime.fromJSDate(end1).toFormat("t");
      const newScheduleWeekDay = DateTime.fromJSDate(start1).weekday;
      const upBorder = availabilities.find(({ end }) => {
        const endDateTime = DateTime.fromISO(end);
        return newScheduleWeekDay === endDateTime.weekday && endDateTime.toFormat("t") === startTime;
      });
      const bottomBorder = availabilities.find(({ start }) => {
        const startDateTime = DateTime.fromISO(start);
        return newScheduleWeekDay === startDateTime.weekday && startDateTime.toFormat("t") === endTime;
      });
      const newVal = {
        id: shortid.generate(),
        start: (upBorder && upBorder.start) || DateTime.fromJSDate(start1).toISO(),
        end: (bottomBorder && bottomBorder.end) || DateTime.fromJSDate(end1).toISO(),
      };
      return availabilities.reduce(
        (acc, val) => {
          const idUp = upBorder && upBorder.id;
          const idBottom = bottomBorder && bottomBorder.id;
          if (idUp === val.id || idBottom === val.id) {
            return acc;
          }
          if (newScheduleWeekDay === DateTime.fromISO(val.start).weekday) {
            if (new Date(val.start) > new Date(newVal.start) && new Date(newVal.end) < new Date(val.start)) {
              return acc.concat(val);
            } else if (new Date(val.start) >= new Date(newVal.start) && new Date(newVal.end) <= new Date(val.end)) {
              return acc.map((el) => (el.id === newVal.id ? { ...el, end: val.end } : el));
            }
            if (new Date(val.end) < new Date(newVal.start) && new Date(newVal.end) > new Date(val.end)) {
              return acc.concat(val);
            } else if (new Date(val.start) <= new Date(newVal.start) && new Date(newVal.end) >= new Date(val.end)) {
              return acc.map((el) => (el.id === newVal.id ? { ...el, start: val.start } : el));
            }
            if (new Date(val.start) >= new Date(newVal.start) && new Date(newVal.end) >= new Date(val.end)) {
              return acc;
            }
          }

          return acc.concat([val]);
        },
        [newVal],
      );
    },
  },
  async mounted() {
    await this.getPractitionerAvailability();
  },
});
</script>
