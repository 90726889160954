<template>
  <div>
    <v-row class="ma-0">
      <v-col class="d-flex pa-0 align-center mt-3" cols="12" lg="3" md="3" sm="6" xl="3">
        <p class="ma-0 mr-2">Doctors:</p>
        <v-select
          :disabled="isLoading || loading"
          :items="practitionersList"
          dense
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeSelectedPractitionerData($event)"
        ></v-select>
      </v-col>
      <v-col class="d-flex pa-0 align-center mt-3" cols="12" lg="3" md="3" sm="6" xl="3">
        <p class="ma-0 mr-2 px-sm-2">Patients:</p>
        <v-select
          :disabled="isLoading || loading"
          :items="patientsOptions"
          dense
          hide-details="true"
          outlined
          placeholder="All"
          @change="changeSelectedPatient($event)"
        ></v-select>
      </v-col>
      <v-col class="d-flex pa-0 align-center mt-3" cols="12" lg="3" md="3" sm="12" xl="3">
        <p class="ma-0 mr-2 px-md-2">Date Range:</p>
        <RangeDatePicker
          v-model="currentDate"
          :dense="true"
          :height="34"
          :hide-title="true"
          :hideDetails="true"
          :inputValue="currentDate"
          :isYearFirst="false"
          className="date-picker__box-shadow v-input--hide-details--custom"
          @setDate="changeCurrentDate"
        />
      </v-col>
    </v-row>
    <div>
      <v-progress-linear v-if="loading || isLoading" color="primary" indeterminate />
      <div v-if="!pendingCheckouts.length && !loading" class="ap-list_no-items">
        <img alt="No Items" src="@/assets/no-items-calendar.svg" />
        <p class="heading-4">There is no appointments to display here</p>
        <p class="sub-heading-3">Book a visit with a doctor first.</p>
        <OutlinedButton
          color="primary"
          style="color: var(--primary); opacity: 1; border: 1px solid var(--primary)"
          text="Schedule a New Visit"
          @onClick="$emit('toSchedule')"
        />
      </div>
      <div class="ap-list_paddings ap-list">
        <AppointmentsListItem
          v-for="checkout in pendingCheckoutsList"
          :key="checkout.id"
          :item="checkout"
          :role="role"
          place="pending"
          @onButtonClick="resendInvite"
          @onCancel="cancel"
        />
      </div>
    </div>
    <CancelVisit
      v-model="dialog"
      :appointment-id="cancelledId"
      :isFromAppointments="true"
      @success="getPendingAppointmentsAfterCancel"
    />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { CheckoutApi } from "@/api/checkout";
import AppointmentsListItem from "@/components/AppointmentsListItem/index.vue";
import CancelVisit from "@/components/CancelVisit/index";
import RangeDatePicker from "@/components/shared/RangeDatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useInvitesStore } from "@/pinia-store/invites";
import { useOwnersStore } from "@/pinia-store/owners";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { useRegistrarsStore } from "@/pinia-store/registrars";

export default {
  name: "Pending",
  props: ["isLoading"],
  data: () => ({
    currentDate: [moment().format("YYYY-MM-DD")],
    practitionerId: null,
    patientId: null,
    dialog: false,
    cancelledId: null,
    loading: false,
  }),
  components: {
    RangeDatePicker,
    AppointmentsListItem,
    CancelVisit,
    OutlinedButton,
  },
  computed: {
    ...mapState(useAuthStore, ["role"]),
    ...mapState(usePatientsStore, ["patients"]),
    ...mapState(usePractitionersStore, ["practitioners"]),
    ...mapState(useOwnersStore, ["pendingCheckouts"]),
    ...mapState(useRegistrarsStore, ["registrarPractitioners"]),
    datePickerValue() {
      const startWeek = DateTime.fromJSDate(this.currentDate).startOf("week").toFormat("LLLL, d");
      const endWeek = DateTime.fromJSDate(this.currentDate).endOf("week").toFormat("LLLL, d");
      return `${startWeek} - ${endWeek}`;
    },
    patientsOptions() {
      const patients = this.patients.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
      patients.unshift({
        text: "All",
        value: null,
      });
      return patients;
    },
    practitionersList() {
      let practitioners = this.practitioners;
      if (this.role === "registrar") {
        practitioners = this.registrarPractitioners;
      }
      practitioners = practitioners.map(({ firstName, lastName, id }) => ({
        text: `${firstName} ${lastName}`,
        value: id,
      }));
      practitioners.unshift({
        text: "All",
        value: null,
      });
      return practitioners;
    },
    pendingCheckoutsList() {
      return this.pendingCheckouts.filter((checkout) =>
        this.role === "registrar" ? this.registrarPractitioners.find((p) => p.id === checkout.practitioner.id) : true,
      );
    },
  },

  methods: {
    ...mapActions(useOwnersStore, [
      "changeSelectedPractitioner",
      "getAllPractitioners",
      "chosePractitioner",
      "getPendingCheckouts",
    ]),
    ...mapActions(useAppointmentsStore, ["getPendingAppointments"]),
    ...mapActions(useInvitesStore, ["sendInvite"]),
    async changeSelectedPractitionerData(selected) {
      this.practitionerId = selected;
      try {
        this.loading = true;
        let queryParams = {
          practitionerId: this.practitionerId,
          patientId: this.patientId,
          status: "pending",
        };
        const datesSorted = this.currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        } else {
          queryParams.date = datesSorted[0];
        }
        await this.getPendingAppointments(queryParams);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async getPendingAppointmentsAfterCancel() {
      this.loading = true;
      try {
        const datesSorted = this.currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        let queryParams = {
          practitionerId: this.practitionerId,
          patientId: this.patientId,
          status: "pending",
          startDate: datesSorted[0],
        };
        await this.getPendingAppointments(queryParams);
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async changeSelectedPatient(selected) {
      this.patientId = selected;
      this.loading = true;
      try {
        let queryParams = {
          practitionerId: this.practitionerId,
          patientId: this.patientId,
          status: "pending",
        };
        const datesSorted = this.currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        } else {
          queryParams.date = datesSorted[0];
        }
        await this.getPendingAppointments(queryParams);
      } catch (e) {
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async changeCurrentDate(newDate) {
      this.currentDate = newDate;
      this.loading = true;
      try {
        let queryParams = {
          practitionerId: this.practitionerId,
          patientId: this.patientId,
          status: "pending",
        };
        const datesSorted = this.currentDate.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
        if (datesSorted.length === 2) {
          queryParams.startDate = datesSorted[0];
          queryParams.endDate = datesSorted[1];
        } else {
          queryParams.date = datesSorted[0];
        }
        await this.getPendingAppointments(queryParams);
      } catch (e) {
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.loading = false;
    },
    async resendInvite(checkoutId) {
      await CheckoutApi.resend(checkoutId);
    },
    cancel(slotId) {
      this.dialog = true;
      this.cancelledId = slotId;
    },
  },
};
</script>

<style scoped></style>
