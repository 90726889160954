<template>
  <div class="time-picker-root">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-bottom="80"
      max-width="290px"
      min-width="290px"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <template>
          <v-text-field
            v-mask="'##:##'"
            :flat="!outlined"
            :height="height"
            :hide-details="true"
            :label="placeholder"
            :masked="true"
            :outlined="outlined"
            :solo="!outlined"
            :v-masked="true"
            :value="inputValue"
            class="base-input"
            dense
            @blur="onBlur"
            @change="onInputChange"
            @focus="onFocus"
            v-on="on"
          >
            <template v-if="iconPrepend && !hideIcon" v-slot:prepend>
              <img class="c-pointer" src="../../assets/time.svg" v-on="on" />
            </template>
            <template v-else-if="!iconPrepend && !hideIcon" v-slot:append>
              <img class="c-pointer" src="../../assets/time.svg" v-on="on" />
            </template>
          </v-text-field>
        </template>
      </template>
      <v-time-picker :value="value" format="24h" full-width @change="onChange"></v-time-picker>
    </v-menu>
    <v-btn v-if="showNow" class="today-btn" color="primary" outlined @click="onNow">Now</v-btn>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "pinia";
import { mask } from "vue-the-mask";

import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "TimePicker",
  props: {
    showNow: { default: false, type: Boolean },
    value: String,
    fieldName: { default: "", type: String },
    height: { default: 47, type: Number },
    iconPrepend: { default: false, type: Boolean },
    placeholder: { default: "", type: String },
    hideIcon: { default: false, type: Boolean },
    labelActive: { default: false, type: Boolean },
  },
  data() {
    return {
      localValue: this.value,
      menu: false,
      outlined: false,
    };
  },
  directives: {
    mask,
  },
  watch: {
    value(newVal) {
      this.localValue = newVal || "";
    },
  },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    inputValue() {
      return this.localValue;
    },
  },
  methods: {
    onChange(value) {
      this.menu = false;
      this.$emit("change", value, this.fieldName);
    },
    onInputChange(value) {
      this.menu = false;
      this.localValue = value;
      if (moment(value, "HH:mm").isValid()) {
        this.$emit("change", value, this.fieldName);
      }
    },
    onBlur() {
      if (!this.labelActive) this.outlined = false;
      this.$emit("blur");
    },
    onFocus() {
      if (!this.outlined) this.outlined = true;
      this.$emit("focus");
    },
    onNow() {
      this.menu = false;
      const parsedDate = this.$moment.tz(this.timeZone).format("HH:mm");
      this.$emit("change", parsedDate);
    },
  },
  created() {
    if (this.labelActive) this.outlined = true;
  },
};
</script>

<style lang="scss">
.c-pointer {
  cursor: pointer;
}

.time-picker-root {
  display: flex;
  width: 100%;

  > div {
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}

.today-btn {
  margin-left: 16px;
  width: 80px;
  height: 46px !important;

  .v-btn__content {
    text-transform: none;
  }
}
</style>
