import moment from "moment-timezone";

export const audioVolume = 0.5;
export const defaultLocale = "en";
export const scheduleHorizonLimitOptions = [7, 15, 30, 45, 60].map((day) => ({ value: day, text: `${day} days` }));
export const scheduleToStartLimitOptions = Array.from(Array((60 / 15) * 24)).map((value, key) => {
  const time = moment("00:00", "HH:mm").add({ minute: key * 15 });
  return {
    value: time.format("HH:mm"),
    text: time.format(time.hour() > 0 ? "H[h]mm[min]" : "m[min]"),
  };
});

export const slotDurationOptions = Array.from(Array((60 / 5) * 2 - 2)).map((value, key) => {
  const time = moment("00:15", "HH:mm").add({ minute: key * 5 });
  return {
    value: time.format("HH:mm"),
    text: time.format(time.hour() > 0 ? "H[h]mm[min]" : "m[min]"),
  };
});
export const scheduleToCancelLimitOptions = Array.from(Array(60 / 5)).map((value, key) => {
  const time = moment("00:05", "HH:mm").add({ minute: key * 5 });
  return {
    value: time.format("HH:mm"),
    text: time.format(time.hour() > 0 ? "H[h]mm[min]" : "m[min]"),
  };
});

export const calendarValues = [
  {
    text: "Day",
    value: "day",
  },
  {
    text: "Week",
    value: "week",
  },
  {
    text: "Month",
    value: "month",
  },
];
