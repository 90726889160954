<template>
  <Confirm
    :dialog="editDialog"
    :hide-cancel="true"
    :loading="isSaving"
    confirm-text="Save"
    title="Schedule planning details"
    @confirm="triggerSave"
    @toggleDialog="() => (editDialog = !editDialog)"
  >
    <ScheduleEditForm />
  </Confirm>
</template>
<script>
import { mapActions, mapState, mapWritableState } from "pinia";

import { ScheduleApi } from "@/api/schedule";
import Confirm from "@/components/shared/Confirm.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { usePractitionerRoleStore } from "@/pinia-store/practitionerRole";
import { useScheduleStore } from "@/pinia-store/schedule";
import ScheduleEditForm from "@/views/Owner/Organizations/OrganizationSchedule/EditForm.vue";

export default {
  name: "ScheduleEditModal",
  components: { ScheduleEditForm, Confirm },
  computed: {
    ...mapState(useAuthStore, ["userIsPractitioner", "uid"]),
    ...mapState(useHealthcareServiceStore, ["healthcareServices"]),
    ...mapState(usePractitionerRoleStore, ["practitionerRoles"]),
    ...mapWritableState(useScheduleStore, ["editDialog", "isSaving", "form"]),
    slots() {
      return this.form.slots || [];
    },
  },
  methods: {
    ...mapActions(useHealthcareServiceStore, ["getHealthcareServices"]),
    ...mapActions(usePractitionerRoleStore, ["getPractitionerRoles"]),
    ...mapActions(useScheduleStore, [
      "fetchSchedules",
      "fetchOrganizationSchedules",
      "changeFormField",
      "changeFormSlotField",
      "saveForm",
      "setForm",
      "appendFormSlot",
      "removeFormSlot",
      "changeSlotRepeat",
    ]),
    async triggerSave() {
      console.log("1");
      if (this.userIsPractitioner) this.form.actorPractitionerId = this.uid;
      await this.saveForm();
      if (!this.userIsPractitioner) await this.fetchOrganizationSchedules(this.$route.params.organizationId);
      else await this.fetchSchedules({ practitionerId: this.uid });
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await ScheduleApi.find(this.$route.params.id);
      this.setForm(item);
      this.isLoading = false;
    }

    await this.getHealthcareServices(this.$route.params.organizationId);
    if (this.userIsPractitioner) await this.getPractitionerRoles({ practitioner: this.uid });
    console.log("Schedule mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}

:deep {
  .time-picker {
    min-width: 120px;

    .base-input .v-input__control .v-input__slot fieldset {
      border: none !important;
    }

    .v-input__slot {
      border: none !important;
      cursor: pointer !important;
    }
  }

  .w-130 {
    width: 130px;

    .v-input__control {
      width: inherit;
    }
  }

  .w-100 {
    width: 100px;

    .v-input__control {
      width: inherit;
    }
  }
}

.pol-container {
  border-bottom: 1px solid #eeeff7;
  border-top: 1px solid #eeeff7;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 12px;
}

.pol-border {
  // clip-path: polygon(80% 0, 0 0, 0 100%, 80% 100%, 100% 50%);
  display: flex;
  background-position: right center;
  border: none;
  padding: 10px 80px 10px 10px;
  background-size: auto 100%;
}
</style>
