// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { omit } from "lodash-es";
import { defineStore } from "pinia";

import { MeetingRoomApi } from "@/api/meeting-room";
import { RoomSettings } from "@/pinia-store/interfaces/RoomSettings";

interface MeetingRoomParticipant {
  id?: string;
  phoneNumber?: string;
  email?: string;
  inviteText: string;
}

export enum MeetingRoomStatus {
  opened = "opened",
  closed = "closed",
}

export interface MeetingRoomItem {
  id: string;
  recipient: MeetingRoomParticipant;
  sender: MeetingRoomParticipant;
  status: string;
}

export interface MeetingRoomInvite {
  id?: string;
  fullName?: string;
  inviteMethod?: "phone" | "email";
  phoneNumber?: string;
  email?: string;
  inviteText?: string;

  [key: string]: any;
}

interface MeetingRoomState {
  openedListDialog: boolean;
  activeMeetingRoom: MeetingRoomItem | null;
  invite: MeetingRoomInvite;
  inviteEmail: string;
  inviteMethod: "phone" | "email";
  inviteReceiver: Record<any, any>;
  invitePhoneNumber: string;
  inviteText: string;
  inviteFullName: string;
  inviteId: string | undefined;
  inviteDialog: boolean;
  connectedParticipant: string | null;
  localParticipant: MeetingRoomParticipant | null;
  remoteParticipant: MeetingRoomParticipant | null;
  token: string | null;
  meetingRooms: MeetingRoomItem[];
  meetingRoom: MeetingRoomItem | null;
  hasVideoDevice: boolean;
  hasAudioDevice: boolean;
  isPermissionsAccepted: boolean;
  settings: RoomSettings | null;
  isVideo: boolean;
  isAudio: boolean;
  userIsReady: boolean;
  isFullScreen: boolean;
  roomIsFinished: boolean;
  roomNotAvailable: boolean;
  participantOptions: [];
  template: string;
  minimized: boolean;
  openedMeetingRoom: boolean;
  isModalMode: boolean;
  localParticipantAcceptedJoin: boolean;
  roomId: string | null;
  identityId: string | null;
}

interface ModuleContext {
  state: MeetingRoomState;
  rootState: any;
  rootGetters: any;
  commit: any;
}

export const useMeetingRoomStore = defineStore({
  id: "meeting-room",
  state: (): MeetingRoomState => ({
    invite: {},
    inviteReceiver: {},
    inviteEmail: "",
    inviteMethod: "email",
    inviteFullName: "",
    invitePhoneNumber: "",
    inviteId: "",
    inviteText: "",
    openedListDialog: false,
    inviteDialog: false,
    participantOptions: [],
    meetingRooms: [],
    token: null,
    meetingRoom: null,
    connectedParticipant: null,
    localParticipant: null,
    remoteParticipant: null,
    activeMeetingRoom: null,
    settings: null,
    isVideo: true,
    isAudio: true,
    isFullScreen: false,
    hasAudioDevice: true,
    hasVideoDevice: true,
    localParticipantAcceptedJoin: false,
    isPermissionsAccepted: false,
    userIsReady: false,
    roomIsFinished: false,
    roomNotAvailable: false,
    minimized: false,
    roomId: null,
    identityId: null,
    isModalMode: false,
    openedMeetingRoom: false,
    template: "Hello %name%, Dr. %practitioner% has invited you to join a secure video call: %link%",
  }),
  actions: {
    updateMeetingRoom(payload: MeetingRoomItem) {
      this.meetingRooms = [...this.meetingRooms.filter((i: MeetingRoomItem) => i.id !== payload.id), payload];
    },
    setOpenedListDialog(payload: boolean) {
      this.openedListDialog = payload;
    },
    addMeetingRoom(payload: MeetingRoomItem) {
      this.meetingRooms.push(payload);
    },
    cleanUpInvite() {
      this.invitePhoneNumber = "";
      this.inviteEmail = "";
      this.inviteFullName = "";
      this.inviteId = "";
      this.inviteReceiver = {};
    },
    resetRoom() {
      this.meetingRoom = null;
      this.connectedParticipant = null;
      this.localParticipant = null;
      this.activeMeetingRoom = null;
      this.isFullScreen = false;
      this.isPermissionsAccepted = false;
      this.roomNotAvailable = false;
      this.userIsReady = false;
      this.roomIsFinished = false;
    },
    async getRoomStatus(payload: string) {
      try {
        const room = await MeetingRoomApi.getById(payload);
        this.meetingRoom = room;
        return room;
      } catch (err) {
        console.error("Room not found");
      }
    },
    async sendInvite(payload: MeetingRoomInvite) {
      await MeetingRoomApi.create(payload);
    },
    async joinToRoom(payload: { meetingRoomId: string; identity: string }) {
      await MeetingRoomApi.joinToRoom(payload.meetingRoomId, payload.identity);
    },
    async finishRoom() {
      if (this.meetingRoom?.id) await MeetingRoomApi.finish(this.meetingRoom.id);
    },
    async getMeetingRooms() {
      this.meetingRooms = (await MeetingRoomApi.getAll()).filter(
        (i: MeetingRoomItem) => i.status === MeetingRoomStatus.opened,
      );
    },
    async fetchRoom(payload: { meetingRoomId: string; status: MeetingRoomStatus }) {
      let data;
      try {
        data = await MeetingRoomApi.getById(payload.meetingRoomId, omit(payload, ["meetingRoomId"]));
        this.meetingRoom = data;
      } catch (e) {
        this.meetingRoom = null;
      }

      return data;
    },
    async getConnectedParticipant(identity: string) {
      let participant;
      if (this?.meetingRoom?.recipient?.id && this?.meetingRoom?.recipient?.id === identity)
        participant = this.meetingRoom.recipient;
      if (this?.meetingRoom?.sender?.id && this?.meetingRoom?.sender?.id === identity)
        participant = this.meetingRoom.sender;
      this.connectedParticipant = participant;
      return participant;
    },
    async getRoomWithAccessToken(payload: { meetingRoomId: string; identity: string }) {
      try {
        const { meetingRoom, token } = await MeetingRoomApi.getByIdWithAccess(payload);
        if (!meetingRoom || !token) {
          this.meetingRoom = null;
          return;
        }
        this.meetingRoom = meetingRoom;
        let localParticipant;
        let remoteParticipant;
        if (meetingRoom?.recipient?.id && meetingRoom?.recipient?.id === payload.identity) {
          localParticipant = meetingRoom.recipient;
          remoteParticipant = meetingRoom?.sender;
        }

        if (meetingRoom?.sender?.id && meetingRoom?.sender?.id === payload.identity) {
          localParticipant = meetingRoom.sender;
          remoteParticipant = meetingRoom?.recipient;
        }
        this.localParticipant = localParticipant;
        this.remoteParticipant = remoteParticipant;
        this.token = token;
      } catch (e) {
        console.error(e);
        this.meetingRoom = null;
      }
    },
    async setDefaultRoomSettings() {
      if (this.settings) {
        return this.settings;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter((device) => device.kind === "videoinput");
      const audioInputDevices = devices.filter((device) => device.kind === "audioinput");
      const audioOutputDevices = devices.filter((device) => device.kind === "audiooutput");
      if (!this.settings) {
        this.settings = {
          videoInput: videoInputDevices[0] && videoInputDevices[0].deviceId,
          audioInput: audioInputDevices[0] && audioInputDevices[0].deviceId,
          audioOutput: audioOutputDevices[0] && audioOutputDevices[0].deviceId,
        };
      }
    },
  },
  getters: {
    roomIsReady: (state) => state.userIsReady,
    roomIsStarted: () => true,
  },
});
