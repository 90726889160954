import axios, { CancelTokenSource } from "axios";
import { defineStore } from "pinia";

import { PractitionerRoleApi } from "@/api/practitionerRole";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { IHealthcareService } from "@/pinia-store/healthcareServices";
import { IOrganization } from "@/pinia-store/organization";
import { Practitioner } from "@/types/users";

export interface IPractitionerRole {
  id?: string;

  organizationId: string;
  organization?: Partial<IOrganization>;

  practitioner: Partial<Practitioner>;
  practitionerId: string;

  healthcareService?: Partial<IHealthcareService>;
  healthcareServiceIds?: string[];
  active?: boolean;
}

interface IPractitionerRoleStore {
  practitionerRoles: IPractitionerRole[];
  practitionerRole: Partial<IPractitionerRole>;
  form: Partial<IPractitionerRole>;
  isLoading: boolean;
  isSaving: boolean;
  cancelRequestToken?: CancelTokenSource | null;
  selectedHealthcareService?: IPractitionerRole | null;
}

export const usePractitionerRoleStore = defineStore({
  id: "practitionerRole",
  state: (): IPractitionerRoleStore => ({
    practitionerRole: {},
    practitionerRoles: [],
    form: {},
    isSaving: false,
    cancelRequestToken: null,
    isLoading: false,
  }),
  actions: {
    setPractitionerRoles(payload: IPractitionerRole[]) {
      this.practitionerRoles = payload;
    },
    setPractitionerRole(payload: IPractitionerRole) {
      this.practitionerRole = payload;
    },
    changeFormField(value: any, field: string) {
      this.form[field] = value;
    },
    setForm(item: IPractitionerRole) {
      this.form = item;
    },
    async saveForm() {
      this.isSaving = true;
      const isUpdate = Boolean(this.form.id);
      try {
        if (this.form.id) this.form = await PractitionerRoleApi.update(this.form);
        else this.form = await PractitionerRoleApi.create(this.form);
        snackBarEventBus.$emit(snackBarEventName, {
          message: isUpdate ? "Practitioner role updated" : "Practitioner role created",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "An error occurred",
          type: "error",
        });
      }
      this.isSaving = false;
    },
    async getPractitionerRoles(filter: { organization?: string; practitioner?: string }) {
      const cancelRequestToken = this.cancelRequestToken;
      if (cancelRequestToken) cancelRequestToken.cancel && cancelRequestToken.cancel();
      this.cancelRequestToken = axios.CancelToken.source();
      this.isLoading = true;
      try {
        this.practitionerRoles = await PractitionerRoleApi.getAll(filter);
        this.cancelRequestToken = null;
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
  },
});
