import "firebase/auth";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "viewerjs/dist/viewer.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import { createPinia, PiniaVuePlugin } from "pinia";
import Vue from "vue";

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
import * as firebase from "firebase/app";
import { merge } from "lodash-es";
import * as moment from "moment-timezone";
import { io } from "socket.io-client";
import VueViewer from "v-viewer";
import VueCookies from "vue-cookies";
import VueFileAgent from "vue-file-agent";
import VueQuillEditor from "vue-quill-editor";
import VueRouter from "vue-router";
import VueSocketIO from "vue-socket.io";
import Vuelidate, { validationMixin } from "vuelidate";
import Vuetify from "vuetify";

import { AppApi } from "@/api/app";
import { defaultCssVariables } from "@/pinia-store/app";
import initI18n from "@/plugins/i18n";
import { defaultVuetifyPreset } from "@/plugins/vuetify";
import { scrollTo, scrollToBottom } from "@/utils/scrollToBottom";
import userActivityTracker from "@/utils/userActivityTracker";

import App from "./App.vue";
import { routes } from "./router";

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
Vue.use(VueViewer);

Vue.prototype.$moment = moment;
Vue.prototype.$userActivityTracker = userActivityTracker;
Vue.config.productionTip = false;
let app: Vue | null = null;
const firebaseProjectId = process.env.VUE_APP_FIREBASE_PROJECT_ID;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: `${firebaseProjectId}.firebaseapp.com`,
  databaseURL: `https://${firebaseProjectId}.firebaseio.com`,
  projectId: firebaseProjectId,
  storageBucket: `${firebaseProjectId}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
Vue.use(
  VueQuillEditor,
  {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"], //toggled buttons
        ["blockquote"],
      ],
    },
  } /* { default global options } */,
);
Vue.use(VueFileAgent);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(
  new VueSocketIO({
    connection: io(`${process.env.VUE_APP_BASE_URL}`),
  }),
);

Vue.directive("chat", {
  bind: (el, binding) => {
    let scrolled = false;

    el.addEventListener("scroll", (e) => {
      if (el.scrollTop === 0) {
        scrollTo(el, 10, false);
      }
      scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
      if (scrolled && el.scrollTop < el.scrollHeight / 5) {
        el.dispatchEvent(new Event("chatTopReached"));
      }
    });

    new MutationObserver((e) => {
      const config = binding.value || {};
      if (config.enabled === false) return;
      const pause = config.always === false && scrolled;
      const addedNodes = e[e.length - 1].addedNodes.length;
      const removedNodes = e[e.length - 1].removedNodes.length;

      if (config.scrollonremoved) {
        if (pause || (addedNodes != 1 && removedNodes != 1)) return;
      } else {
        if (pause || addedNodes != 1) return;
      }

      let smooth = config.smooth;
      const loadingRemoved = !addedNodes && removedNodes === 1;
      if (loadingRemoved && config.scrollonremoved && "smoothonremoved" in config) {
        smooth = config.smoothonremoved;
      }
      scrollToBottom(el, smooth);
    }).observe(el, { childList: true, subtree: true });
  },
  inserted: (el, binding) => {
    const config = binding.value || {};
    scrollToBottom(el, config.notSmoothOnInit ? false : config.smooth);
  },
});
Vue.directive("blur", {
  inserted: function (el) {
    el.onfocus = (ev) => ev && ev.target && el.blur();
  },
});

// theme  config
Vue.use(Vuetify);
const vuetify = new Vuetify(merge(defaultVuetifyPreset, { theme: { themes: { light: defaultCssVariables } } }));

firebase.auth().onAuthStateChanged(async (user) => {
  if (!app) {
    let isInMaintenanceMode = false;
    let appSettings = {
      brandName: process.env.VUE_APP_BRAND_NAME,
    };
    try {
      appSettings = await AppApi.getApp();
    } catch (e) {
      isInMaintenanceMode = true;
    }

    app = new Vue({
      props: ["appSettings"],
      pinia,
      router,
      vuetify,
      i18n: initI18n(),
      mixins: [validationMixin],
      render: (h) => h(App, { props: { appSettings: { ...appSettings, isInMaintenanceMode } } }),
    }).$mount("#app");
  }
});
